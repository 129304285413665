const chapterFifthPCM = [
    0.0725,
    -0.0294,
    0.0766,
    -0.1134,
    0.8757,
    -0.1942,
    0.6112,
    -0.5407,
    0.6418,
    -0.5224,
    0.5416,
    -0.2821,
    0.2374,
    -0.2135,
    0.8336,
    -0.7046,
    0.4342,
    -0.4283,
    0.2893,
    -0.2914,
    0.2012,
    -0.1448,
    0.5512,
    -0.4077,
    0.2937,
    -0.306,
    0.1911,
    -0.516,
    0.2712,
    -0.0467,
    0.0622,
    -0.041,
    0.2852,
    -0.3359,
    0.5135,
    -0.3975,
    0.382,
    -0.1667,
    0.7372,
    -0.2562,
    0.0743,
    -0.1751,
    0.0494,
    -0.1684,
    0.039,
    -0.0532,
    0.0782,
    -0.2769,
    0.5285,
    -0.3078,
    0.4396,
    -0.4433,
    0.0528,
    -0.2164,
    -0.003,
    -0.4004,
    0.8576,
    -0.6474,
    0.5541,
    -0.1617,
    0.4986,
    -0.536,
    0.0973,
    -0.3133,
    0.2808,
    -0.2966,
    0.1079,
    -0.47,
    0.5746,
    -0.3744,
    0.5044,
    -0.1666,
    0.3475,
    -0.9095,
    0.0434,
    -0.103,
    0.3483,
    -0.5135,
    0.4604,
    -0.5046,
    0.5251,
    -0.2538,
    0.5449,
    -0.5029,
    0.3436,
    -0.2411,
    0.2407,
    -0.2573,
    0.2404,
    -0.3422,
    0.4382,
    -0.3136,
    0.7918,
    -0.3743,
    0.1475,
    -0.1158,
    0.0626,
    -0.1893,
    0.5594,
    -0.4642,
    0.2232,
    -0.7994,
    0.4615,
    -0.3916,
    0.246,
    -0.2633,
    0.2556,
    -0.2502,
    0.8012,
    -0.2866,
    0.8427,
    -0.4086,
    0.1462,
    -0.4363,
    0.4102,
    -0.601,
    0.8295,
    -0.4587,
    0.1914,
    -0.7392,
    0.3686,
    -0.823,
    0.1062,
    -0.1008,
    0.6887,
    -0.2498,
    0.5939,
    -0.3649,
    0.3279,
    -0.1474,
    0.2889,
    -0.2084,
    0.3464,
    -0.1162,
    0.3498,
    -0.2367,
    0.5686,
    -0.2108,
    0.4554,
    -0.4123,
    0.2022,
    -0.1783,
    0.0424,
    -0.0428,
    0.3293,
    -0.3606,
    0.4243,
    -0.1592,
    0.4937,
    -0.2454,
    0.8304,
    -0.3805,
    0.2911,
    -0.3791,
    0.4014,
    -0.375,
    0.4565,
    -0.4418,
    0.4574,
    -0.288,
    0.1407,
    -0.2913,
    0.1337,
    -0.0697,
    0.2377,
    -0.0862,
    0.5431,
    -0.3112,
    0.7583,
    -0.352,
    0.2094,
    -0.1598,
    0.1039,
    -0.1136,
    0.0558,
    -0.094,
    0.4984,
    -0.1171,
    0.2396,
    -0.5151,
    0.2595,
    -0.3818,
    0.4333,
    -0.2356,
    0.8328,
    -0.063,
    0.677,
    -0.2543,
    0.4329,
    -0.1824,
    0.2085,
    -0.1373,
    0.269,
    -0.4987,
    0.3832,
    -0.3856,
    0.307,
    -0.3167,
    0.3402,
    -0.1865,
    0.3148,
    -0.0948,
    0.1381,
    -0.0363,
    0.5438,
    -0.4995,
    0.4966,
    -0.2123,
    0.216,
    -0.2619,
    0.7293,
    -0.379,
    0.5806,
    -0.609,
    0.7537,
    -0.2105,
    0.8112,
    -0.5211,
    0.883,
    -0.343,
    0.2073,
    -0.3593,
    0.5378,
    -0.3135,
    0.3038,
    -0.3893,
    0.2047,
    -0.1185,
    0.109,
    -0.4159,
    0.349,
    -0.5184,
    0.2503,
    -0.3502,
    0.363,
    -0.159,
    0.3851,
    -0.3486,
    0.0504,
    -0.5871,
    0.0948,
    -0.1133,
    0.5692,
    -0.4422,
    0.62,
    -0.3676,
    0.0929,
    -0.3848,
    0.6135,
    -0.6179,
    0.0706,
    -0.2225,
    0.3254,
    -0.3373,
    0.1407,
    -0.3438,
    0.4956,
    -0.705,
    0.3633,
    -0.7997,
    0.1372,
    -0.5463,
    0.3284,
    -0.219,
    0.2395,
    -0.6724,
    0.057,
    -0.259,
    0.021,
    -0.0544,
    0.0968,
    -0.0631,
    0.1645,
    -0.4156,
    0.4964,
    -0.326,
    0.1536,
    -0.4172,
    0.3951,
    -0.1646,
    0.9304,
    -0.3624,
    0.6624,
    -0.2914,
    0.8771,
    -0.5064,
    0.6498,
    -0.3136,
    0.4432,
    -0.1875,
    0.2387,
    -0.162,
    0.1562,
    -0.3755,
    0.8178,
    -0.5768,
    0.3887,
    -0.2665,
    0.3891,
    -0.6827,
    0.2177,
    -0.3781,
    0.8169,
    -0.5459,
    0.7275,
    -0.5637,
    0.7258,
    -0.4465,
    0.1218,
    -0.2292,
    0.1774,
    -0.3732,
    0.164,
    -0.0475,
    0.4442,
    -0.1634,
    0.4123,
    -0.2757,
    0.1937,
    -0.4188,
    0.3354,
    -0.4147,
    0.1703,
    -0.1404,
    0.4952,
    -0.3926,
    0.5374,
    -0.4079,
    0.5461,
    -0.1819,
    0.0895,
    -0.0988,
    0.0555,
    -0.1633,
    -0.0199,
    -0.3875,
    0.5317,
    -0.2878,
    0.2165,
    -0.2804,
    0.3562,
    -0.1419,
    0.3288,
    -0.3423,
    0.2489,
    -0.0772,
    0.1014,
    -0.1731,
    0.6897,
    -0.5117,
    0.2747,
    -0.417,
    0.5696,
    -0.3671,
    0.8477,
    -0.4765,
    0.196,
    -0.1616,
    0.7008,
    -0.2353,
    0.1088,
    -0.0926,
    0.5116,
    -0.0914,
    0.4956,
    -0.4638,
    0.2517,
    -0.2609,
    0.4028,
    -0.233,
    0.2932,
    -0.4405,
    0.7991,
    -0.3328,
    0.5283,
    -0.2716,
    0.1966,
    -0.6956,
    0.1279,
    -0.0904,
    0.3619,
    -0.0876,
    0.3987,
    -0.3461,
    0.737,
    -0.6725,
    0.2087,
    -0.1611,
    0.2147,
    -0.4617,
    0.1283,
    -0.1842,
    0.8125,
    -0.5897,
    0.0786,
    -0.061,
    0.5751,
    -0.3007,
    0.8126,
    -0.5517,
    0.6202,
    -0.2719,
    0.4493,
    -0.1229,
    0.4265,
    -0.1456,
    0.3845,
    -0.3327,
    0.2198,
    -0.4146,
    0.324,
    -0.2287,
    0.1748,
    -0.5704,
    0.1861,
    -0.6084,
    0.0704,
    -0.0255,
    0.8217,
    -0.334,
    0.5804,
    -0.0514,
    0.5422,
    -0.3596,
    0.1829,
    -0.2941,
    0.3961,
    -0.3926,
    0.2489,
    -0.3594,
    0.3829,
    -0.1908,
    0.0759,
    -0.1971,
    0.0876,
    -0.6645,
    0.378,
    -0.4005,
    0.4079,
    -0.2089,
    0.0899,
    -0.2477,
    0.2884,
    -0.4929,
    0.6537,
    -0.1843,
    0.3076,
    -0.4117,
    0.1128,
    -0.3135,
    0.5085,
    -0.3592,
    0.1908,
    -0.5091,
    0.1533,
    -0.3976,
    0.15,
    -0.1022,
    0.1787,
    -0.0811,
    0.5366,
    -0.4862,
    0.4714,
    -0.466,
    0.5494,
    -0.4339,
    0.2968,
    -0.3679,
    0.6423,
    -0.3369,
    0.2547,
    -0.153,
    0.2583,
    -0.1033,
    0.0349,
    -0.4223,
    0.3635,
    -0.2204,
    0.1435,
    -0.2597,
    0.1241,
    -0.2687,
    0.3998,
    -0.276,
    0.3251,
    -0.2554,
    0.1716,
    -0.116,
    0.1843,
    -0.1102,
    0.7788,
    -0.4482,
    0.5803,
    -0.4884,
    0.6369,
    -0.2825,
    0.2062,
    -0.4838,
    0.7021,
    -0.2905,
    0.5265,
    -0.5092,
    0.279,
    -0.0793,
    0.2337,
    -0.7673,
    0.1805,
    -0.2503,
    0.2316,
    -0.3273,
    0.6311,
    -0.1929,
    0.5631,
    -0.3714,
    0.5834,
    -0.5274,
    0.6554,
    -0.4275,
    0.5111,
    -0.3718,
    0.5142,
    -0.1466,
    0.5912,
    -0.3712,
    0.2233,
    -0.3035,
    0.1325,
    -0.0922,
    0.1246,
    -0.6002,
    0.6862,
    -0.3763,
    0.3487,
    -0.3158,
    0.3983,
    -0.4178,
    0.13,
    -0.3837,
    0.4793,
    -0.4136,
    0.4991,
    -0.1844,
    0.1675,
    -0.018,
    0.1346,
    -0.1104,
    0.1334,
    -0.2453,
    0.3739,
    -0.2773,
    0.244,
    -0.2526,
    0.261,
    -0.7568,
    0.2668,
    -0.2589,
    0.2716,
    -0.1933,
    0.2929,
    -0.1518,
    0.1732,
    -0.3216,
    0.3595,
    -0.1951,
    0.5291,
    -0.175,
    0.4158,
    -0.5645,
    0.1108,
    -0.5038,
    0.3064,
    -0.5403,
    0.3346,
    -0.2324,
    0.3385,
    -0.2903,
    0.1261,
    -0.3842,
    0.1556,
    -0.4144,
    0.2315,
    -0.0973,
    0.0753,
    -0.0512,
    0.2153,
    -0.1208,
    0.595,
    -0.4153,
    0.2088,
    -0.2292,
    0.2614,
    -0.2103,
    0.3964,
    -0.2117,
    0.0715,
    -0.1221,
    0.5025,
    -0.6127,
    0.4529,
    -0.4399,
    0.2479,
    -0.4821,
    0.0451,
    -0.1642,
    0.5289,
    -0.1512,
    0.4084,
    -0.3001,
    0.1709,
    -0.3297,
    0.2741,
    -0.2323,
    0.2593,
    -0.2449,
    0.0394,
    -0.0571,
    0.0667,
    -0.1264,
    0.0459,
    -0.0238,
    0.0744,
    -0.1084,
    0.1733,
    -0.6719,
    0.4216,
    -0.4906,
    0.5322,
    -0.1918,
    0.5561,
    -0.7236,
    0.4873,
    -0.3493,
    0.469,
    -0.2668,
    0.187,
    -0.3265,
    0.1862,
    -0.7048,
    0.2631,
    -0.3755,
    0.4154,
    -0.5193,
    0.3947,
    -0.4265,
    0.4125,
    -0.4802,
    0.3314,
    -0.4364,
    0.0981,
    -0.1206,
    0.0507,
    -0.0527,
    0.4987,
    -0.6705,
    0.1897,
    -0.2564,
    0.4551,
    -0.0496,
    0.5253,
    -0.6155,
    0.6243,
    -0.3769,
    0.5073,
    -0.4422,
    0.2756,
    -0.5473,
    0.1694,
    -0.2694,
    0.6625,
    -0.0256,
    0.4045,
    -0.5131,
    0.3703,
    -0.6881,
    0.2045,
    -0.5386,
    0.607,
    -0.6504,
    0.5067,
    -0.3144,
    0.1395,
    -0.1502,
    0.0605,
    -0.0927,
    0.034,
    -0.609,
    0.5439,
    -0.1193,
    0.0493,
    -0.1349,
    0.3209,
    -0.3802,
    0.4365,
    -0.3103,
    0.0589,
    -0.4724,
    0.2102,
    -0.2251,
    0.2249,
    -0.1147,
    0.2065,
    -0.211,
    0.2643,
    -0.2247,
    0.293,
    -0.3058,
    0.3218,
    -0.3117,
    0.0743,
    -0.2068,
    0.2202,
    -0.3768,
    0.1211,
    -0.0448,
    0.1816,
    -0.2215,
    0.4451,
    0.0001,
    0.3023,
    -0.3284,
    0.102,
    -0.1185,
    0.0729,
    -0.2783,
    0.1916,
    -0.0911,
    0.1104,
    -0.2082,
    0.1464,
    -0.2351,
    0.5201,
    -0.2671,
    0.0799,
    -0.4416,
    0.2792,
    -0.1201,
    0.6205,
    -0.1221,
    0.5047,
    -0.5367,
    0.3755,
    -0.2293,
    0.2791,
    -0.3205,
    0.5885,
    -0.2112,
    0.5426,
    -0.4198,
    0.3209,
    -0.5735,
    0.548,
    -0.0514,
    0.4392,
    -0.1732,
    0.3652,
    -0.1453,
    0.3367,
    -0.4332,
    0.0983,
    -0.4774,
    0.1043,
    -0.1985,
    0.3844,
    -0.3385,
    0.0535,
    -0.6465,
    0.1816,
    -0.3796,
    0.1435,
    -0.0596,
    0.0716,
    -0.1643,
    0.0667,
    -0.103,
    0.0842,
    -0.1121,
    0.0489,
    -0.0513,
    0.0743,
    -0.4118,
    0.3658,
    -0.1475,
    0.0251,
    -0.116,
    0.7377,
    -0.2712,
    0.1944,
    -0.6856,
    0.0455,
    -0.1256,
    0.517,
    -0.2704,
    0.3711,
    -0.1368,
    0.6983,
    -0.0425,
    0.4626,
    -0.2467,
    0.1487,
    -0.1977,
    0.1042,
    -0.134,
    0.0536,
    -0.0394,
    0.0729,
    -0.1337,
    0.5738,
    -0.0966,
    0.4041,
    -0.4165,
    0.3347,
    -0.2851,
    0.1559,
    -0.3387,
    0.2518,
    -0.3631,
    0.0566,
    -0.0666,
    0.0765,
    -0.0512,
    0.3879,
    -0.6187,
    0.5608,
    -0.3342,
    0.4287,
    -0.4129,
    0.2535,
    -0.3525,
    0.1495,
    -0.4961,
    0.358,
    -0.4789,
    0.1943,
    -0.6468,
    0.117,
    -0.0936,
    0.1498,
    -0.1016,
    0.09,
    -0.084,
    0.2446,
    -0.3634,
    0.5282,
    -0.7912,
    0.2423,
    -0.462,
    0.3439,
    -0.2322,
    0.3005,
    -0.1749,
    0.1195,
    -0.316,
    0.3298,
    -0.5961,
    0.5734,
    -0.6976,
    0.5424,
    -0.2527,
    0.3704,
    -0.4238,
    0.2515,
    -0.4861,
    0.5023,
    -0.1208,
    0.36,
    -0.4344,
    0.5577,
    -0.447,
    0.4739,
    -0.4929,
    0.61,
    -0.5477,
    0.1121,
    -0.317,
    0.1363,
    -0.0368,
    0.0756,
    -0.1503,
    0.0564,
    -0.1414,
    0.4812,
    -0.1332,
    0.4164,
    -0.7254,
    0.6286,
    -0.2452,
    0.4825,
    -0.1529,
    0.3542,
    -0.1984,
    0.2811,
    -0.6942,
    0.3145,
    -0.5976,
    0.3965,
    -0.6284,
    0.3249,
    -0.115,
    0.7048,
    -0.7997,
    0.2595,
    -0.0591,
    0.3315,
    -0.6522,
    0.2977,
    -0.2276,
    0.3758,
    -0.5393,
    0.4554,
    -0.5427,
    0.383,
    -0.4157,
    0.4485,
    -0.4382,
    0.382,
    -0.5721,
    0.3793,
    -0.0849,
    0.1218,
    -0.0729,
    0.4193,
    -0.3331,
    0.4187,
    -0.3316,
    0.069,
    -0.1979,
    0.2648,
    -0.1433,
    0.529,
    -0.3153,
    0.3784,
    -0.2508,
    0.4439,
    -0.403,
    0.4351,
    -0.2963,
    0.1548,
    -0.049,
    0.2744,
    -0.404,
    0.5709,
    -0.7514,
    0.3047,
    -0.1654,
    0.5107,
    -0.1687,
    0.1011,
    -0.1834,
    0.329,
    -0.2643,
    0.4136,
    -0.6002,
    0.34,
    -0.4933,
    0.1301,
    -0.2753,
    0.0729,
    -0.0654,
    0.0435,
    -0.1121,
    0.0707,
    -0.0497,
    0.1368,
    -0.1821,
    0.1732,
    -0.2082,
    0.1001,
    -0.3175,
    0.294,
    -0.2546,
    0.0908,
    -0.1613,
    0.1587,
    -0.7287,
    0.4621,
    -0.2935,
    0.3282,
    -0.6056,
    0.2478,
    -0.6529,
    0.2081,
    -0.8288,
    0.0668,
    -0.041,
    0.0906,
    -0.1315,
    0.4496,
    -0.4531,
    0.4426,
    -0.326,
    0.1054,
    -0.419,
    0.2343,
    -0.0467,
    0.2213,
    -0.6143,
    0.286,
    -0.4988,
    0.1128,
    -0.3944,
    0.3122,
    -0.5242,
    0.396,
    -0.7152,
    0.5936,
    -0.3634,
    0.4001,
    -0.1201,
    0.5792,
    -0.3126,
    0.5489,
    -0.1208,
    0.1134,
    -0.0982,
    0.0669,
    -0.0683,
    0.3044,
    -0.0735,
    0.4033,
    -0.5469,
    0.4791,
    -0.4615,
    0.4782,
    -0.6646,
    0.2754,
    -0.4219,
    0.3348,
    -0.1022,
    0.2942,
    -0.4751,
    0.204,
    -0.1889,
    0.0892,
    -0.43,
    0.5075,
    -0.0774,
    0.1617,
    -0.381,
    0.6713,
    -0.6114,
    0.0719,
    -0.3346,
    0.1613,
    -0.1459,
    0.6114,
    -0.0932,
    0.6115,
    -0.3853,
    0.4783,
    -0.1917,
    0.39,
    -0.4745,
    0.0878,
    -0.1142,
    0.407,
    -0.629,
    0.2318,
    -0.6112,
    0.5625,
    -0.6691,
    0.0524,
    -0.1073,
    0.2697,
    -0.371,
    0.6096,
    -0.2137,
    0.1498,
    -0.3111,
    0.2938,
    -0.4885,
    0.3489,
    -0.2998,
    0.2437,
    -0.5077,
    0.0694,
    -0.5473,
    0.062,
    -0.0597,
    0.055,
    -0.1311,
    0.0182,
    -0.3144,
    0.4523,
    -0.4967,
    0.4799,
    -0.6262,
    0.1635,
    -0.1377,
    0.7234,
    -0.3184,
    0.3553,
    -0.1711,
    0.4703,
    -0.3351,
    0.0805,
    -0.321,
    0.4743,
    -0.4942,
    0.1396,
    -0.3894,
    0.5591,
    -0.5872,
    0.4663,
    -0.0743,
    0.2096,
    -0.3275,
    0.4257,
    -0.1259,
    0.0863,
    -0.0776,
    0.6889,
    -0.1808,
    0.3563,
    -0.5468,
    0.3718,
    -0.4206,
    0.6478,
    -0.2051,
    0.1453,
    -0.2996,
    0.3159,
    -0.7607,
    0.5992,
    -0.5024,
    0.6019,
    -0.399,
    0.5446,
    -0.2528,
    0.1427,
    -0.1597,
    0.3962,
    -0.4751,
    0.0474,
    -0.4562,
    0.698,
    -0.4257,
    0.3404,
    -0.4058,
    0.0283,
    -0.3407,
    0.0659,
    -0.3391,
    0.3639,
    -0.7173,
    0.2785,
    -0.2873,
    0.085,
    -0.1417,
    0.0509,
    -0.2884,
    0.4022,
    -0.246,
    0.384,
    -0.2807,
    0.1694,
    -0.3132,
    0.14,
    -0.0262,
    0.5478,
    -0.3693,
    0.4558,
    -0.7148,
    0.4708,
    -0.7949,
    0.2802,
    -0.7994,
    0.1924,
    -0.2461,
    0.356,
    -0.4142,
    0.2561,
    -0.1367,
    0.0154,
    -0.0143,
    0.0421,
    -0.0533,
    0.2535,
    -0.3545,
    0.5707,
    -0.5708,
    0.4516,
    -0.3864,
    0.1529,
    -0.2619,
    0.6686,
    -0.4867,
    0.6895,
    -0.1733,
    0.346,
    -0.7672,
    0.265,
    -0.7562,
    0.0603,
    -0.0517,
    0.0199,
    -0.0119,
    0.2853,
    -0.0471,
    0.5078,
    -0.2951,
    0.205,
    -0.3725,
    0.4474,
    -0.5491,
    0.1223,
    -0.0653,
    0.0795,
    -0.0754,
    0.339,
    -0.5348,
    0.1055,
    -0.0787,
    0.1459,
    -0.0801,
    0.3307,
    -0.3774,
    0.044,
    -0.0323,
    0.15,
    -0.0719,
    0.17,
    -0.1209,
    0.0821,
    -0.0503,
    0.0707,
    -0.0246,
    0.2574,
    -0.6104,
    0.225,
    -0.581,
    0.2002,
    -0.1734,
    0.3306,
    -0.682,
    0.5403,
    -0.7948,
    0.4424,
    -0.7007,
    0.3807,
    -0.19,
    0.2158,
    -0.2239,
    0.0433,
    -0.0286,
    0.5111,
    -0.3555,
    0.3721,
    -0.4405,
    0.544,
    -0.7756,
    0.459,
    -0.0669,
    0.1327,
    -0.2448,
    0.2019,
    -0.7122,
    0.1679,
    -0.1799,
    0.1633,
    -0.087,
    0.134,
    -0.076,
    0.2262,
    -0.6371,
    0.2248,
    -0.1978,
    0.4991,
    -0.0546,
    0.4209,
    -0.1658,
    0.2578,
    -0.4736,
    0.1781,
    -0.0955,
    0.0163,
    -0.0381,
    0.0821,
    -0.0562,
    0.1412,
    -0.0451,
    0.3149,
    -0.1499,
    0.3399,
    -0.3288,
    0.0774,
    -0.0689,
    0.1212,
    -0.0911,
    0.3965,
    -0.2324,
    0.5326,
    -0.5031,
    0.4491,
    -0.3377,
    0.1401,
    -0.0492,
    0.1541,
    -0.363,
    0.2274,
    -0.2443,
    0.4142,
    -0.5038,
    0.1907,
    -0.189,
    0.1876,
    -0.1493,
    0.2698,
    -0.1699,
    0.1148,
    -0.8439,
    0.0269,
    -0.3668,
    0.3714,
    -0.2975,
    0.0945,
    -0.2915,
    0.5111,
    -0.3033,
    0.2669,
    -0.2679,
    0.2103,
    -0.3188,
    0.3541,
    -0.4132,
    0.2016,
    -0.5074,
    0.683,
    -0.195,
    0.3852,
    -0.5021,
    0.3364,
    -0.5666,
    0.0242,
    -0.0762,
    0.0432,
    -0.0936,
    0.3318,
    -0.5493,
    0.0528,
    -0.0368,
    0.1635,
    -0.1569,
    0.1279,
    -0.6625,
    0.4746,
    -0.1671,
    0.7292,
    -0.2203,
    0.2633,
    -0.2114,
    0.1537,
    -0.2879,
    0.4026,
    -0.8862,
    0.3601,
    -0.6208,
    0.609,
    -0.3337,
    0.5993,
    -0.2365,
    0.1725,
    -0.2551,
    0.7522,
    -0.7814,
    0.5669,
    -0.2547,
    0.2806,
    -0.1399,
    0.1105,
    -0.037,
    0.1533,
    -0.0204,
    0.0272,
    -0.0414,
    0.354,
    -0.339,
    0.4435,
    -0.701,
    0.3566,
    -0.2754,
    0.0289,
    -0.6665,
    0.1303,
    -0.3551,
    0.3409,
    -0.558,
    0.3506,
    -0.5471,
    0.5656,
    -0.5001,
    0.151,
    -0.0476,
    0.1159,
    -0.1438,
    0.6693,
    -0.0528,
    0.3766,
    -0.7217,
    0.4341,
    -0.1609,
    0.0572,
    -0.0103,
    0.2241,
    -0.3192,
    0.4726,
    -0.6591,
    0.6388,
    -0.2379,
    0.1643,
    -0.5798,
    0.3852,
    -0.5725,
    0.4718,
    -0.8016,
    0.2602,
    -0.2906,
    0.1557,
    -0.1016,
    0.4136,
    -0.5689,
    0.486,
    -0.6144,
    0.111,
    -0.4678,
    0.2626,
    -0.5544,
    0.18,
    -0.1445,
    0.5445,
    -0.4183,
    0.3255,
    -0.2168,
    0.4221,
    -0.7189,
    0.3291,
    -0.2659,
    0.6184,
    -0.3726,
    0.464,
    -0.1209,
    0.3378,
    -0.1661,
    0.1316,
    -0.6815,
    0.3556,
    -0.447,
    0.1153,
    -0.3424,
    0.3025,
    -0.8987,
    0.081,
    -0.6533,
    0.4054,
    -0.5517,
    0.198,
    -0.1998,
    0.0302,
    -0.1146,
    0.1391,
    -0.7366,
    0.3595,
    -0.4575,
    0.0939,
    -0.0736,
    0.0715,
    -0.1042,
    0.1287,
    -0.0448,
    0.0373,
    -0.0564,
    0.1264,
    -0.0319,
    0.7419,
    -0.6306,
    0.1652,
    -0.1439,
    0.1081,
    -0.6001,
    0.3844,
    -0.2544,
    0.3716,
    -0.3498,
    0.5145,
    -0.0756,
    0.3796,
    -0.373,
    0.4005,
    -0.7263,
    0.1289,
    -0.1763,
    0.4476,
    -0.3492,
    0.5423,
    -0.1795,
    0.4527,
    -0.3913,
    0.3787,
    -0.1875,
    0.3976,
    -0.3987,
    0.0805,
    -0.1862,
    0.0723,
    -0.0584,
    0.0897,
    -0.0446,
    0.0577,
    -0.1292,
    0.4585,
    -0.2425,
    0.1618,
    -0.3071,
    0.0458,
    -0.0555,
    0.4569,
    -0.0977,
    0.2952,
    -0.534,
    0.1512,
    -0.4343,
    0.1825,
    -0.3392,
    0.354,
    -0.3763,
    0.7218,
    -0.3069,
    0.0426,
    -0.0825,
    0.504,
    -0.1098,
    0.3022,
    -0.4805,
    0.3878,
    -0.3873,
    0.0812,
    -0.1432,
    0.505,
    -0.1303,
    0.4371,
    -0.2482,
    0.093,
    -0.0466,
    0.0366,
    -0.1162,
    0.0276,
    -0.097,
    0.0983,
    -0.1253,
    0.0867,
    -0.0661,
    0.1459,
    -0.0935,
    0.5261,
    -0.1144,
    0.4002,
    -0.4143,
    0.3235,
    -0.5677,
    0.1208,
    -0.223,
    0.1165,
    -0.1064,
    0.2989,
    -0.4769,
    0.73,
    -0.6766,
    0.4843,
    -0.6958,
    0.2993,
    -0.0917,
    0.1072,
    -0.0876,
    0.113,
    -0.0895,
    0.1169,
    -0.0807,
    0.1513,
    -0.2473,
    0.726,
    -0.373,
    0.7056,
    -0.3301,
    0.3214,
    -0.4519,
    0.1549,
    -0.3438,
    0.197,
    -0.1834,
    0.3675,
    -0.3408,
    0.1129,
    -0.3192,
    0.2041,
    -0.5884,
    0.2908,
    -0.0914,
    0.4869,
    -0.228,
    0.4516,
    -0.1683,
    0.0813,
    -0.0787,
    0.0553,
    -0.1249,
    0.4942,
    -0.2635,
    0.5204,
    -0.5055,
    0.1883,
    -0.3895,
    0.3789,
    -0.2673,
    0.3816,
    -0.6567,
    0.1916,
    -0.2708,
    0.4522,
    -0.7192,
    0.7293,
    -0.3367,
    0.0987,
    -0.4051,
    0.1193,
    -0.4478,
    0.3505,
    -0.168,
    0.3382,
    -0.3483,
    0.3132,
    -0.2274,
    0.6582,
    -0.3012,
    0.3606,
    -0.1954,
    0.0811,
    -0.1491,
    0.0648,
    -0.1316,
    0.0401,
    -0.039,
    0.2407,
    -0.3283,
    0.5751,
    -0.273,
    0.6958,
    -0.4979,
    0.3405,
    -0.506,
    0.2035,
    -0.4097,
    0.1482,
    -0.0889,
    0.2569,
    -0.3811,
    0.5229,
    -0.1676,
    0.7606,
    -0.2672,
    0.6552,
    -0.4489,
    0.315,
    -0.4995,
    0.0687,
    -0.2308,
    0.4129,
    -0.1739,
    0.1007,
    -0.1146,
    0.1068,
    -0.0858,
    0.0564,
    -0.1214,
    0.4216,
    -0.3021,
    0.0881,
    -0.0666,
    0.1307,
    -0.0492,
    0.4884,
    -0.5273,
    0.5295,
    -0.5181,
    0.3056,
    -0.3184,
    0.243,
    -0.3254,
    0.3003,
    -0.3913,
    0.0541,
    -0.0292,
    0.5835,
    -0.5773,
    0.5126,
    -0.4909,
    0.3829,
    -0.6423,
    0.4226,
    -0.2635,
    0.6654,
    -0.8391,
    0.3663,
    -0.5622,
    0.2192,
    -0.7029,
    0.3365,
    -0.6489,
    0.1933,
    -0.5357,
    0.5301,
    -0.0458,
    0.1758,
    -0.3661,
    0.285,
    -0.3105,
    0.0453,
    -0.1107,
    0.5316,
    -0.2175,
    0.3841,
    -0.8274,
    0.2173,
    -0.4527,
    0.2564,
    -0.2486,
    0.0705,
    -0.0527,
    0.2996,
    -0.1335,
    0.7779,
    -0.6515,
    0.2313,
    -0.5077,
    0.1579,
    -0.6051,
    0.2107,
    -0.2413,
    0.4271,
    -0.6597,
    0.1845,
    -0.3608,
    0.1972,
    -0.7851,
    0.1652,
    -0.5254,
    0.5256,
    -0.4715,
    0.3782,
    -0.6736,
    0.6012,
    -0.7952,
    0.4489,
    -0.7427,
    0.2503,
    -0.3138,
    0.1456,
    -0.1888,
    0.2531,
    -0.2044,
    0.2448,
    -0.4586,
    0.0725,
    -0.5818,
    0.3045,
    -0.0534,
    0.2672,
    -0.3686,
    0.1817,
    -0.4295,
    0.4006,
    -0.4536,
    0.3181,
    -0.506,
    0.397,
    -0.5197,
    0.4137,
    -0.1223,
    0.2549,
    -0.1837,
    0.3187,
    -0.5173,
    0.3335,
    -0.5528,
    0.2808,
    -0.416,
    0.1196,
    -0.1211,
    0.3374,
    -0.0656,
    0.7179,
    -0.6878,
    0.4728,
    -0.3828,
    0.3879,
    -0.6885,
    0.1035,
    -0.1835,
    0.1821,
    -0.221,
    0.2827,
    -0.1075,
    0.2204,
    -0.1549,
    0.4244,
    -0.2282,
    0.0792,
    -0.0799,
    0.0855,
    -0.0771,
    0.4317,
    -0.5883,
    0.2962,
    -0.3658,
    0.3071,
    -0.0933,
    0.1625,
    -0.1652,
    0.0633,
    -0.1412,
    0.2988,
    -0.0568,
    0.4474,
    -0.38,
    0.3522,
    -0.4179,
    0.4222,
    -0.6092,
    0.4938,
    -0.5317,
    0.6374,
    -0.5267,
    0.3534,
    -0.7321,
    0.0698,
    -0.6691,
    0.1304,
    -0.4885,
    0.1266,
    -0.5599,
    0.096,
    -0.1074,
    0.5997,
    -0.134,
    0.3287,
    -0.1415,
    0.0629,
    -0.6357,
    0.475,
    -0.1454,
    0.6315,
    -0.673,
    0.059,
    -0.0423,
    0.5533,
    -0.2987,
    0.2055,
    -0.8652,
    0.1855,
    -0.55,
    0.4011,
    -0.1885,
    0.2986,
    -0.2694,
    0.1035,
    -0.0691,
    0.1897,
    -0.1795,
    0.0795,
    -0.199,
    0.1213,
    -0.2289,
    0.2576,
    -0.1202,
    0.1343,
    -0.2291,
    0.1517,
    -0.1171,
    0.2358,
    -0.4075,
    0.389,
    -0.3556,
    0.1481,
    -0.1291,
    0.128,
    -0.0285,
    0.5525,
    -0.4694,
    0.5291,
    -0.4996,
    0.259,
    -0.4637,
    0.2279,
    -0.1992,
    0.2013,
    -0.3106,
    0.3457,
    -0.374,
    0.1475,
    -0.0358,
    0.5532,
    -0.5867,
    0.262,
    -0.3124,
    0.3853,
    -0.37,
    0.1678,
    -0.4975,
    0.2818,
    -0.2061,
    0.6469,
    -0.6238,
    0.2125,
    -0.2592,
    0.1538,
    -0.0431,
    0.1921,
    -0.352,
    0.1227,
    -0.4742,
    0.3307,
    -0.3513,
    0.1419,
    -0.4054,
    0.0512,
    -0.4522,
    0.4595,
    -0.133,
    0.082,
    -0.6637,
    0.6613,
    -0.7964,
    0.1348,
    -0.4531,
    0.124,
    -0.3876,
    0.098,
    -0.1295,
    0.1352,
    -0.1492,
    0.0719,
    -0.1498,
    0.0597,
    -0.0537,
    0.0967,
    -0.1165,
    0.3496,
    -0.2159,
    0.3229,
    -0.3663,
    0.2994,
    -0.3287,
    0.1329,
    -0.1292,
    0.1115,
    -0.0822,
    0.3608,
    -0.6008,
    0.8904,
    -0.711,
    0.8764,
    -0.4744,
    0.1061,
    -0.0809,
    0.3947,
    -0.2047,
    0.3854,
    -0.5433,
    0.1187,
    -0.2208,
    0.53,
    -0.5076,
    0.2564,
    -0.359,
    0.7458,
    -0.7593,
    0.1817,
    -0.3688,
    0.2152,
    -0.1858,
    0.2317,
    -0.2853,
    0.1562,
    -0.3621,
    0.146,
    -0.3725,
    0.8809,
    -0.2146,
    0.284,
    -0.1945,
    0.3215,
    -0.3482,
    0.4839,
    -0.1347,
    0.1689,
    -0.355,
    0.1416,
    -0.3074,
    0.2303,
    -0.1069,
    0.3228,
    -0.3103,
    0.1898,
    -0.5186,
    0.1173,
    -0.3338,
    0.3077,
    -0.0739,
    0.3267,
    -0.3304,
    0.2159,
    -0.2183,
    0.1856,
    -0.1185,
    0.3851,
    -0.2005,
    0.5867,
    -0.5494,
    0.4,
    -0.2796,
    0.1894,
    -0.2167,
    0.2441,
    -0.138,
    0.1671,
    -0.427,
    0.0368,
    -0.1091,
    0.0407,
    -0.0997,
    0.0808,
    -0.1245,
    0.0355,
    -0.0829,
    0.0454,
    -0.125,
    0.009,
    -0.008,
    0.0034,
    -0.0025,
    0.0019,
    -0.0021,
    0.0008,
    -0.0007,
    0.0007,
    -0.0003,
    0.0002,
    -0.0003,
    0.0001,
    0
];

export default chapterFifthPCM;