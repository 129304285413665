import styled from 'styled-components';

export const DesktopPlugWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1 {
    margin-top: 130px;
    font-size: 60px;

    line-height: 1;
    letter-spacing: -0.41px;
  }
  h2 {
    font-size: 60px;
    font-weight: 400;
    line-height: 1;
  }
  p{
    margin: 90px 0 5px;
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    line-height: 42px;
  }
`;