const chapterFirstPCM = [
    0.0011,
    -0.0017,
    0.0044,
    -0.0031,
    0.181,
    -0.2274,
    0.4403,
    -0.8139,
    0.8152,
    -0.1348,
    0.8499,
    -0.3556,
    0.02,
    -0.1362,
    0.6251,
    -0.4714,
    0.6118,
    -0.5343,
    0.5878,
    -0.5465,
    0.368,
    -0.5522,
    0.4081,
    -0.3619,
    0.6433,
    -0.3338,
    0.6166,
    -0.4352,
    0.0509,
    -0.0281,
    0.144,
    -0.2519,
    0.1002,
    -0.5251,
    0.594,
    -0.5157,
    0.6206,
    -0.8215,
    0.5681,
    -0.3352,
    0.7971,
    -0.4253,
    0.3501,
    -0.1714,
    0.656,
    -0.2432,
    0.7075,
    -0.5724,
    0.3684,
    -0.2405,
    0.1156,
    -0.3632,
    0.639,
    -0.0318,
    0.6115,
    -0.2195,
    0.4369,
    -0.4669,
    0.7709,
    -0.4425,
    0.363,
    -0.1141,
    0.0373,
    -0.2216,
    0.8934,
    -0.2231,
    0.4175,
    -0.32,
    0.3236,
    -0.3408,
    0.0667,
    -0.357,
    0.5749,
    -0.5318,
    0.2005,
    -0.1832,
    0.0917,
    -0.1525,
    0.293,
    -0.0769,
    0.5307,
    -0.5066,
    0.2368,
    -0.4553,
    0.6172,
    -0.452,
    0.5884,
    -0.4635,
    0.3566,
    -0.253,
    0.8541,
    -0.1357,
    0.2895,
    -0.2306,
    0.1218,
    -0.0825,
    0.1596,
    -0.2354,
    0.385,
    -0.1598,
    0.2335,
    -0.1677,
    0.6597,
    -0.2173,
    0.1078,
    -0.1182,
    0.1747,
    -0.106,
    0.0933,
    -0.0983,
    0.0993,
    -0.0997,
    0.1249,
    -0.0535,
    0.5155,
    -0.1416,
    0.5296,
    -0.5755,
    0.6698,
    -0.4314,
    0.5703,
    -0.548,
    0.4819,
    -0.3601,
    0.3135,
    -0.3256,
    0.4504,
    -0.3449,
    0.5905,
    -0.374,
    0.3292,
    -0.2566,
    0.5813,
    -0.3372,
    0.7476,
    -0.4457,
    0.6736,
    -0.4098,
    0.4762,
    -0.3519,
    0.222,
    -0.1267,
    0.218,
    -0.3139,
    0.4166,
    -0.2204,
    0.5378,
    -0.301,
    0.3222,
    -0.3919,
    0.6125,
    -0.3604,
    0.1428,
    -0.1568,
    0.2935,
    -0.3473,
    0.5058,
    -0.5489,
    0.4101,
    -0.4581,
    0.1788,
    -0.1124,
    0.1292,
    -0.1126,
    0.1062,
    -0.1887,
    0.3538,
    -0.3335,
    0.7833,
    -0.5239,
    0.5666,
    -0.3684,
    0.7374,
    -0.5635,
    0.7024,
    -0.6429,
    0.6368,
    -0.363,
    0.2123,
    -0.2404,
    0.1882,
    -0.132,
    0.7061,
    -0.8278,
    -0.0068,
    -0.6678,
    0.2466,
    -0.8015,
    0.3395,
    -0.1913,
    0.0517,
    -0.6674,
    -0.2142,
    -0.4764,
    0.1551,
    -0.3744,
    0.5019,
    -0.1984,
    0.5348,
    -0.4247,
    0.202,
    -0.2652,
    0.4121,
    -0.2337,
    0.3911,
    -0.2453,
    0.3648,
    -0.1656,
    0.225,
    -0.754,
    0.1949,
    -0.603,
    0.145,
    -0.113,
    0.1433,
    -0.3171,
    0.1302,
    -0.3118,
    0.1709,
    -0.4143,
    0.2646,
    -0.461,
    0.5041,
    -0.523,
    0.0987,
    -0.1386,
    0.0716,
    -0.0793,
    0.5918,
    -0.1669,
    0.8015,
    -0.5626,
    0.6151,
    -0.6164,
    0.299,
    -0.4281,
    0.5403,
    -0.7213,
    0.8009,
    -0.26,
    0.456,
    -0.3716,
    0.5165,
    -0.447,
    0.4648,
    -0.7086,
    0.7066,
    -0.7357,
    0.2775,
    -0.2451,
    0.7603,
    -0.2645,
    0.7716,
    -0.3939,
    0.516,
    -0.4012,
    0.8008,
    -0.3855,
    0.7547,
    -0.38,
    0.4216,
    -0.5652,
    0.1704,
    -0.6235,
    0.2763,
    -0.3191,
    0.4102,
    -0.5004,
    0.3988,
    -0.1877,
    0.1526,
    -0.1576,
    0.2135,
    -0.416,
    0.2856,
    -0.4404,
    0.0332,
    -0.4009,
    0.2696,
    -0.1618,
    0.2827,
    -0.1514,
    0.6822,
    -0.0693,
    0.9404,
    -0.5727,
    0.5818,
    -0.7805,
    0.6724,
    -0.4911,
    0.581,
    -0.5272,
    0.6776,
    -0.4079,
    0.0874,
    -0.2921,
    0.3386,
    -0.158,
    0.4212,
    -0.4942,
    0.7229,
    -0.4123,
    0.1774,
    -0.2916,
    0.4138,
    -0.4536,
    0.3141,
    -0.1558,
    0.4593,
    -0.2615,
    0.6003,
    -0.3255,
    0.2942,
    -0.2395,
    0.8023,
    -0.1725,
    0.7179,
    -0.3878,
    0.4725,
    -0.259,
    0.0664,
    -0.2778,
    0.42,
    -0.4275,
    0.3857,
    -0.3547,
    0.5295,
    -0.218,
    0.2474,
    -0.5511,
    0.6672,
    -0.3921,
    0.7449,
    -0.4823,
    0.6041,
    -0.1035,
    0.2282,
    -0.1581,
    0.0305,
    -0.0396,
    0.4185,
    -0.5749,
    0.596,
    -0.6187,
    0.4683,
    -0.3999,
    0.2365,
    -0.1448,
    0.1807,
    -0.1767,
    0.1019,
    -0.1457,
    0.1299,
    -0.2128,
    0.4898,
    -0.1796,
    0.2676,
    -0.2655,
    0.2133,
    -0.3431,
    0.4615,
    -0.5082,
    0.5834,
    -0.663,
    0.5264,
    -0.6084,
    0.1818,
    -0.2199,
    0.3649,
    -0.2696,
    0.5388,
    -0.5077,
    0.1654,
    -0.137,
    0.1598,
    -0.1176,
    -0.0476,
    -0.5408,
    0.5499,
    -0.7584,
    0.3619,
    -0.5078,
    0.3126,
    -0.4854,
    0.3204,
    -0.1889,
    0.27,
    -0.3099,
    0.0797,
    -0.0612,
    0.333,
    -0.6961,
    0.8054,
    -0.4552,
    0.6363,
    -0.3592,
    0.2073,
    -0.2732,
    0.3285,
    -0.4402,
    0.5443,
    -0.5335,
    0.3062,
    -0.36,
    0.4012,
    -0.2979,
    0.3947,
    -0.316,
    0.594,
    -0.5289,
    0.3176,
    -0.1056,
    0.1274,
    -0.1192,
    0.4703,
    -0.1135,
    0.157,
    -0.1955,
    0.2398,
    -0.1629,
    0.1473,
    -0.1793,
    0.2028,
    -0.2142,
    0.2097,
    -0.1292,
    0.2076,
    -0.2833,
    0.2399,
    -0.1464,
    0.1261,
    -0.182,
    0.1112,
    -0.1875,
    0.0431,
    -0.0833,
    0.097,
    -0.0761,
    0.0899,
    -0.0917,
    0.0954,
    -0.0832,
    0.0388,
    -0.2022,
    0.142,
    -0.2479,
    0.1485,
    -0.1283,
    0.1058,
    -0.0983,
    0.1,
    -0.104,
    0.0763,
    -0.074,
    0.2623,
    -0.4118,
    0.7503,
    -0.4768,
    0.3127,
    -0.234,
    0.5979,
    -0.484,
    0.6051,
    -0.7756,
    0.2905,
    -0.2412,
    0.1698,
    -0.1749,
    0.4047,
    -0.3637,
    0.3061,
    -0.3646,
    0.4648,
    -0.7593,
    0.3922,
    -0.5121,
    0.4786,
    -0.5017,
    0.802,
    -0.2713,
    0.3312,
    -0.3957,
    0.1845,
    -0.1684,
    0.1913,
    -0.1551,
    0.1981,
    -0.017,
    0.0925,
    -0.2358,
    0.0918,
    -0.1841,
    0.1253,
    -0.1568,
    0.2106,
    -0.1301,
    0.3063,
    -0.4116,
    0.1209,
    -0.1826,
    0.1612,
    -0.306,
    0.3778,
    -0.3613,
    0.1909,
    -0.0114,
    0.1117,
    -0.1538,
    0.1217,
    -0.1938,
    0.4494,
    -0.5884,
    0.3914,
    -0.3252,
    0.6434,
    -0.6068,
    0.4518,
    -0.683,
    0.2879,
    -0.329,
    0.126,
    -0.2381,
    0.2659,
    -0.3057,
    0.096,
    -0.2471,
    0.56,
    -0.4969,
    0.2402,
    -0.1503,
    0.2736,
    -0.121,
    0.2952,
    -0.4042,
    0.4386,
    -0.5317,
    0.6184,
    -0.6315,
    0.2747,
    -0.3116,
    0.0497,
    -0.1935,
    0.1688,
    -0.1886,
    0.5982,
    -0.4591,
    0.3854,
    -0.3469,
    0.5823,
    -0.2633,
    0.3289,
    -0.2937,
    0.587,
    -0.4505,
    0.2857,
    -0.416,
    0.3232,
    -0.2915,
    0.7672,
    -0.2952,
    0.5818,
    -0.2367,
    0.1889,
    -0.2847,
    0.1775,
    -0.1148,
    0.2285,
    -0.143,
    0.122,
    -0.2643,
    0.102,
    -0.2099,
    0.1295,
    -0.1812,
    0.1742,
    -0.1686,
    0.7285,
    -0.5697,
    0.5196,
    -0.6491,
    0.8226,
    -0.6514,
    0.5589,
    -0.7594,
    0.4551,
    -0.5624,
    0.2418,
    -0.1651,
    0.0928,
    -0.5794,
    0.5189,
    -0.8374,
    0.7052,
    -0.417,
    0.4588,
    -0.478,
    0.5203,
    -0.4547,
    0.4071,
    -0.3795,
    0.3232,
    -0.1984,
    0.7653,
    -0.3415,
    0.3924,
    -0.2685,
    0.3551,
    -0.426,
    0.8033,
    -0.3221,
    0.4799,
    -0.2982,
    0.1612,
    -0.2153,
    0.3619,
    -0.2892,
    0.379,
    -0.2168,
    0.2937,
    -0.2544,
    0.2256,
    -0.2373,
    0.2526,
    -0.422,
    0.2666,
    -0.1968,
    0.2125,
    -0.1473,
    0.1857,
    -0.2009,
    0.0853,
    -0.0975,
    0.0996,
    -0.1007,
    0.0622,
    -0.0652,
    0.0799,
    -0.0908,
    0.1041,
    -0.0962,
    0.048,
    -0.066,
    0.0818,
    -0.1799,
    0.0567,
    -0.1401,
    0.0588,
    -0.0972,
    0.0768,
    -0.0992,
    0.1112,
    -0.1083,
    0.1254,
    -0.1006,
    0.2002,
    -0.1641,
    0.3811,
    -0.5912,
    0.734,
    -0.5502,
    0.6808,
    -0.2871,
    0.5911,
    0.1916,
    0.3999,
    -0.239,
    0.5768,
    -0.4153,
    0.5482,
    -0.3668,
    0.1759,
    -0.127,
    0.3559,
    -0.1215,
    0.1208,
    -0.1234,
    0.7512,
    -0.589,
    0.3854,
    -0.7616,
    0.4175,
    -0.7832,
    0.8616,
    -0.7434,
    0.1699,
    -0.2553,
    0.5112,
    -0.431,
    0.7168,
    -0.4455,
    0.2709,
    -0.1748,
    0.115,
    -0.0668,
    0.1186,
    -0.0519,
    0.3838,
    -0.3821,
    0.8889,
    -0.5508,
    0.3435,
    -0.2574,
    0.1282,
    -0.161,
    0.2576,
    -0.012,
    0.4801,
    -0.1875,
    0.4146,
    -0.195,
    0.2455,
    -0.1513,
    0.3934,
    -0.2925,
    0.207,
    -0.0575,
    0.1636,
    -0.1544,
    0.5692,
    -0.7783,
    0.6763,
    -0.837,
    0.3429,
    -0.5621,
    0.8227,
    -0.6066,
    0.5358,
    -0.2102,
    0.164,
    -0.7528,
    0.3881,
    -0.4628,
    0.707,
    -0.3978,
    0.2222,
    -0.1407,
    0.3555,
    -0.266,
    0.1731,
    -0.1735,
    0.6958,
    -0.3889,
    0.4356,
    -0.3718,
    0.3042,
    -0.3599,
    0.6743,
    -0.4299,
    0.7943,
    -0.6139,
    0.2343,
    -0.6591,
    0.556,
    -0.6021,
    0.3407,
    -0.3974,
    0.2547,
    -0.3956,
    0.1888,
    -0.3847,
    0.7125,
    -0.2678,
    0.2499,
    -0.2661,
    0.5159,
    -0.3383,
    0.3588,
    -0.3758,
    0.3539,
    -0.4058,
    0.5293,
    -0.4721,
    0.8242,
    -0.2439,
    0.3434,
    -0.2949,
    0.2957,
    -0.1736,
    0.2302,
    -0.1379,
    0.2549,
    -0.136,
    0.4792,
    -0.3702,
    0.2004,
    -0.2108,
    0.295,
    -0.2285,
    0.2641,
    -0.217,
    0.2806,
    -0.27,
    0.8553,
    -0.4231,
    0.7095,
    -0.521,
    0.6159,
    -0.7698,
    0.5232,
    -0.1437,
    0.152,
    -0.2688,
    0.6985,
    -0.3159,
    0.8113,
    -0.2097,
    0.4015,
    -0.133,
    0.1965,
    -0.1244,
    0.2626,
    -0.2633,
    0.1424,
    -0.1668,
    0.1566,
    -0.102,
    0.2214,
    -0.1638,
    0.0929,
    -0.0801,
    0.1691,
    -0.1059,
    0.1748,
    -0.1262,
    0.148,
    -0.2223,
    0.6007,
    -0.6061,
    0.702,
    -0.6354,
    0.82,
    -0.7441,
    0.8255,
    -0.6241,
    0.2192,
    -0.3401,
    0.9368,
    -0.5087,
    0.1476,
    -0.5271,
    0.0734,
    -0.1096,
    0.1511,
    -0.0526,
    0.6703,
    -0.5498,
    0.3598,
    -0.5324,
    0.4313,
    -0.4823,
    0.5642,
    -0.79,
    0.5286,
    -0.5826,
    0.1852,
    -0.3412,
    0.0511,
    -0.2218,
    0.53,
    -0.3812,
    0.5985,
    -0.367,
    0.393,
    -0.2733,
    0.7316,
    -0.4842,
    0.5532,
    -0.4864,
    0.6303,
    -0.4031,
    0.0645,
    -0.2715,
    0.2275,
    -0.3203,
    0.9311,
    -0.366,
    0.2605,
    -0.3165,
    0.527,
    -0.2862,
    0.1736,
    -0.2312,
    0.305,
    -0.2286,
    0.2573,
    -0.1582,
    0.0917,
    -0.2689,
    0.1258,
    -0.3548,
    0.4171,
    -0.3189,
    0.253,
    -0.1114,
    0.2307,
    -0.1812,
    0.6929,
    -0.53,
    0.6809,
    -0.4613,
    0.5339,
    -0.3266,
    0.3076,
    -0.5323,
    0.6875,
    -0.304,
    0.2716,
    -0.3268,
    0.2469,
    -0.2516,
    0.3041,
    -0.1433,
    0.8379,
    -0.3583,
    0.5109,
    -0.4913,
    0.3958,
    -0.46,
    0.3303,
    -0.368,
    0.3394,
    -0.3641,
    0.1514,
    -0.176,
    0.061,
    -0.2705,
    0.2944,
    -0.1484,
    0.367,
    -0.2689,
    0.3967,
    -0.3778,
    0.1042,
    -0.1182,
    0.2829,
    -0.2703,
    0.8039,
    -0.2761,
    0.5819,
    -0.6457,
    0.2367,
    -0.3523,
    0.2981,
    -0.5229,
    0.5673,
    -0.6804,
    0.065,
    -0.4242,
    0.6061,
    -0.4447,
    0.2002,
    -0.1905,
    0.4197,
    -0.2108,
    0.9022,
    -0.4332,
    0.3342,
    -0.2584,
    0.5033,
    -0.3063,
    0.1161,
    -0.2956,
    0.443,
    -0.122,
    0.2954,
    -0.2304,
    0.1855,
    -0.2211,
    0.3139,
    -0.1513,
    0.2967,
    -0.3372,
    0.2715,
    -0.1223,
    0.0827,
    -0.1173,
    0.1345,
    -0.1332,
    0.0763,
    -0.1083,
    0.1454,
    -0.1628,
    0.1425,
    -0.1419,
    0.1739,
    -0.1323,
    0.2287,
    -0.1605,
    0.2265,
    -0.1696,
    0.0836,
    -0.0958,
    0.1629,
    -0.1955,
    0.1437,
    -0.1946,
    0.6489,
    -0.2197,
    0.1078,
    -0.0861,
    0.5157,
    -0.8078,
    0.2081,
    -0.235,
    0.3313,
    -0.3952,
    0.238,
    -0.1826,
    0.1984,
    -0.7527,
    0.5838,
    -0.5887,
    0.844,
    -0.6521,
    0.5699,
    -0.6925,
    0.2236,
    -0.2385,
    0.1726,
    -0.2025,
    0.3787,
    -0.2007,
    0.5187,
    -0.3287,
    0.2757,
    -0.3761,
    0.4404,
    -0.2541,
    0.3418,
    -0.1937,
    0.0597,
    -0.0409,
    0.3329,
    -0.4357,
    0.2588,
    -0.2309,
    0.0463,
    -0.0396,
    0.3092,
    -0.0746,
    0.8107,
    -0.2043,
    0.1754,
    -0.1489,
    0.3986,
    -0.1335,
    0.357,
    -0.2613,
    0.418,
    -0.2208,
    0.3392,
    -0.3119,
    0.7434,
    -0.4658,
    0.5833,
    -0.4472,
    0.3368,
    -0.8695,
    0.0697,
    -0.2351,
    0.1321,
    -0.1093,
    0.1011,
    -0.6517,
    0.3559,
    -0.2812,
    0.2234,
    -0.2827,
    0.2812,
    -0.3383,
    0.4747,
    -0.4206,
    0.1295,
    -0.2652,
    0.4287,
    -0.0551,
    0.4231,
    -0.1965,
    0.2322,
    -0.2625,
    0.2886,
    -0.1673,
    0.3105,
    -0.3153,
    0.2483,
    -0.1904,
    0.2137,
    -0.2703,
    0.127,
    -0.2135,
    0.1585,
    -0.1562,
    0.1808,
    -0.0951,
    0.1414,
    -0.1204,
    0.4132,
    -0.2672,
    0.4307,
    -0.2895,
    0.6826,
    -0.423,
    0.6749,
    -0.7713,
    0.4196,
    -0.6163,
    0.3544,
    -0.4271,
    0.4441,
    -0.5209,
    0.1492,
    -0.2649,
    0.0834,
    -0.0998,
    0.1108,
    -0.1055,
    0.1468,
    -0.205,
    0.1215,
    -0.2021,
    0.1186,
    -0.158,
    0.8913,
    -0.076,
    0.6144,
    -0.4211,
    0.5039,
    -0.3194,
    0.1228,
    -0.1341,
    0.8019,
    -0.4412,
    0.5269,
    -0.3711,
    0.3556,
    -0.2241,
    0.7192,
    -0.6894,
    0.3808,
    -0.1495,
    0.7025,
    -0.4081,
    0.437,
    -0.2974,
    0.2401,
    -0.3438,
    0.3813,
    -0.2941,
    0.5646,
    -0.5135,
    0.2559,
    -0.1933,
    0.2949,
    -0.1404,
    0.1638,
    -0.2841,
    0.1262,
    -0.1293,
    0.3873,
    -0.3157,
    0.1721,
    -0.19,
    0.1848,
    -0.2216,
    0.1555,
    -0.1361,
    0.099,
    -0.0672,
    0.1109,
    -0.0887,
    0.0984,
    -0.1252,
    0.0994,
    -0.1995,
    0.1623,
    -0.107,
    0.1287,
    -0.141,
    0.702,
    -0.422,
    0.4279,
    -0.6885,
    0.777,
    -0.6063,
    -0.1252,
    -0.7648,
    0.2545,
    -0.4559,
    0.1747,
    -0.0754,
    0.1856,
    -0.1626,
    0.4998,
    -0.4751,
    0.6927,
    -0.5809,
    0.0289,
    -0.159,
    0.1806,
    -0.0724,
    0.2264,
    -0.1355,
    0.4561,
    -0.4693,
    0.2684,
    -0.3364,
    0.8472,
    -0.4552,
    0.1879,
    -0.2375,
    0.3059,
    -0.2474,
    0.3723,
    -0.3107,
    0.1417,
    -0.2522,
    0.0742,
    -0.1783,
    0.5037,
    -0.149,
    0.2731,
    -0.1813,
    0.135,
    -0.1978,
    0.2902,
    -0.2982,
    0.2908,
    -0.3129,
    0.6068,
    -0.4608,
    0.5046,
    -0.3717,
    0.1201,
    -0.2484,
    0.469,
    -0.3098,
    0.5632,
    -0.4662,
    0.0472,
    -0.1643,
    0.1741,
    -0.0937,
    0.0596,
    -0.4101,
    0.4744,
    -0.372,
    0.2532,
    -0.4456,
    0.375,
    -0.3566,
    -0.0359,
    -0.2822,
    0.1982,
    -0.3315,
    0.1506,
    -0.1979,
    0.2325,
    -0.386,
    0.1421,
    -0.1345,
    0.1087,
    -0.1078,
    0.1181,
    -0.1486,
    0.1035,
    -0.0907,
    0.0791,
    -0.1062,
    0.1247,
    -0.0865,
    0.0974,
    -0.1063,
    0.2061,
    -0.0738,
    0.8026,
    -0.1177,
    0.7634,
    -0.3083,
    0.8933,
    -0.1625,
    0.8745,
    0.4958,
    0.6918,
    -0.6895,
    0.6093,
    -0.7626,
    0.3275,
    -0.1961,
    0.7502,
    -0.7383,
    0.3849,
    -0.4786,
    0.4606,
    -0.6927,
    0.646,
    -0.167,
    -0.0627,
    -0.4688,
    0.0934,
    -0.232,
    0.2248,
    -0.2734,
    0.7196,
    -0.1717,
    0.719,
    -0.4543,
    0.2393,
    -0.2891,
    0.0372,
    -0.073,
    0.4414,
    -0.3692,
    0.4952,
    -0.2636,
    0.4599,
    -0.3325,
    0.4071,
    -0.2427,
    0.1601,
    -0.3429,
    0.5522,
    -0.4703,
    0.6,
    -0.5977,
    0.412,
    -0.4623,
    0.5457,
    -0.3833,
    0.4624,
    -0.7329,
    0.3957,
    -0.081,
    0.6355,
    -0.6764,
    0.806,
    -0.5203,
    0.29,
    -0.3506,
    0.1425,
    -0.1368,
    0.2057,
    -0.1452,
    0.4876,
    -0.3535,
    0.2025,
    -0.1973,
    0.1679,
    -0.1871,
    0.2034,
    -0.2949,
    0.1356,
    -0.4608,
    0.1176,
    -0.3472,
    0.3701,
    -0.3313,
    0.1381,
    -0.0914,
    0.2209,
    -0.2898,
    0.3226,
    -0.2665,
    0.5852,
    -0.3143,
    0.8187,
    -0.3144,
    0.3074,
    -0.2711,
    0.3015,
    -0.1286,
    0.1631,
    -0.1593,
    0.1677,
    -0.0983,
    0.2487,
    -0.2532,
    0.6626,
    -0.7072,
    0.5668,
    -0.5214,
    0.7338,
    -0.7519,
    0.1273,
    -0.3791,
    0.9208,
    -0.5196,
    0.5408,
    -0.5927,
    0.2129,
    -0.108,
    0.0717,
    -0.1388,
    0.2928,
    -0.2375,
    0.8305,
    -0.3403,
    0.1717,
    -0.287,
    0.6358,
    -0.2704,
    0.1921,
    -0.15,
    0.1218,
    -0.0839,
    0.1495,
    -0.1806,
    0.1877,
    -0.28,
    0.1005,
    -0.1218,
    0.0798,
    -0.117,
    0.0795,
    -0.0712,
    0.1974,
    -0.1469,
    0.1812,
    -0.1971,
    0.0952,
    -0.1348,
    0.1348,
    -0.1293,
    0.0987,
    -0.1623,
    0.1375,
    -0.1171,
    0.0968,
    -0.1595,
    0.1333,
    -0.1054,
    0.1561,
    -0.1002,
    0.4448,
    -0.2351,
    0.3095,
    -0.3663,
    0.2573,
    -0.144,
    0.1674,
    -0.1816,
    0.3434,
    -0.4731,
    0.3669,
    -0.2706,
    0.3915,
    -0.4082,
    0.4128,
    -0.4186,
    0.4848,
    -0.6719,
    0.2724,
    -0.5135,
    0.5803,
    -0.5974,
    0.4453,
    -0.5237,
    0.605,
    -0.7099,
    0.2969,
    -0.313,
    0.2295,
    -0.3904,
    0.273,
    -0.5624,
    0.7059,
    -0.6702,
    -0.2629,
    -0.5674,
    0.2242,
    -0.5615,
    0.1238,
    -0.1329,
    0.4395,
    -0.5051,
    0.6621,
    -0.6211,
    0.5615,
    -0.3737,
    0.1436,
    -0.2826,
    0.1349,
    -0.0783,
    0.077,
    -0.0613,
    0.0645,
    -0.0864,
    0.0846,
    -0.1184,
    0.1413,
    -0.1019,
    0.094,
    -0.1191,
    0.1487,
    -0.0837,
    0.2748,
    -0.2147,
    0.2764,
    -0.3418,
    0.7867,
    -0.6419,
    0.4751,
    -0.5562,
    0.2735,
    -0.478,
    0.2345,
    -0.1647,
    0.8363,
    -0.4266,
    0.7167,
    -0.5174,
    0.3912,
    -0.4353,
    0.3294,
    -0.1884,
    0.4011,
    -0.3368,
    0.3393,
    -0.4854,
    0.1492,
    -0.2942,
    0.2839,
    -0.3777,
    0.8227,
    -0.4728,
    0.6044,
    -0.5239,
    0.3338,
    -0.5349,
    0.2316,
    -0.3044,
    0.302,
    -0.2891,
    0.128,
    -0.0626,
    0.0451,
    -0.1805,
    0.8179,
    -0.6766,
    0.5019,
    -0.5614,
    0.4048,
    -0.4042,
    0.389,
    -0.8174,
    0.522,
    -0.7406,
    0.19,
    -0.402,
    0.5539,
    -0.0872,
    0.2833,
    -0.1464,
    0.1127,
    -0.1851,
    0.8133,
    -0.3116,
    0.8236,
    -0.5807,
    0.5352,
    -0.4613,
    0.4756,
    -0.1838,
    0.3748,
    -0.08,
    0.3959,
    -0.083,
    0.1734,
    -0.122,
    0.4489,
    -0.3328,
    0.4879,
    -0.3652,
    0.3579,
    -0.4249,
    0.237,
    -0.3977,
    0.1879,
    -0.1586,
    0.2125,
    -0.35,
    0.1814,
    -0.2137,
    0.0616,
    -0.0919,
    0.1988,
    -0.473,
    0.3994,
    -0.4881,
    0.2044,
    -0.2986,
    0.6339,
    -0.2124,
    0.2946,
    -0.3013,
    0.1388,
    -0.0806,
    0.2543,
    -0.2181,
    0.8885,
    -0.3928,
    0.7553,
    -0.2725,
    0.1779,
    -0.2295,
    0.0396,
    -0.3061,
    0.562,
    -0.1868,
    0.7374,
    -0.3133,
    0.7668,
    -0.545,
    0.201,
    -0.3513,
    0.1042,
    -0.1394,
    0.6087,
    -0.2089,
    0.5308,
    -0.3824,
    0.3938,
    -0.3879,
    0.6901,
    -0.163,
    0.5124,
    -0.2855,
    0.1101,
    -0.174,
    0.1707,
    -0.2553,
    0.0763,
    -0.1024,
    0.1914,
    -0.1047,
    0.1446,
    -0.0131,
    0.0932,
    -0.0674,
    0.0577,
    -0.1645,
    0.121,
    -0.1965,
    0.1383,
    -0.0803,
    0.0918,
    -0.1323,
    0.1337,
    -0.1051,
    0.1183,
    -0.1251,
    0.2691,
    -0.1095,
    0.8767,
    -0.5684,
    0.8271,
    -0.5264,
    0.627,
    -0.5808,
    0.6096,
    -0.862,
    0.5924,
    -0.1612,
    0.3491,
    -0.4198,
    0.388,
    -0.2018,
    0.6502,
    -0.4877,
    0.2959,
    -0.7238,
    0.5527,
    -0.7487,
    0.2442,
    -0.802,
    0.1759,
    -0.156,
    0.4435,
    -0.3109,
    0.4456,
    -0.4937,
    0.333,
    -0.2705,
    0.6911,
    -0.5861,
    0.1788,
    -0.1451,
    0.1363,
    -0.1788,
    0.4164,
    -0.3067,
    0.4742,
    -0.2471,
    0.4655,
    -0.3461,
    0.2688,
    -0.216,
    0.2943,
    -0.2731,
    0.1913,
    -0.3255,
    0.3957,
    -0.1882,
    0.42,
    -0.3238,
    0.147,
    -0.3338,
    0.3932,
    -0.4342,
    0.1399,
    -0.1129,
    0.1233,
    -0.2689,
    0.513,
    -0.5737,
    0.8283,
    -0.8144,
    0.889,
    -0.3292,
    0.6631,
    -0.2928,
    0.4084,
    -0.2686,
    0.9,
    -0.1842,
    0.1867,
    -0.1641,
    0.3723,
    -0.1545,
    0.1002,
    -0.1721,
    0.2714,
    -0.2522,
    0.1513,
    -0.0871,
    0.0973,
    -0.0721,
    0.1032,
    -0.0704,
    0.0789,
    -0.1459,
    0.1182,
    -0.1388,
    0.1291,
    -0.1443,
    0.1268,
    -0.1448,
    0.1215,
    -0.1709,
    0.1071,
    -0.1187,
    0.1513,
    -0.1086,
    0.1421,
    -0.1275,
    0.1848,
    -0.1578,
    0.1728,
    -0.1681,
    0.1243,
    -0.1275,
    0.1009,
    -0.1604,
    0.1534,
    -0.1,
    0.0578,
    -0.4071,
    0.4499,
    -0.4058,
    0.6015,
    -0.7971,
    0.6064,
    -0.504,
    0.7706,
    -0.8033,
    0.2548,
    -0.4067,
    0.1583,
    -0.259,
    0.8191,
    -0.596,
    0.1908,
    -0.3638,
    0.7814,
    -0.4457,
    0.4783,
    -0.424,
    0.0788,
    -0.1554,
    0.1858,
    -0.1442,
    0.4967,
    -0.1762,
    0.6979,
    -0.3013,
    0.1317,
    -0.1077,
    0.1288,
    -0.2348,
    0.1561,
    -0.1537,
    0.1165,
    -0.1202,
    0.1188,
    -0.1832,
    0.1261,
    -0.1552,
    0.1193,
    -0.0769,
    0.1251,
    -0.098,
    0.0685,
    -0.1216,
    0.0767,
    -0.1131,
    0.0683,
    -0.0515,
    0.1586,
    -0.1771,
    0.1053,
    -0.0951,
    0.0735,
    -0.0827,
    0.0997,
    -0.1498,
    0.1278,
    -0.1662,
    0.124,
    -0.0801,
    0.0774,
    -0.1069,
    0.0846,
    -0.1883,
    0.2098,
    -0.2209,
    0.3177,
    -0.3505,
    0.853,
    -0.5659,
    0.2712,
    -0.4863,
    0.8515,
    -0.3254,
    0.7642,
    -0.5741,
    0.5051,
    -0.5095,
    0.4832,
    -0.4249,
    0.1723,
    -0.4089,
    0.514,
    -0.5032,
    0.8078,
    -0.4242,
    0.157,
    -0.0814,
    0.4398,
    -0.2614,
    0.31,
    -0.1991,
    0.3935,
    -0.4374,
    0.6854,
    -0.4685,
    0.7885,
    -0.7539,
    0.893,
    -0.492,
    0.3499,
    -0.1487,
    0.2044,
    -0.0704,
    0.2128,
    -0.1141,
    0.2019,
    -0.1517,
    0.3562,
    -0.2615,
    0.1723,
    -0.2524,
    0.686,
    -0.1542,
    0.9001,
    -0.4144,
    0.0371,
    -0.3323,
    0.2297,
    -0.2536,
    0.1522,
    -0.1463,
    0.3555,
    -0.3322,
    0.5385,
    -0.2973,
    0.4877,
    -0.2047,
    0.1272,
    -0.1795,
    0.128,
    -0.2686,
    0.1969,
    -0.2185,
    0.5954,
    -0.5335,
    0.3588,
    -0.2711,
    0.6972,
    -0.4769,
    0.6214,
    -0.5485,
    0.0492,
    -0.1124,
    0.1056,
    -0.1355,
    0.4584,
    -0.208,
    0.1679,
    -0.3523,
    0.3795,
    -0.2546,
    0.2853,
    -0.2767,
    0.3793,
    -0.1469,
    0.6517,
    -0.2691,
    0.2286,
    -0.1393,
    0.8377,
    -0.6425,
    0.5628,
    -0.6679,
    0.6925,
    -0.7656,
    0.4101,
    -0.275,
    0.8864,
    -0.5741,
    0.7981,
    -0.5615,
    0.1728,
    -0.137,
    0.3414,
    -0.1758,
    0.319,
    -0.3008,
    0.4365,
    -0.267,
    0.3212,
    -0.147,
    0.1701,
    -0.2725,
    0.4529,
    -0.0916,
    0.5093,
    -0.3118,
    0.5126,
    -0.6161,
    0.5168,
    -0.5988,
    0.0292,
    -0.1612,
    0.264,
    -0.2173,
    0.1815,
    -0.1252,
    0.2512,
    -0.3681,
    0.5563,
    -0.2897,
    0.1134,
    -0.2054,
    0.1565,
    -0.1805,
    0.586,
    -0.33,
    0.1799,
    -0.3857,
    0.0473,
    -0.1153,
    0.2372,
    -0.1873,
    0.3616,
    -0.2138,
    0.4301,
    -0.1848,
    0.7441,
    -0.2762,
    0.1705,
    -0.2424,
    0.5185,
    -0.2684,
    0.3103,
    -0.4422,
    0.2185,
    -0.1589,
    0.4372,
    -0.5166,
    0.1658,
    -0.1506,
    0.5637,
    -0.6403,
    0.6387,
    -0.9065,
    0.1633,
    -0.1535,
    0.1081,
    -0.0907,
    0.4493,
    -0.5767,
    0.4376,
    -0.4129,
    0.1751,
    -0.3779,
    0.3043,
    -0.3489,
    0.4235,
    -0.4041,
    0.2706,
    -0.338,
    0.3511,
    -0.2873,
    0.3428,
    -0.1347,
    0.0554,
    -0.1081,
    0.0397,
    -0.0658,
    0.0351,
    -0.0335,
    0.0148,
    -0.0265,
    0.0189,
    -0.0175,
    0.0188,
    -0.0147,
    0.0152,
    -0.0152,
    0.0122,
    -0.014,
    0.0095,
    -0.0096,
    0.003,
    -0.0033,
    0.0013,
    -0.0011,
    0.0002,
    -0.0003
];

export default chapterFirstPCM;