import styled from 'styled-components';
import { fontFamily } from '../../theme/theme';

export const Wrapper = styled.div`
  background-color: #fff;
  color: #000000;
  font-family: ${fontFamily.base};
  
  h1, h2, p{
    margin: 0;
  }
`;