import styled from 'styled-components';

export const DetailPageWrap = styled.div`
  background-color: #fff;
`;

export const DetailPageHeader = styled.div`
  padding: 50px 15px 15px;
  margin: 0 auto;
  max-width: 375px;
  display: flex;
  position: relative;
  a{
    position: relative;
    z-index: 10;
  }
`;

export const DetailPageHeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  p{
    text-align: center;
  }
`;

export const DetailPageContent = styled.div`
  padding: 0px 17px 70px;
  margin: 0 auto;
  max-width: 375px;
`;

export const DetailPageCover = styled.div`
  margin-bottom: 20px;
  img {
    height: 340px;
    object-fit: cover;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }
`;

export const DetailPageCaption = styled.div`
  text-align: center;
  letter-spacing: -0.41px;
  margin-bottom: 35px;
  p {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    margin: 0 auto 10px;
  }
  span {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const DetailPageAudio = styled.div`
  margin: 0 auto;
  max-width: 375px;
`;