import styled, { css } from 'styled-components';

export const PlayerWrap = styled.div(({ theme: { colors } }) => {
    return css`
      color: ${colors.base};
    }
  `;
});

export const Controls = styled.div(({ theme: { colors } }) => {
    return css`
      color: ${colors.base};
      display: flex;
      justify-content: space-between;
      margin: 10px auto 0;
      height: 80px;
      width: 220px;
      button{
        background-color: transparent;
        border: none;
        highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      .arrow {
        margin-top: 20px;
        highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &.disabled {
          opacity: .4;
        }
      }
  `;
});