const appData = [
    {
        id: 1,
        name: 'chapter-1',
        url:'/chapter-1',
        img1:'./images/detail-1.jpg',
        img2:'./images/detail-1.jpg',
        title1:'Klus en Smaakmakers',
        title1_1: 'Blz 2 t/m 3',
        title2:'Inleiding',
        audio: 'audio-1',
        variant:'intro',
        introText: 'Welkom bij de Klus en Smaakmakers Audiotour van Karwei! Voorjaarsmagazine in de hand? Druk op play en blader door het magazine samen met Anna Drijver, Theo-Bert Pot, Tanja van Hoogdalem en Thomas Verhoef. Zij helpen jou met interieur- én klustips echt wat van je smaak te maken. Een aanrader want deze tips zijn een aanvulling op het magazine. Veel luisterplezier!'
    },
    {
        id: 2,
        name: 'chapter-2',
        url:'/chapter-2',
        img1:'./images/cover-2.jpg',
        title1:'Blz 4 t/m 19',
        title2:'Buitenleven, ja natuurlijk',
        title3: 'Inspiratie voor buiten',
        audio: 'audio-2',
        variant:'preview'
    },
    {
        id: 3,
        name: 'chapter-3',
        url:'/chapter-3',
        img1:'./images/cover-3.jpg',
        title1:'Blz 20 t/m 33',
        title2:'Op de zon gericht',
        title3: 'Inspiratie voor buiten',
        audio: 'audio-3',
        variant:'preview'
    },
    {
        id: 4,
        name: 'chapter-4',
        url:'/chapter-4',
        img1:'./images/cover-4.jpg',
        title1:'Blz 34 t/m 51',
        title2:'Haal buiten naar binnen',
        title3: 'Inspiratie voor binnen',
        audio: 'audio-4',
        variant:'preview'
    },
    {
        id: 5,
        name: 'chapter-5',
        url:'/chapter-5',
        img1:'./images/cover-5.jpg',
        title1:'Blz 52 t/m 59',
        title2:'Tinten uit de natuur',
        title3: 'Inspiratie voor binnen',
        audio: 'audio-5',
        variant:'preview'
    },
    {
        id: 6,
        name: 'chapter-6',
        url:'/chapter-6',
        img1:'./images/cover-6.jpg',
        title1:'Blz 60 t/m 67',
        title2:'Jouw lichtpuntje',
        title3: 'Inspiratie voor binnen',
        audio: 'audio-6',
        variant:'preview'
    },
    {
        id: 7,
        name: 'chapter-7',
        url:'/chapter-7',
        img1:'./images/cover-7.jpg',
        title1:'Blz 68 t/m 79',
        title2:'Trendsetters',
        title3: 'Inspiratie voor binnen',
        audio: 'audio-7',
        variant:'preview'
    },
    {
        id: 8,
        name: 'chapter-8',
        url:'/chapter-8',
        img1:'./images/cover-8.jpg',
        title1:'Blz 80 t/m 89',
        title2:'Terug naar de basis',
        title3: 'Inspiratie voor binnen',
        audio: 'audio-8',
        variant:'preview'
    },
    {
        id: 9,
        url:'/chapter-9',
        img1:'./images/cover-9.jpg',
        title1:'Blz 90 t/m 92',
        title2:'Sta open voor nieuw',
        title3: 'Inspiratie voor binnen',
        audio: 'audio-9',
        variant:'preview'
    },
    {
        id: 10,
        url:'/chapter-10',
        img1:'./images/cover-10.jpg',
        title1:'Blz 93 t/m 96',
        title2:'Klus en Smaakmakers outro!',
        title3: 'Bedankt voor het luisteren!',
        audio: 'audio-10',
        variant:'preview'
    },

];

export default appData;