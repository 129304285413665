const chapterFourthPCM = [
    0.2111,
    -0.1918,
    0.2144,
    -0.2145,
    0.2074,
    -0.2684,
    0.2211,
    -0.1367,
    0.2577,
    -0.2033,
    0.2543,
    -0.1866,
    0.1605,
    -0.2434,
    0.2044,
    -0.1318,
    0.146,
    -0.1464,
    0.1705,
    -0.1772,
    0.1852,
    -0.3301,
    0.2259,
    -0.5424,
    0.2134,
    -0.5238,
    0.316,
    -0.3643,
    0.7769,
    -0.2438,
    0.5482,
    -0.4067,
    0.5836,
    -0.6053,
    0.2631,
    -0.3751,
    0.29,
    -0.2764,
    0.6314,
    -0.1888,
    0.2041,
    -0.245,
    0.501,
    -0.4378,
    0.8823,
    -0.7129,
    0.4434,
    -0.2342,
    0.4785,
    -0.2627,
    0.5495,
    -0.378,
    0.3736,
    -0.5061,
    0.2446,
    -0.0105,
    0.345,
    -0.3214,
    0.1148,
    -0.2344,
    0.1636,
    -0.2095,
    0.2446,
    -0.6208,
    0.5218,
    -0.301,
    0.236,
    -0.3727,
    0.2133,
    -0.6829,
    0.7478,
    -0.3168,
    0.4985,
    -0.4367,
    0.5804,
    -0.4476,
    0.5583,
    -0.3387,
    0.7594,
    -0.3261,
    0.6772,
    -0.3095,
    0.623,
    -0.6028,
    0.1749,
    -0.146,
    0.2006,
    -0.2562,
    0.1399,
    -0.125,
    0.0772,
    -0.0763,
    0.0909,
    -0.0662,
    0.3505,
    -0.4225,
    0.3246,
    -0.4414,
    0.509,
    -0.7625,
    0.4188,
    -0.3064,
    0.7146,
    -0.3195,
    0.6306,
    -0.3872,
    0.6705,
    -0.6415,
    0.3509,
    -0.3057,
    0.1496,
    -0.1827,
    0.6865,
    -0.8247,
    0.335,
    -0.2615,
    0.2549,
    -0.464,
    0.1076,
    -0.0889,
    0.7355,
    -0.5498,
    0.4803,
    -0.7861,
    0.442,
    -0.4386,
    0.533,
    -0.5147,
    0.5871,
    -0.4556,
    0.2351,
    -0.3075,
    0.3898,
    -0.3251,
    0.1402,
    -0.4073,
    0.6292,
    -0.1327,
    0.5186,
    -0.4106,
    0.0892,
    -0.4047,
    0.1635,
    -0.1059,
    0.1612,
    -0.2468,
    0.3785,
    -0.4437,
    0.2848,
    -0.5182,
    0.147,
    -0.1965,
    0.0853,
    -0.1427,
    0.3195,
    -0.1918,
    0.4585,
    -0.5504,
    0.466,
    -0.6165,
    0.2595,
    -0.2391,
    0.1605,
    -0.1943,
    0.4638,
    -0.164,
    0.7089,
    -0.2153,
    0.0468,
    -0.0927,
    0.0696,
    -0.038,
    0.0759,
    -0.0982,
    0.0465,
    -0.0406,
    0.0545,
    -0.2417,
    0.5223,
    -0.1809,
    0.4662,
    -0.5273,
    0.3679,
    -0.3262,
    0.5083,
    -0.4271,
    0.3137,
    -0.4722,
    0.2491,
    -0.3586,
    0.233,
    -0.4562,
    0.9281,
    -0.4234,
    0.3559,
    -0.178,
    0.6815,
    -0.5463,
    0.3332,
    -0.2416,
    0.5398,
    -0.3392,
    0.5288,
    -0.3948,
    0.753,
    -0.277,
    0.2922,
    -0.3745,
    0.4471,
    -0.2985,
    0.4006,
    -0.1613,
    0.4108,
    -0.21,
    0.2269,
    -0.128,
    0.0667,
    -0.1326,
    0.1059,
    -0.059,
    0.643,
    -0.65,
    0.7898,
    -0.2856,
    0.4427,
    -0.2201,
    0.7736,
    -0.303,
    0.1064,
    -0.4224,
    0.395,
    -0.5979,
    0.394,
    -0.2823,
    0.5908,
    -0.4127,
    0.1051,
    -0.2048,
    0.612,
    -0.2573,
    0.5812,
    -0.1784,
    0.1324,
    -0.4303,
    0.2816,
    -0.1047,
    0.4011,
    -0.3097,
    0.6481,
    -0.3101,
    0.5948,
    -0.2792,
    0.5253,
    -0.5306,
    0.1962,
    -0.2685,
    0.093,
    -0.0947,
    0.0636,
    -0.0716,
    0.0565,
    -0.4226,
    0.0956,
    -0.4217,
    0.593,
    -0.3396,
    0.4988,
    -0.4066,
    0.2477,
    -0.0727,
    0.2391,
    -0.3037,
    0.6445,
    -0.4829,
    0.8622,
    -0.2894,
    0.7917,
    -0.1133,
    0.5468,
    -0.2967,
    0.2484,
    -0.1945,
    0.5524,
    -0.4822,
    0.554,
    -0.2335,
    0.4021,
    -0.2082,
    0.4721,
    -0.2631,
    0.1532,
    -0.3346,
    0.0845,
    -0.4214,
    0.3807,
    -0.2703,
    0.1301,
    -0.1864,
    0.1121,
    -0.4733,
    0.3415,
    -0.529,
    0.466,
    -0.3624,
    0.1348,
    -0.3446,
    0.3367,
    -0.2636,
    0.1777,
    -0.5009,
    0.3652,
    -0.3371,
    0.6729,
    -0.1354,
    0.5947,
    -0.3745,
    0.6897,
    -0.3704,
    0.2915,
    -0.2258,
    0.5568,
    -0.5035,
    0.1441,
    -0.3501,
    0.0794,
    -0.0921,
    0.0623,
    -0.0419,
    0.056,
    -0.0374,
    0.0655,
    -0.0924,
    0.4359,
    -0.1958,
    0.6723,
    -0.8241,
    0.5664,
    -0.5193,
    0.4795,
    -0.5202,
    0.3719,
    -0.4148,
    0.4752,
    -0.3375,
    0.2698,
    -0.356,
    0.5846,
    -0.3013,
    0.384,
    -0.1797,
    0.1304,
    -0.237,
    0.3355,
    -0.2473,
    0.6042,
    -0.4067,
    0.2475,
    -0.3039,
    0.4482,
    -0.2738,
    0.4111,
    -0.0733,
    0.6704,
    -0.2775,
    0.2504,
    -0.4394,
    0.7502,
    -0.4192,
    0.306,
    -0.0954,
    0.3734,
    -0.3085,
    0.2558,
    -0.5239,
    0.4743,
    -0.5324,
    0.3133,
    -0.2382,
    0.3465,
    -0.2296,
    0.2279,
    -0.195,
    0.0217,
    -0.1003,
    0.056,
    -0.044,
    0.3217,
    -0.2241,
    0.606,
    -0.3036,
    0.1502,
    -0.4837,
    0.5587,
    -0.4971,
    0.3267,
    -0.4597,
    0.2086,
    -0.1417,
    0.6355,
    -0.4393,
    0.1625,
    -0.2298,
    0.3125,
    -0.259,
    0.6924,
    -0.2171,
    0.2696,
    -0.3598,
    0.2018,
    -0.1653,
    0.0458,
    -0.059,
    0.0806,
    -0.0627,
    0.2004,
    -0.1375,
    0.5149,
    -0.3864,
    0.3549,
    -0.2628,
    0.1504,
    -0.2843,
    0.4747,
    -0.3612,
    0.6472,
    -0.5077,
    0.1837,
    -0.1631,
    0.4843,
    -0.2767,
    0.411,
    -0.5108,
    0.6493,
    -0.2787,
    0.1209,
    -0.1442,
    0.7663,
    -0.3633,
    0.1861,
    -0.1096,
    0.0529,
    -0.055,
    0.5927,
    -0.4575,
    0.627,
    -0.5129,
    0.3983,
    -0.2964,
    0.117,
    -0.1921,
    0.689,
    -0.208,
    0.7342,
    -0.44,
    0.0991,
    -0.1481,
    0.3718,
    -0.6034,
    0.2095,
    -0.091,
    0.4744,
    -0.2498,
    0.4072,
    -0.1892,
    0.1017,
    -0.121,
    0.0781,
    -0.0938,
    0.0397,
    -0.0699,
    0.0602,
    -0.0914,
    0.0587,
    -0.0574,
    0.0836,
    -0.2725,
    0.2613,
    -0.6225,
    0.4424,
    -0.5028,
    0.4653,
    -0.3342,
    0.0759,
    -0.1275,
    0.7734,
    -0.6072,
    0.5996,
    -0.5438,
    0.0514,
    -0.5297,
    0.5551,
    -0.4222,
    0.1339,
    -0.2927,
    0.4001,
    -0.3768,
    0.7992,
    -0.0606,
    0.4829,
    -0.2752,
    0.4836,
    -0.2472,
    0.3183,
    -0.1902,
    0.5957,
    -0.1621,
    0.2845,
    -0.2047,
    0.1493,
    -0.1338,
    0.316,
    -0.0933,
    0.3661,
    -0.2558,
    0.5663,
    -0.2483,
    0.3674,
    -0.286,
    0.3132,
    -0.2229,
    0.1801,
    -0.4018,
    0.1602,
    -0.4171,
    0.3044,
    -0.2962,
    0.2557,
    -0.3729,
    0.1861,
    -0.2148,
    0.1231,
    -0.0979,
    0.0859,
    -0.0628,
    0.0485,
    -0.0792,
    0.0461,
    -0.0668,
    0.245,
    -0.2574,
    0.7883,
    -0.4385,
    0.5976,
    -0.3163,
    0.5516,
    -0.5192,
    0.3224,
    -0.1275,
    0.7419,
    -0.337,
    0.2187,
    -0.1826,
    0.2004,
    -0.4007,
    0.3923,
    -0.1507,
    0.364,
    -0.125,
    0.6902,
    -0.4219,
    0.3573,
    -0.4001,
    0.4288,
    -0.3874,
    0.5312,
    -0.3453,
    0.2935,
    -0.1714,
    0.2022,
    -0.1779,
    0.4059,
    -0.4319,
    0.1393,
    -0.3351,
    0.2249,
    -0.2401,
    0.6216,
    -0.3373,
    0.725,
    -0.4345,
    0.2557,
    -0.2302,
    0.0487,
    -0.023,
    0.3306,
    -0.2706,
    0.8353,
    -0.4169,
    0.4505,
    -0.3336,
    0.0706,
    -0.3756,
    0.3164,
    -0.4388,
    0.1746,
    -0.2247,
    0.053,
    -0.0454,
    0.0082,
    -0.0756,
    0.0712,
    -0.0839,
    0.8098,
    -0.5149,
    0.6608,
    -0.2946,
    0.3314,
    -0.3825,
    0.4673,
    -0.4251,
    0.0309,
    -0.3949,
    0.5672,
    -0.5304,
    0.194,
    -0.4044,
    0.1541,
    -0.2056,
    0.4018,
    -0.4863,
    0.4432,
    -0.1525,
    0.1258,
    -0.377,
    0.3785,
    -0.4479,
    0.2999,
    -0.2811,
    0.0474,
    -0.0836,
    0.6616,
    -0.5526,
    0.4911,
    -0.091,
    0.3403,
    -0.4307,
    0.7636,
    -0.2944,
    0.6587,
    -0.167,
    0.4363,
    -0.1861,
    0.5092,
    -0.463,
    0.1977,
    -0.3823,
    0.44,
    -0.4647,
    0.7323,
    -0.2418,
    0.6304,
    -0.624,
    0.7212,
    -0.4928,
    0.2559,
    -0.5636,
    0.4861,
    -0.6113,
    0.0565,
    -0.0759,
    0.0743,
    -0.0659,
    0.64,
    -0.4854,
    0.2787,
    -0.3643,
    0.1198,
    -0.3486,
    0.7011,
    -0.2738,
    0.3712,
    -0.2619,
    0.6745,
    -0.3611,
    0.5166,
    -0.3756,
    0.1246,
    -0.3819,
    0.3169,
    -0.2075,
    0.1251,
    -0.1706,
    0.0694,
    -0.0533,
    0.0659,
    -0.0435,
    0.0311,
    -0.0498,
    0.0493,
    -0.0522,
    0.8791,
    -0.6396,
    0.3704,
    -0.5937,
    0.6869,
    -0.5689,
    0.6698,
    -0.6681,
    0.4464,
    -0.4813,
    0.4588,
    -0.6178,
    0.5287,
    -0.247,
    0.3058,
    -0.3256,
    0.43,
    -0.3265,
    0.1202,
    -0.221,
    0.2568,
    -0.3065,
    0.3472,
    -0.353,
    0.4874,
    -0.6263,
    0.1758,
    -0.1929,
    0.1289,
    -0.131,
    0.0383,
    -0.0518,
    0.1453,
    -0.1706,
    0.4479,
    -0.0792,
    0.5016,
    -0.5278,
    0.6967,
    -0.3807,
    0.3459,
    -0.5472,
    0.6337,
    -0.4141,
    0.7048,
    -0.3576,
    0.6989,
    -0.7556,
    0.0555,
    -0.0606,
    0.5102,
    -0.5697,
    0.1453,
    -0.5628,
    0.4159,
    -0.5025,
    0.0661,
    -0.2492,
    0.0591,
    -0.0364,
    0.0305,
    -0.0485,
    0.0668,
    -0.0469,
    0.024,
    -0.0416,
    0.0691,
    -0.0293,
    0.5944,
    -0.589,
    0.7005,
    -0.4973,
    0.523,
    -0.371,
    0.3245,
    -0.3559,
    0.1179,
    -0.3306,
    0.4176,
    -0.3454,
    0.4552,
    -0.4934,
    0.3772,
    -0.5934,
    0.544,
    -0.5415,
    0.2335,
    -0.1475,
    0.1223,
    -0.2336,
    0.4012,
    -0.1269,
    0.1508,
    -0.1071,
    0.2075,
    -0.1839,
    0.1892,
    -0.111,
    0.1288,
    -0.1612,
    0.0408,
    -0.3736,
    0.1737,
    -0.2449,
    0.3349,
    -0.6735,
    0.3972,
    -0.2657,
    0.1618,
    -0.2641,
    0.3295,
    -0.4146,
    0.4352,
    -0.2044,
    0.1945,
    -0.0762,
    0.164,
    -0.2117,
    0.2775,
    -0.3491,
    0.0241,
    -0.1259,
    0.8805,
    -0.1576,
    0.0578,
    -0.4419,
    0.0479,
    -0.3099,
    0.122,
    -0.2248,
    0.3001,
    -0.1111,
    0.4273,
    -0.5022,
    0.3017,
    -0.3489,
    0.2506,
    -0.4033,
    0.7344,
    -0.3692,
    0.1507,
    -0.3542,
    0.6151,
    -0.1832,
    0.2393,
    -0.072,
    0.0719,
    -0.0739,
    0.5125,
    -0.3403,
    0.4115,
    -0.6036,
    0.4033,
    -0.5059,
    0.6714,
    -0.4646,
    0.3898,
    -0.1942,
    0.0362,
    -0.1882,
    0.2775,
    -0.1804,
    0.1706,
    -0.1371,
    0.1686,
    -0.0873,
    0.6863,
    -0.4402,
    0.4411,
    -0.4857,
    0.4542,
    -0.4915,
    0.2669,
    -0.7211,
    0.4774,
    -0.3404,
    0.1595,
    -0.5619,
    0.1887,
    -0.5904,
    0.3932,
    -0.4078,
    0.2929,
    -0.2244,
    0.0637,
    -0.4786,
    0.2559,
    -0.4006,
    0.267,
    -0.5297,
    0.2384,
    -0.074,
    0.4308,
    -0.295,
    0.3896,
    -0.4185,
    0.6279,
    -0.8075,
    0.6142,
    -0.6657,
    0.7581,
    -0.5125,
    0.0855,
    -0.2343,
    0.3164,
    -0.2432,
    0.2474,
    -0.1371,
    0.1721,
    -0.4177,
    0.0369,
    -0.0481,
    0.0485,
    -0.0192,
    0.0211,
    -0.0262,
    0.036,
    -0.0441,
    0.0854,
    -0.0426,
    0.1815,
    -0.6536,
    0.5201,
    -0.7794,
    0.4389,
    -0.3488,
    0.0817,
    -0.3321,
    0.0973,
    -0.1535,
    0.0974,
    -0.3157,
    0.3297,
    -0.1974,
    0.3558,
    -0.4637,
    0.0577,
    -0.0629,
    0.2809,
    -0.1595,
    0.2049,
    -0.1146,
    0.463,
    -0.504,
    0.5608,
    -0.5838,
    0.5935,
    -0.6394,
    0.6396,
    -0.2362,
    0.5625,
    -0.284,
    0.558,
    -0.58,
    0.2907,
    -0.0345,
    0.1441,
    -0.1514,
    0.2075,
    -0.3014,
    0.053,
    -0.0464,
    0.0756,
    -0.421,
    0.5547,
    -0.65,
    0.4741,
    -0.7459,
    0.5704,
    -0.6603,
    0.3403,
    -0.3587,
    0.154,
    -0.2672,
    0.7536,
    -0.7618,
    0.5543,
    -0.5339,
    0.4074,
    -0.3776,
    0.0644,
    -0.1694,
    0.1888,
    -0.106,
    0.3556,
    -0.1304,
    0.1511,
    -0.5151,
    0.477,
    -0.3779,
    0.4179,
    -0.4074,
    0.3041,
    -0.3585,
    0.2658,
    -0.4065,
    0.2884,
    -0.231,
    0.3178,
    -0.3564,
    0.2286,
    -0.348,
    0.3712,
    -0.4073,
    0.0534,
    -0.0479,
    0.0533,
    -0.0204,
    0.2134,
    -0.2114,
    0.3766,
    -0.253,
    0.0337,
    -0.389,
    0.0489,
    -0.0573,
    0.3992,
    -0.0427,
    0.6343,
    -0.4925,
    0.1267,
    -0.3251,
    0.4329,
    -0.4982,
    0.362,
    -0.594,
    0.3292,
    -0.4011,
    0.2107,
    -0.2407,
    0.2914,
    -0.2183,
    0.2693,
    -0.0533,
    0.1679,
    -0.2199,
    0.4663,
    -0.2702,
    0.2888,
    -0.3362,
    0.2673,
    -0.1417,
    0.0403,
    -0.021,
    0.3002,
    -0.0397,
    0.322,
    -0.6618,
    0.5336,
    -0.494,
    0.4452,
    -0.888,
    0.2275,
    -0.1902,
    0.3124,
    -0.2838,
    0.0512,
    -0.1448,
    0.0126,
    -0.0116,
    0.0433,
    -0.041,
    0.8168,
    -0.3865,
    0.4433,
    -0.484,
    0.338,
    -0.6799,
    0.8138,
    -0.3762,
    0.5664,
    -0.3241,
    0.1352,
    -0.0475,
    0.1305,
    -0.1039,
    0.1518,
    -0.1663,
    0.107,
    -0.4242,
    0.4015,
    -0.4383,
    0.046,
    -0.3002,
    0.2487,
    -0.1939,
    0.4626,
    -0.2313,
    0.2537,
    -0.7425,
    0.3149,
    -0.4332,
    0.0917,
    -0.42,
    0.5713,
    -0.6176,
    0.366,
    -0.4437,
    0.2573,
    -0.51,
    0.0832,
    -0.2427,
    0.5899,
    -0.5763,
    0.4079,
    -0.4652,
    0.5925,
    -0.171,
    0.5558,
    -0.6325,
    0.3577,
    -0.5991,
    0.0614,
    -0.0634,
    0.0485,
    -0.0576,
    0.0569,
    -0.0446,
    0.0868,
    -0.2949,
    0.4901,
    -0.3954,
    0.2288,
    -0.3694,
    0.4041,
    -0.5175,
    0.7553,
    -0.6831,
    0.3455,
    -0.3918,
    0.2344,
    -0.4792,
    0.0709,
    -0.1362,
    0.2568,
    -0.3121,
    0.3855,
    -0.2897,
    0.2891,
    -0.5805,
    0.7055,
    -0.7974,
    0.6246,
    -0.4615,
    0.3564,
    -0.2515,
    0.3771,
    -0.3943,
    0.2053,
    -0.2788,
    0.5291,
    -0.5485,
    0.2512,
    -0.4208,
    0.0906,
    -0.0798,
    0.0728,
    -0.0622,
    0.0542,
    -0.052,
    0.0507,
    -0.0606,
    0.0231,
    -0.3937,
    0.1771,
    -0.4416,
    0.2587,
    -0.3409,
    0.4873,
    -0.415,
    0.4238,
    -0.2433,
    0.2221,
    -0.496,
    0.203,
    -0.1858,
    0.4709,
    -0.7317,
    0.7121,
    -0.6103,
    0.3422,
    -0.45,
    0.306,
    -0.4997,
    0.5895,
    -0.5092,
    0.492,
    -0.5226,
    0.5012,
    -0.1878,
    0.2782,
    -0.2416,
    0.062,
    -0.2696,
    0.5566,
    -0.3995,
    0.412,
    -0.3776,
    0.1938,
    -0.3457,
    0.2025,
    -0.2149,
    0.1267,
    -0.1672,
    0.3053,
    -0.5649,
    0.2779,
    -0.4593,
    0.1743,
    -0.1814,
    0.2478,
    -0.208,
    0.0605,
    -0.0525,
    0.061,
    -0.065,
    0.3071,
    -0.581,
    0.3462,
    -0.566,
    0.2824,
    -0.3371,
    0.4835,
    -0.1694,
    0.5156,
    -0.5508,
    0.5199,
    -0.1101,
    0.0476,
    -0.0583,
    0.4004,
    -0.2074,
    0.3864,
    -0.6149,
    0.3301,
    -0.1891,
    0.141,
    -0.388,
    0.5145,
    -0.135,
    0.2977,
    -0.3924,
    0.1402,
    -0.1297,
    0.6551,
    -0.5126,
    0.4551,
    -0.4325,
    0.2179,
    -0.3781,
    0.5482,
    -0.6898,
    0.1499,
    -0.0834,
    0.145,
    -0.5025,
    0.286,
    -0.7093,
    0.062,
    -0.0424,
    0.0576,
    -0.0536,
    0.0531,
    -0.0569,
    0.1677,
    -0.2783,
    0.5183,
    -0.0566,
    0.2928,
    -0.4985,
    0.0793,
    -0.5522,
    0.5428,
    -0.3507,
    0.6176,
    -0.8337,
    0.7073,
    -0.7778,
    0.5251,
    -0.6292,
    0.0987,
    -0.1568,
    0.3293,
    -0.657,
    0.3409,
    -0.6708,
    0.3936,
    -0.4157,
    0.2329,
    -0.7538,
    0.3837,
    -0.3,
    0.3552,
    -0.3701,
    0.4507,
    -0.5048,
    0.4884,
    -0.3074,
    0.2105,
    -0.2118,
    0.2956,
    -0.3321,
    0.0766,
    -0.0848,
    0.2556,
    -0.151,
    0.3875,
    -0.0639,
    0.3741,
    -0.2255,
    0.4492,
    -0.3296,
    0.49,
    -0.0705,
    0.0481,
    -0.0449,
    0.0637,
    -0.0417,
    0.6602,
    -0.59,
    0.2946,
    -0.3935,
    0.4479,
    -0.1735,
    0.4445,
    -0.1514,
    0.7102,
    -0.4212,
    0.6075,
    -0.2946,
    0.1416,
    -0.3523,
    0.4542,
    -0.4229,
    0.2234,
    -0.2002,
    0.6614,
    -0.1923,
    0.1588,
    -0.4268,
    0.3229,
    -0.4774,
    0.2962,
    -0.1546,
    0.4462,
    -0.1483,
    0.0751,
    -0.0893,
    0.054,
    -0.0439,
    0.0567,
    -0.0502,
    0.0613,
    -0.0469,
    0.0509,
    -0.0417,
    0.2317,
    -0.0906,
    0.4817,
    -0.7257,
    0.2316,
    -0.5607,
    0.0564,
    -0.0491,
    0.049,
    -0.0671,
    0.4774,
    -0.2621,
    0.0926,
    -0.1952,
    0.7404,
    -0.1203,
    0.5064,
    -0.4877,
    0.6282,
    -0.1399,
    0.4471,
    -0.476,
    0.0616,
    -0.2256,
    0.0624,
    -0.0702,
    0.0492,
    -0.0711,
    0.0522,
    -0.0839,
    0.2102,
    -0.4119,
    0.5198,
    -0.6298,
    0.1763,
    -0.1854,
    0.4632,
    -0.2898,
    0.3524,
    -0.3593,
    0.1009,
    -0.1659,
    0.0556,
    -0.0381,
    0.0985,
    -0.0802,
    0.6401,
    -0.6779,
    0.2873,
    -0.4493,
    0.4011,
    -0.3286,
    0.4,
    -0.7743,
    0.378,
    -0.323,
    0.0659,
    -0.0497,
    0.0496,
    -0.0654,
    0.1543,
    -0.5219,
    0.651,
    -0.5336,
    0.3405,
    -0.277,
    0.6438,
    -0.4964,
    0.1383,
    -0.428,
    0.4816,
    -0.3166,
    0.2513,
    -0.1178,
    0.3078,
    -0.1585,
    0.1418,
    -0.4671,
    0.0745,
    -0.7439,
    0.6833,
    -0.8213,
    0.156,
    -0.0411,
    0.3985,
    -0.1091,
    0.0714,
    -0.0508,
    0.0533,
    -0.0559,
    0.0485,
    -0.0603,
    0.6388,
    -0.0944,
    0.2702,
    -0.6268,
    0.2506,
    -0.2639,
    0.7935,
    -0.5722,
    0.4879,
    -0.5695,
    0.2173,
    -0.332,
    0.6537,
    -0.2898,
    0.4377,
    -0.3639,
    0.2018,
    -0.4274,
    0.3005,
    -0.3226,
    0.2232,
    -0.0784,
    0.6055,
    -0.3441,
    0.5932,
    -0.4427,
    0.2212,
    -0.2769,
    0.4848,
    -0.3739,
    0.2339,
    -0.3637,
    0.3151,
    -0.1617,
    0.4656,
    -0.2581,
    0.4439,
    -0.2843,
    0.0351,
    -0.2104,
    0.0362,
    -0.0316,
    0.0372,
    -0.0334,
    0.5018,
    -0.018,
    0.4351,
    -0.6232,
    0.3664,
    -0.6122,
    0.3685,
    -0.4396,
    0.4189,
    -0.4723,
    0.0238,
    -0.0271,
    0.0208,
    -0.044,
    0.0488,
    -0.0375,
    0.7234,
    -0.1089,
    0.4188,
    -0.27,
    0.1438,
    -0.3792,
    0.4187,
    -0.395,
    0.1673,
    -0.0049,
    0.6977,
    -0.5052,
    0.3978,
    -0.2228,
    0.5913,
    -0.2946,
    0.1631,
    -0.6322,
    0.477,
    -0.4798,
    0.1568,
    -0.4164,
    0.4104,
    -0.0744,
    0.2918,
    -0.2655,
    0.1292,
    -0.1052,
    0.2251,
    -0.2325,
    0.3372,
    -0.3682,
    0.0705,
    -0.22,
    0.0095,
    -0.2029,
    0.331,
    -0.2467,
    0.7889,
    -0.1654,
    0.7671,
    -0.0504,
    0.0961,
    -0.3041,
    0.0877,
    -0.1692,
    0.3983,
    -0.1762,
    0.2135,
    -0.3781,
    0.3838,
    -0.4071,
    0.0758,
    -0.0548,
    0.3101,
    -0.3095,
    0.2373,
    -0.1493,
    0.2418,
    -0.2798,
    0.2693,
    -0.2418,
    0.0332,
    -0.0323,
    0.3643,
    -0.024,
    0.3486,
    -0.5499,
    0.2651,
    -0.3674,
    0.5961,
    -0.2323,
    0.5397,
    -0.2929,
    0.2452,
    -0.0291,
    0.0414,
    -0.0196,
    0.4266,
    -0.5688,
    0.1177,
    -0.3638,
    0.0502,
    -0.029,
    0.0438,
    -0.0132,
    0.0447,
    -0.029,
    0.0286,
    -0.0131,
    0.0479,
    -0.027,
    0.0942,
    -0.2542,
    0.3338,
    -0.3427,
    0.3743,
    -0.2211,
    0.5029,
    -0.4464,
    0.0413,
    -0.2255,
    0.504,
    -0.3467,
    0.118,
    -0.1132,
    0.4506,
    -0.3151,
    0.666,
    -0.6633,
    0.3002,
    -0.2147,
    0.5905,
    -0.4137,
    0.4645,
    -0.083,
    0.2335,
    -0.2953,
    0.3005,
    -0.3817,
    0.7017,
    -0.6476,
    0.2267,
    -0.5956,
    0.1041,
    -0.0897,
    0.2,
    -0.2861,
    0.5301,
    -0.334,
    0.503,
    -0.374,
    0.1437,
    -0.657,
    0.1429,
    -0.3656,
    0.1411,
    -0.2937,
    0.2536,
    -0.1383,
    0.1039,
    -0.287,
    0.1425,
    -0.2419,
    0.0201,
    -0.0238,
    0.0149,
    -0.0138,
    0.4897,
    -0.7566,
    0.3995,
    -0.1017,
    0.4513,
    -0.5846,
    0.1904,
    -0.6495,
    0.378,
    -0.4488,
    0.2633,
    -0.4252,
    0.4994,
    -0.6938,
    0.6526,
    -0.4597,
    0.3056,
    -0.5858,
    0.0135,
    -0.8421,
    0.8233,
    -0.7034,
    0.3414,
    -0.1469,
    0.2703,
    -0.1964,
    0.3884,
    -0.2265,
    0.0687,
    -0.0418,
    0.0368,
    -0.0275,
    0.1927,
    -0.2472,
    0.394,
    -0.4539,
    0.4553,
    -0.4531,
    0.4977,
    -0.6312,
    0.3179,
    -0.2094,
    0.1625,
    -0.0507,
    0.3172,
    -0.1715,
    0.4795,
    -0.4716,
    0.3748,
    -0.3963,
    0.1402,
    -0.1211,
    0.4314,
    -0.1804,
    0.1806,
    -0.2003,
    0.2544,
    -0.1075,
    0.4144,
    -0.3804,
    0.3335,
    -0.2098,
    0.0229,
    -0.0351,
    0.0298,
    -0.1462,
    0.0701,
    -0.151,
    0.2449,
    -0.3431,
    0.1395,
    -0.4953,
    0.3582,
    -0.2437,
    0.0782,
    -0.233,
    0.1703,
    -0.0577,
    0.3642,
    -0.2547,
    0.061,
    -0.4709,
    0.1931,
    -0.6406,
    0.2031,
    -0.2111,
    0.2925,
    -0.3805,
    0.2838,
    -0.6483,
    0.1988,
    -0.3051,
    0.3135,
    -0.3808,
    0.334,
    -0.3602,
    0.0824,
    -0.0953,
    0.1138,
    -0.0583,
    0.0867,
    -0.1819,
    0.1073,
    -0.1286,
    0.3125,
    -0.5524,
    0.324,
    -0.3164,
    0.2659,
    -0.1945,
    0.2452,
    -0.0586,
    0.0575,
    -0.0454,
    0.428,
    -0.2547,
    0.1249,
    -0.1532,
    0.4021,
    -0.0915,
    0.2004,
    -0.1525,
    0.1745,
    -0.174,
    0.1188,
    -0.3538,
    0.05,
    -0.0452,
    0.3692,
    -0.0186,
    0.5947,
    -0.7483,
    0.2387,
    -0.1178,
    0.3314,
    -0.6094,
    0.271,
    -0.0627,
    0.4622,
    -0.4702,
    0.3428,
    -0.1341,
    0.2213,
    -0.2234,
    0.5445,
    -0.5552,
    0.1827,
    -0.5014,
    0.3069,
    -0.3366,
    0.2214,
    -0.2922,
    0.2078,
    -0.4689,
    0.1986,
    -0.1368,
    0.2075,
    -0.1245,
    0.2437,
    -0.2446,
    0.1251,
    -0.2752,
    0.2167,
    -0.1108,
    0.2006,
    -0.1322,
    0.0797,
    -0.1374,
    0.2993,
    -0.3039,
    0.1852,
    -0.22,
    0.1098,
    -0.0857,
    0.0804,
    -0.1871,
    0.1646,
    -0.1852,
    0.1414,
    -0.2112,
    0.1778,
    -0.3866,
    0.2853,
    -0.2742,
    0.1294,
    -0.1486,
    0.1709,
    -0.1741,
    0.3179,
    -0.0478,
    0.2654,
    -0.1444,
    0.0447,
    -0.1187,
    0.0523,
    -0.0362,
    0.0453,
    -0.0565,
    0.0197,
    -0.6669,
    0.4698,
    -0.3915,
    0.4023,
    -0.4374,
    0.085,
    -0.6372,
    0.8359,
    -0.5075,
    0.0959,
    -0.4812,
    0.5988,
    -0.5242,
    0.4543,
    -0.7608,
    0.2134,
    -0.4543,
    0.2807,
    -0.1631,
    0.3865,
    -0.4129,
    0.1672,
    -0.418,
    0.5087,
    -0.8958,
    0.0141,
    -0.3852,
    0.0666,
    -0.6879,
    0.3292,
    -0.482,
    0.206,
    -0.3613,
    0.2913,
    -0.0723,
    0.1193,
    -0.0324,
    0.1193,
    -0.0986,
    0.0905,
    -0.0942,
    0.6278,
    -0.4293,
    0.3445,
    -0.2786,
    0.2888,
    -0.294,
    0.0367,
    -0.0545,
    0.0544,
    -0.0319,
    0.0795,
    -0.066,
    0.1477,
    -0.0608,
    0.3388,
    -0.6668,
    0.2677,
    -0.5218,
    0.5406,
    -0.3416,
    0.5293,
    -0.4485,
    0.218,
    -0.3614,
    0.2808,
    -0.7141,
    0.22,
    -0.1195,
    0.11,
    -0.2502,
    0.1756,
    -0.1204,
    0.1898,
    -0.572,
    0.4411,
    -0.2515,
    0.3577,
    -0.3051,
    0.3414,
    -0.4113,
    0.0483,
    -0.0373,
    0.1555,
    -0.0462,
    0.4783,
    -0.4333,
    0.5489,
    -0.5382,
    0.4526,
    -0.4806,
    0.1749,
    -0.5342,
    0.2052,
    -0.4268,
    0.2484,
    -0.5873,
    0.0855,
    -0.3423,
    0.2107,
    -0.5504,
    0.1775,
    -0.1241,
    0.2688,
    -0.1896,
    0.0583,
    -0.04,
    0.0482,
    -0.0444,
    0.06,
    -0.3212,
    0.2817,
    -0.4928,
    0.1132,
    -0.1371,
    0.2089,
    -0.243,
    0.2468,
    -0.0912,
    0.1233,
    -0.2206,
    0.0842,
    -0.1083,
    0.0699,
    -0.0645,
    0.0532,
    -0.0221,
    0.1096,
    -0.0218,
    0.0171,
    -0.0275,
    0.025,
    -0.0215,
    0.0229,
    -0.0428,
    0.066,
    -0.0787,
    0.0424,
    -0.0519,
    0.0402,
    -0.0283,
    0.0563,
    -0.0327,
    0.0539,
    -0.0288,
    0.0372,
    -0.0276,
    0.0217,
    -0.0234,
    0.0147,
    -0.0266,
    0.0146,
    -0.0206,
    0.0102,
    -0.0125,
    0.0099,
    -0.0145,
    0.0096,
    -0.0099,
    0.008,
    -0.0085,
    0.0067,
    -0.0084,
    0.0052,
    -0.008,
    0.0069,
    -0.0044,
    0.0043,
    -0.0028,
    0.0056,
    -0.0023,
    0.0028,
    -0.0025,
    0.003,
    -0.0042,
    0.0022,
    -0.0025,
    0.0026,
    -0.0012,
    0.0008,
    -0.0018,
    0.0014,
    -0.0015,
    0.0014,
    -0.0009,
    0.0013,
    -0.0013,
    0.0011,
    -0.0006,
    0.0006,
    -0.0004,
    0.0002,
    -0.0004,
    0.0002,
    -0.0002,
    0.0001,
    -0.0001,
    0.0001,
    -0.0001,
    0.0001,
    -0.0001,
    0.0001,
    0
];

export default chapterFourthPCM;