import Chapter from "../../components/Chapter";

import {
    ChapterListWrap
} from "./ChapterList.styles";

import appData from '../../data/appData'

const chapterListWithoutIntro = appData.filter(({ variant }) => variant !== 'intro');

const ChapterList = () => {
    return (
        <ChapterListWrap>
            {chapterListWithoutIntro.map(appData => {
                return <Chapter
                    key={appData.url}
                    {...appData}
                />
            })}
        </ChapterListWrap>
    );

};

export default ChapterList;