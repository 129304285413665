const chapterNinthPCM = [
    0.143,
    -0.1279,
    0.1041,
    -0.1274,
    0.1394,
    -0.1196,
    0.097,
    -0.0932,
    0.1032,
    -0.0963,
    0.145,
    -0.1237,
    0.1015,
    -0.0676,
    0.0968,
    -0.1119,
    0.0846,
    -0.0606,
    0.1312,
    -0.104,
    0.1084,
    -0.0965,
    0.0777,
    -0.0677,
    0.104,
    -0.0543,
    0.08,
    -0.0504,
    0.1035,
    -0.0748,
    0.4345,
    -0.0947,
    0.6323,
    -0.342,
    0.557,
    -0.2534,
    0.2846,
    -0.8081,
    0.4004,
    -0.7337,
    0.3899,
    -0.6061,
    0.2605,
    -0.3014,
    0.1717,
    -0.0423,
    0.3389,
    -0.4144,
    0.4795,
    -0.53,
    0.3347,
    -0.4467,
    0.47,
    -0.3951,
    0.1205,
    -0.1649,
    0.6179,
    -0.5376,
    0.7836,
    -0.6115,
    0.5687,
    -0.4333,
    0.6442,
    -0.4839,
    0.065,
    -0.0485,
    0.0851,
    -0.0785,
    0.0953,
    -0.0541,
    0.1249,
    -0.1786,
    0.8094,
    -0.2873,
    0.8097,
    -0.6382,
    0.8178,
    -0.431,
    0.2689,
    -0.4041,
    0.1657,
    -0.3848,
    0.1237,
    -0.1561,
    0.0623,
    -0.0529,
    0.0613,
    -0.0489,
    0.071,
    -0.0396,
    0.8152,
    -0.7077,
    0.7584,
    -0.0194,
    0.5516,
    -0.4956,
    0.638,
    -0.4399,
    0.2595,
    -0.138,
    0.0651,
    -0.2107,
    0.36,
    -0.3789,
    0.477,
    -0.2438,
    0.5763,
    -0.1136,
    0.0845,
    -0.1045,
    0.1613,
    -0.1045,
    0.083,
    -0.1199,
    0.0915,
    -0.178,
    0.2048,
    -0.1026,
    0.5264,
    -0.2248,
    0.2123,
    -0.1215,
    0.0648,
    -0.0644,
    0.0605,
    -0.1305,
    0.0609,
    -0.0661,
    0.0508,
    -0.0406,
    0.0718,
    -0.048,
    0.0427,
    -0.0288,
    0.0624,
    -0.0261,
    0.0434,
    -0.0383,
    0.1861,
    -0.3229,
    0.1661,
    -0.4234,
    0.8427,
    -0.3792,
    0.4992,
    -0.2517,
    0.5502,
    -0.2789,
    0.5876,
    -0.1736,
    0.337,
    -0.2008,
    0.4974,
    -0.2813,
    0.3389,
    -0.1508,
    0.0842,
    -0.1619,
    0.4003,
    -0.1344,
    0.4179,
    -0.2779,
    0.0978,
    -0.1592,
    0.4716,
    -0.6317,
    0.4584,
    -0.6977,
    0.3356,
    -0.6218,
    0.4369,
    -0.5008,
    0.5653,
    -0.3502,
    0.16,
    -0.0111,
    0.1113,
    -0.5127,
    0.2765,
    -0.1653,
    0.3802,
    -0.1834,
    0.4137,
    -0.1812,
    0.7525,
    -0.2609,
    0.8897,
    -0.2874,
    0.373,
    -0.2807,
    0.0468,
    -0.1486,
    0.827,
    -0.53,
    0.7332,
    -0.6499,
    0.5604,
    -0.5621,
    0.3162,
    -0.3849,
    0.0523,
    -0.0616,
    0.0614,
    -0.0705,
    0.048,
    -0.0641,
    0.381,
    -0.2742,
    0.4378,
    -0.32,
    0.7783,
    -0.3183,
    0.5069,
    -0.4644,
    0.4709,
    -0.3047,
    0.3239,
    -0.2917,
    0.1591,
    -0.3266,
    0.0991,
    -0.0885,
    0.0636,
    -0.1688,
    0.1619,
    -0.2554,
    0.4409,
    -0.7682,
    0.6157,
    -0.8229,
    0.378,
    -0.6363,
    0.4328,
    -0.4041,
    0.3457,
    -0.3386,
    0.4102,
    -0.1375,
    0.0666,
    -0.1242,
    0.0752,
    -0.0524,
    0.0588,
    -0.061,
    0.0612,
    -0.0602,
    0.0645,
    -0.0487,
    0.0472,
    -0.1244,
    0.0363,
    -0.0742,
    0.1443,
    -0.1233,
    0.4558,
    -0.5435,
    0.5231,
    -0.3308,
    0.1114,
    -0.0879,
    0.1426,
    -0.066,
    0.0541,
    -0.1294,
    0.1102,
    -0.0853,
    0.0537,
    -0.0447,
    0.0377,
    -0.0735,
    0.0344,
    -0.0325,
    0.8108,
    -0.4761,
    0.3961,
    -0.4399,
    0.6665,
    -0.7613,
    0.1786,
    -0.0809,
    0.8339,
    -0.1236,
    0.6245,
    -0.3907,
    0.1273,
    -0.1964,
    0.7176,
    -0.513,
    0.5944,
    -0.5212,
    0.0495,
    -0.0647,
    0.4026,
    -0.3915,
    0.4428,
    -0.183,
    0.0796,
    -0.2859,
    0.482,
    -0.6284,
    0.4563,
    -0.6302,
    0.5357,
    -0.6197,
    0.8615,
    -0.5077,
    0.1538,
    -0.319,
    0.5786,
    -0.1796,
    0.6624,
    -0.3275,
    0.2804,
    -0.3028,
    0.1478,
    -0.2074,
    0.4457,
    -0.445,
    0.4213,
    -0.6065,
    0.1003,
    -0.0774,
    0.0707,
    -0.072,
    0.0695,
    -0.4325,
    0.1802,
    -0.1576,
    0.5914,
    -0.2785,
    0.2347,
    -0.1138,
    0.3106,
    -0.6576,
    0.1726,
    -0.2961,
    0.6411,
    -0.6976,
    0.3616,
    -0.4566,
    0.209,
    -0.2287,
    0.763,
    -0.5114,
    0.8057,
    -0.731,
    0.3071,
    -0.2809,
    0.4191,
    -0.3233,
    0.6709,
    -0.4092,
    0.1862,
    -0.3991,
    0.2182,
    -0.2293,
    0.4633,
    -0.4813,
    0.1058,
    -0.0458,
    0.2076,
    -0.475,
    0.6365,
    -0.4088,
    0.1926,
    -0.2353,
    0.188,
    -0.1801,
    0.5743,
    -0.1379,
    0.2291,
    -0.2557,
    0.8379,
    -0.4686,
    0.6568,
    -0.4196,
    0.285,
    -0.3253,
    0.0793,
    -0.2816,
    0.1816,
    -0.1798,
    0.0818,
    -0.0602,
    0.0975,
    -0.0656,
    0.0576,
    -0.0619,
    0.0621,
    -0.0507,
    0.039,
    -0.0605,
    0.0849,
    -0.0724,
    0.0883,
    -0.0602,
    0.0826,
    -0.0592,
    0.0727,
    -0.5226,
    0.2461,
    -0.3342,
    0.0836,
    -0.1004,
    0.1323,
    -0.7179,
    0.5622,
    -0.6924,
    0.3848,
    -0.6402,
    0.2934,
    -0.0674,
    0.1986,
    -0.3393,
    0.562,
    -0.3029,
    0.4396,
    -0.286,
    0.1078,
    -0.5645,
    0.1009,
    -0.4874,
    0.1482,
    -0.1353,
    0.8645,
    -0.6329,
    0.8016,
    -0.004,
    0.7121,
    -0.3624,
    0.1927,
    -0.3474,
    0.3136,
    -0.3491,
    0.708,
    -0.5737,
    0.4993,
    -0.4452,
    0.5937,
    -0.3994,
    0.6077,
    -0.3523,
    0.444,
    -0.2914,
    0.0682,
    -0.0513,
    0.4144,
    -0.3081,
    0.6928,
    -0.3163,
    0.4162,
    -0.3065,
    0.5854,
    -0.3327,
    0.6905,
    -0.3972,
    0.1641,
    -0.2217,
    0.2929,
    -0.2953,
    0.1916,
    -0.2045,
    0.7954,
    -0.3702,
    0.3035,
    -0.3704,
    0.2536,
    -0.1606,
    0.5421,
    -0.1685,
    0.5388,
    -0.2351,
    0.8507,
    -0.4001,
    -0.1713,
    -0.4078,
    0.5042,
    -0.3132,
    0.1023,
    -0.1197,
    0.1432,
    -0.2103,
    0.1137,
    -0.0811,
    0.0633,
    -0.0678,
    0.06,
    -0.0715,
    0.0511,
    -0.0592,
    0.0661,
    -0.0804,
    0.0486,
    -0.1559,
    0.5038,
    -0.3435,
    0.3796,
    0.0273,
    0.3834,
    -0.4806,
    0.5121,
    -0.369,
    0.7224,
    -0.4745,
    0.5917,
    -0.4989,
    0.2782,
    -0.1802,
    0.9002,
    -0.3605,
    0.6479,
    -0.444,
    0.8135,
    -0.2869,
    0.2788,
    -0.3659,
    0.2686,
    -0.0648,
    0.4129,
    -0.2822,
    0.1521,
    -0.1736,
    0.4949,
    -0.4227,
    0.5672,
    -0.5184,
    0.8044,
    -0.3772,
    0.4213,
    -0.3102,
    0.5316,
    -0.591,
    0.3743,
    -0.3866,
    0.0927,
    -0.2099,
    0.5899,
    -0.2063,
    0.3207,
    -0.168,
    0.5399,
    -0.1766,
    0.1505,
    -0.3178,
    0.2776,
    -0.2843,
    0.6643,
    -0.3236,
    0.0746,
    -0.2493,
    0.7215,
    -0.5787,
    0.6606,
    -0.3774,
    0.6962,
    -0.6461,
    0.4553,
    -0.3235,
    0.1629,
    -0.1873,
    0.1899,
    -0.3562,
    0.4073,
    -0.1445,
    0.1468,
    -0.1719,
    0.1342,
    -0.1654,
    0.2685,
    -0.2984,
    0.5496,
    -0.3121,
    0.6486,
    -0.3873,
    0.3572,
    -0.1706,
    0.6148,
    -0.398,
    0.189,
    -0.0764,
    0.2223,
    -0.2368,
    -0.0977,
    -0.2991,
    0.2255,
    -0.2338,
    0.2112,
    -0.061,
    0.0964,
    -0.0596,
    0.0326,
    -0.0304,
    0.0539,
    -0.0635,
    0.0488,
    -0.0446,
    0.0529,
    -0.0571,
    0.0502,
    -0.0544,
    0.0768,
    -0.0513,
    0.3926,
    -0.5981,
    0.5175,
    -0.5763,
    0.3681,
    -0.0889,
    0.4525,
    -0.6696,
    0.4527,
    -0.4959,
    0.3436,
    -0.3958,
    0.3862,
    -0.5496,
    0.3249,
    -0.7002,
    0.601,
    -0.5953,
    0.5195,
    -0.1895,
    0.5261,
    -0.0514,
    0.1412,
    -0.425,
    0.2153,
    -0.3107,
    0.1978,
    -0.4661,
    0.3368,
    -0.5454,
    0.2351,
    -0.4918,
    0.7983,
    -0.4746,
    0.8266,
    -0.2778,
    0.1992,
    -0.2322,
    0.1121,
    -0.5765,
    0.5705,
    -0.3202,
    0.5557,
    -0.5672,
    0.3909,
    -0.6326,
    0.5628,
    -0.3244,
    0.4705,
    -0.7412,
    0.6252,
    -0.6463,
    0.4513,
    -0.2843,
    0.2892,
    -0.2811,
    0.1291,
    -0.2679,
    0.5044,
    -0.3348,
    0.3502,
    -0.3156,
    0.2211,
    -0.1926,
    0.0871,
    -0.0423,
    0.0856,
    -0.0468,
    0.0414,
    -0.1242,
    0.5027,
    -0.6119,
    0.422,
    -0.6825,
    0.3757,
    -0.5523,
    0.398,
    -0.5227,
    0.0252,
    -0.522,
    0.1261,
    -0.1784,
    0.7948,
    -0.6464,
    0.4824,
    -0.3812,
    0.1079,
    -0.2813,
    0.3543,
    -0.1558,
    0.3136,
    -0.2347,
    0.1459,
    -0.2589,
    0.0824,
    -0.5533,
    0.21,
    -0.427,
    0.1095,
    -0.1885,
    0.5181,
    -0.7825,
    0.3642,
    -0.3536,
    0.1382,
    -0.1825,
    0.1211,
    -0.2606,
    0.1931,
    -0.1147,
    0.0401,
    -0.0456,
    0.0467,
    -0.0497,
    0.0666,
    -0.0698,
    0.0467,
    -0.0601,
    0.0322,
    -0.052,
    0.4422,
    -0.5638,
    0.3687,
    -0.5479,
    0.5482,
    -0.4088,
    0.425,
    -0.3545,
    0.1452,
    -0.0784,
    0.2153,
    -0.0784,
    0.1472,
    -0.2069,
    0.6429,
    -0.4491,
    0.3842,
    -0.3298,
    0.453,
    -0.1835,
    0.1273,
    -0.3153,
    0.5777,
    -0.6238,
    0.3839,
    -0.1355,
    0.3763,
    -0.1698,
    0.2176,
    -0.199,
    0.4835,
    -0.1702,
    0.0716,
    -0.1789,
    0.391,
    -0.2065,
    0.4008,
    -0.2426,
    0.2882,
    -0.1628,
    0.1514,
    -0.165,
    0.2969,
    -0.1209,
    0.1974,
    -0.1364,
    0.1697,
    -0.146,
    0.1226,
    -0.0815,
    0.1124,
    -0.1236,
    0.0699,
    -0.0676,
    0.1358,
    -0.0669,
    0.2274,
    -0.3644,
    0.738,
    -0.4673,
    0.3968,
    -0.1697,
    0.5836,
    -0.8846,
    0.2355,
    -0.6422,
    0.4457,
    -0.4708,
    0.4697,
    -0.3274,
    0.6415,
    -0.6137,
    0.443,
    -0.3467,
    0.0781,
    -0.1282,
    0.5244,
    -0.5744,
    0.4572,
    -0.452,
    0.1986,
    -0.3304,
    0.077,
    -0.0605,
    0.5426,
    -0.2988,
    0.2285,
    -0.2904,
    0.5241,
    -0.1974,
    0.4374,
    -0.3097,
    0.5889,
    -0.4192,
    0.1529,
    -0.1917,
    0.2164,
    -0.2762,
    0.3539,
    -0.4558,
    0.4397,
    -0.1912,
    0.2785,
    -0.1954,
    0.1654,
    -0.124,
    0.1031,
    -0.0865,
    0.0578,
    -0.0712,
    0.0653,
    -0.0674,
    0.0457,
    -0.0691,
    0.0699,
    -0.0636,
    0.0639,
    -0.0636,
    0.1164,
    -0.1256,
    0.0503,
    -0.0523,
    0.5886,
    -0.482,
    0.6219,
    -0.6297,
    0.6282,
    -0.7213,
    0.6998,
    -0.781,
    0.6755,
    -0.7246,
    0.5623,
    -0.6471,
    0.5558,
    -0.6391,
    0.2349,
    -0.4682,
    0.3875,
    -0.477,
    0.368,
    -0.5752,
    0.4132,
    -0.6075,
    0.3632,
    0.0288,
    0.3408,
    -0.2028,
    0.3621,
    -0.2434,
    0.5779,
    -0.089,
    0.2565,
    -0.0571,
    0.2755,
    -0.4432,
    0.3177,
    -0.4828,
    0.1178,
    -0.2112,
    0.4449,
    -0.3948,
    0.5405,
    -0.5988,
    0.18,
    -0.1195,
    0.0885,
    -0.0304,
    0.4257,
    -0.2561,
    0.2878,
    -0.2383,
    0.3207,
    -0.1265,
    0.2285,
    -0.284,
    0.2666,
    -0.4135,
    0.317,
    -0.1235,
    0.1017,
    -0.0654,
    0.0687,
    -0.0387,
    0.2226,
    -0.0903,
    0.3496,
    -0.4485,
    0.0652,
    -0.1774,
    0.2831,
    -0.6385,
    0.2895,
    -0.1938,
    0.1496,
    -0.0374,
    0.1525,
    -0.2197,
    0.6485,
    -0.1291,
    0.2524,
    -0.6657,
    0.3846,
    -0.415,
    0.3268,
    -0.3564,
    0.1709,
    -0.2467,
    0.1347,
    -0.256,
    0.2388,
    -0.2425,
    0.2196,
    -0.2184,
    0.159,
    -0.1083,
    0.182,
    -0.1856,
    0.1048,
    -0.2048,
    0.1142,
    -0.1434,
    0.8454,
    -0.1461,
    0.6577,
    -0.1953,
    0.0891,
    -0.0963,
    0.4637,
    -0.4466,
    0.5299,
    -0.6643,
    0.4875,
    -0.5688,
    0.267,
    -0.3644,
    0.2332,
    -0.173,
    0.1108,
    -0.0899,
    0.094,
    -0.055,
    0.0893,
    -0.0466,
    0.1287,
    -0.088,
    0.4634,
    -0.2751,
    0.4468,
    -0.5099,
    0.5576,
    -0.4572,
    0.7169,
    -0.2053,
    0.0451,
    -0.8476,
    0.3782,
    -0.4929,
    0.1111,
    -0.3721,
    0.3486,
    -0.3183,
    0.517,
    -0.0739,
    0.3945,
    -0.4276,
    0.4902,
    -0.5576,
    0.1589,
    -0.5312,
    0.1117,
    -0.0742,
    0.4372,
    -0.2422,
    0.563,
    -0.7542,
    0.1628,
    -0.2279,
    0.0702,
    -0.5096,
    0.227,
    -0.4576,
    0.3493,
    -0.4933,
    0.4215,
    -0.2531,
    0.2779,
    -0.5673,
    0.461,
    -0.4402,
    0.3319,
    -0.3382,
    0.1406,
    -0.3388,
    0.085,
    -0.0642,
    0.1226,
    -0.0707,
    0.0568,
    -0.0481,
    0.0623,
    -0.0687,
    0.1094,
    -0.0823,
    0.0846,
    -0.071,
    0.016,
    -0.3266,
    0.1746,
    -0.1167,
    0.1688,
    -0.0737,
    0.434,
    -0.3884,
    0.3031,
    -0.3502,
    0.59,
    -0.5788,
    0.5789,
    -0.1581,
    0.1503,
    -0.2024,
    0.1258,
    -0.1733,
    0.0772,
    -0.0858,
    0.5778,
    -0.5246,
    0.4344,
    -0.4472,
    0.371,
    -0.4606,
    0.0708,
    -0.1322,
    0.52,
    -0.4045,
    0.5291,
    -0.5711,
    0.0772,
    -0.0655,
    0.1527,
    -0.5717,
    0.6438,
    -0.5028,
    0.1269,
    -0.152,
    0.7146,
    -0.5459,
    0.4182,
    -0.1387,
    0.1809,
    -0.1724,
    0.3438,
    -0.4152,
    0.6732,
    -0.2754,
    0.2756,
    -0.3125,
    0.2186,
    -0.1997,
    0.0616,
    -0.0848,
    0.0813,
    -0.0897,
    0.0812,
    -0.1049,
    0.1146,
    -0.0834,
    0.1074,
    -0.0825,
    0.0613,
    -0.0967,
    0.1156,
    -0.0945,
    0.6645,
    -0.0644,
    0.4688,
    -0.3074,
    0.8076,
    -0.5,
    0.2303,
    -0.5974,
    0.1885,
    -0.604,
    0.3991,
    -0.4872,
    0.1299,
    -0.0675,
    0.678,
    -0.3937,
    0.4338,
    -0.7098,
    0.2435,
    -0.1976,
    0.1384,
    -0.1415,
    0.0882,
    -0.0456,
    0.0671,
    -0.5722,
    0.3173,
    -0.579,
    0.2336,
    -0.1475,
    0.1868,
    -0.1102,
    0.2052,
    -0.1635,
    0.1286,
    -0.2815,
    0.4191,
    -0.6597,
    0.481,
    -0.7242,
    0.1165,
    -0.166,
    0.2969,
    -0.3818,
    0.3165,
    -0.3081,
    0.2188,
    -0.3869,
    0.0821,
    -0.1032,
    0.087,
    -0.0918,
    0.0766,
    -0.1075,
    0.049,
    -0.0907,
    0.189,
    -0.1888,
    0.063,
    -0.0473,
    0.0738,
    -0.1256,
    0.123,
    -0.1139,
    0.2371,
    0.0088,
    0.4873,
    -0.2801,
    0.0468,
    -0.3881,
    0.2939,
    -0.3103,
    0.2911,
    -0.167,
    0.8663,
    -0.8287,
    0.2046,
    -0.0837,
    0.1446,
    -0.1229,
    0.5436,
    -0.4246,
    0.215,
    -0.7281,
    0.4666,
    -0.3167,
    0.5237,
    -0.438,
    0.2368,
    -0.2285,
    0.3022,
    -0.3146,
    0.4418,
    -0.2769,
    0.0606,
    -0.0794,
    0.5516,
    -0.1174,
    0.5437,
    -0.523,
    0.6085,
    -0.8161,
    0.6309,
    -0.8254,
    0.4383,
    -0.5121,
    0.1804,
    -0.1828,
    0.0931,
    -0.1083,
    0.1822,
    -0.0695,
    0.053,
    -0.0398,
    0.0542,
    -0.0801,
    0.0487,
    -0.0761,
    0.1023,
    -0.0577,
    0.0434,
    -0.0466,
    0.114,
    -0.0688,
    0.1161,
    -0.0804,
    0.2414,
    -0.12,
    0.5673,
    -0.0891,
    0.229,
    -0.106,
    0.0577,
    -0.4188,
    0.4702,
    -0.348,
    0.1986,
    -0.6864,
    0.2536,
    -0.602,
    0.3823,
    -0.3436,
    0.1786,
    -0.2034,
    0.4894,
    -0.473,
    0.356,
    -0.3255,
    0.2238,
    -0.2913,
    0.4065,
    -0.506,
    0.2339,
    -0.3862,
    0.1442,
    -0.1498,
    0.0898,
    -0.0807,
    0.408,
    -0.6891,
    0.4017,
    -0.6881,
    0.4836,
    -0.2317,
    0.2551,
    -0.2473,
    0.2912,
    -0.2382,
    0.1133,
    -0.2379,
    0.0846,
    -0.0854,
    0.1592,
    -0.3689,
    0.6231,
    -0.1994,
    0.6508,
    -0.2682,
    0.6204,
    -0.0871,
    0.7689,
    -0.3098,
    0.5871,
    -0.6498,
    0.5079,
    -0.2059,
    0.0434,
    -0.1182,
    0.3473,
    -0.2215,
    0.4056,
    -0.5253,
    0.4165,
    -0.5858,
    0.4475,
    -0.6014,
    0.4636,
    -0.5123,
    0.1673,
    -0.1027,
    0.3898,
    -0.0959,
    0.0681,
    -0.131,
    0.072,
    -0.0583,
    0.1288,
    -0.2847,
    0.098,
    -0.291,
    0.408,
    -0.431,
    0.3317,
    -0.1235,
    0.1099,
    -0.139,
    0.5156,
    -0.183,
    0.2735,
    -0.3065,
    0.326,
    -0.5292,
    0.3106,
    -0.4558,
    0.2215,
    -0.2746,
    0.3893,
    -0.4599,
    0.5352,
    -0.4008,
    0.2114,
    -0.0624,
    0.0773,
    -0.0844,
    0.5708,
    -0.5918,
    0.4559,
    -0.5856,
    0.148,
    -0.2412,
    0.057,
    -0.0634,
    0.0669,
    -0.0539,
    0.1151,
    -0.1764,
    0.0755,
    -0.0753,
    0.0513,
    -0.0749,
    0.041,
    -0.06,
    0.0435,
    -0.0465,
    0.3606,
    -0.1316,
    0.3583,
    -0.1444,
    0.7503,
    -0.8862,
    0.3992,
    -0.6552,
    0.454,
    -0.1166,
    0.1814,
    -0.0801,
    0.4546,
    -0.132,
    0.3494,
    -0.7918,
    0.6942,
    -0.7227,
    0.2129,
    -0.5057,
    0.5186,
    -0.0918,
    0.5683,
    -0.7518,
    0.4692,
    -0.2622,
    0.3323,
    -0.6038,
    0.2343,
    -0.2421,
    0.2016,
    -0.1517,
    0.3748,
    -0.1402,
    0.4303,
    -0.2951,
    -0.2054,
    -0.7142,
    0.1254,
    -0.2932,
    0.0888,
    -0.1877,
    0.0809,
    -0.3234,
    0.1371,
    -0.0956,
    0.1715,
    -0.3983,
    0.1891,
    -0.0916,
    0.8321,
    -0.5636,
    0.2388,
    -0.5564,
    0.3159,
    -0.1426,
    0.2098,
    -0.2181,
    0.3458,
    -0.4196,
    0.1752,
    -0.3104,
    0.1212,
    -0.0913,
    0.2129,
    -0.0165,
    0.3704,
    -0.7858,
    0.491,
    -0.797,
    0.2455,
    -0.1808,
    0.3407,
    -0.3711,
    0.3521,
    -0.1099,
    0.3146,
    -0.4771,
    0.3495,
    -0.4936,
    0.3306,
    -0.6201,
    0.5959,
    -0.3372,
    0.591,
    -0.5058,
    0.5695,
    -0.2238,
    0.3054,
    -0.1279,
    0.3407,
    -0.5578,
    0.4987,
    -0.6162,
    0.0861,
    -0.0643,
    0.1045,
    -0.2469,
    0.6551,
    -0.2148,
    0.6596,
    -0.313,
    0.2328,
    -0.1856,
    0.4235,
    -0.3628,
    0.4144,
    -0.5963,
    0.4129,
    -0.4245,
    0.2362,
    -0.4237,
    0.2863,
    -0.3576,
    0.0663,
    -0.2608,
    0.0706,
    -0.126,
    0.4819,
    -0.6389,
    0.7818,
    -0.6067,
    0.6945,
    -0.6558,
    0.2296,
    -0.3537,
    0.4693,
    -0.2863,
    0.2484,
    -0.27,
    0.4758,
    -0.7429,
    0.3299,
    -0.4232,
    0.0797,
    -0.099,
    0.043,
    -0.0611,
    0.0624,
    -0.0831,
    0.0691,
    -0.0716,
    0.1204,
    -0.0686,
    0.154,
    -0.0871,
    0.0647,
    -0.0613,
    0.0643,
    -0.104,
    0.0858,
    -0.0949,
    0.082,
    -0.123,
    0.6773,
    -0.1234,
    0.7304,
    -0.4968,
    0.3804,
    -0.5624,
    0.4355,
    -0.8568,
    0.5631,
    -0.3644,
    0.3503,
    -0.3561,
    0.5075,
    -0.2451,
    0.1387,
    -0.1598,
    0.3392,
    -0.3942,
    0.1626,
    -0.0899,
    0.3617,
    -0.499,
    0.6701,
    -0.7754,
    0.5922,
    -0.6124,
    0.5647,
    -0.1315,
    0.3841,
    -0.4671,
    0.2634,
    0.0259,
    0.308,
    -0.121,
    0.5351,
    -0.2189,
    0.3895,
    -0.3939,
    0.3923,
    -0.2985,
    0.2137,
    -0.2269,
    0.2506,
    -0.2142,
    0.5281,
    -0.3224,
    0.4037,
    -0.301,
    0.3905,
    -0.3342,
    0.1047,
    -0.1152,
    0.1176,
    -0.1172,
    0.0653,
    -0.1038,
    0.0902,
    -0.0927,
    0.0712,
    -0.2717,
    0.1459,
    -0.2078,
    0.269,
    -0.2293,
    0.1723,
    -0.1839,
    0.2245,
    -0.1895,
    0.0859,
    -0.1552,
    0.1677,
    -0.1247,
    0.138,
    -0.1875,
    0.0475,
    -0.0726,
    0.0428,
    -0.0502,
    0.0825,
    -0.0647,
    0.1434,
    -0.1827,
    0.0928,
    -0.1175,
    0.09,
    -0.0716,
    0.0751,
    -0.0936,
    0.0793,
    -0.0916,
    0.7364,
    -0.8609,
    0.4805,
    -0.8662,
    0.2076,
    -0.1025,
    0.7255,
    -0.6355,
    0.5351,
    -0.5041,
    0.2385,
    -0.3615,
    0.702,
    -0.4306,
    0.7245,
    -0.1231,
    0.3281,
    -0.2074,
    0.1942,
    -0.0973,
    0.3578,
    -0.5284,
    0.3718,
    -0.2169,
    0.29,
    -0.3944,
    0.3397,
    -0.5972,
    0.3289,
    -0.4998,
    0.2665,
    -0.5898,
    0.1498,
    -0.1822,
    0.0968,
    -0.2529,
    0.2538,
    -0.1729,
    0.4536,
    -0.574,
    0.2778,
    -0.3852,
    0.4633,
    -0.4289,
    0.3976,
    -0.7083,
    0.4468,
    -0.6897,
    0.2005,
    -0.4523,
    0.2797,
    -0.3947,
    0.1444,
    -0.169,
    0.2675,
    -0.1733,
    0.4136,
    -0.4101,
    0.5146,
    -0.4276,
    0.6583,
    -0.5918,
    0.2133,
    -0.1848,
    0.1498,
    -0.1545,
    0.5211,
    -0.4237,
    0.5372,
    -0.4886,
    0.4748,
    -0.4754,
    0.2416,
    -0.2572,
    0.1573,
    -0.0886,
    0.4212,
    -0.2671,
    0.4532,
    -0.4401,
    0.2907,
    -0.4291,
    0.1613,
    -0.1241,
    0.2477,
    -0.3839,
    0.5373,
    -0.4295,
    0.0993,
    -0.4296,
    0.5239,
    -0.5731,
    0.5742,
    -0.5212,
    0.2874,
    -0.2683,
    0.4755,
    -0.4507,
    0.3977,
    -0.5454,
    0.4794,
    -0.7511,
    0.5921,
    -0.366,
    0.6903,
    -0.6001,
    0.5733,
    -0.5111,
    0.5525,
    -0.5425,
    0.6432,
    -0.5918,
    0.145,
    -0.0625,
    0.2565,
    -0.2514,
    0.5196,
    -0.7147,
    0.3865,
    -0.606,
    0.4744,
    -0.5814,
    0.4616,
    -0.504,
    0.1927,
    -0.2781,
    0.097,
    -0.0865,
    0.089,
    -0.0896,
    0.1279,
    -0.0954,
    0.0794,
    -0.055,
    0.0667,
    -0.1363,
    0.0785,
    -0.1129,
    0.0841,
    -0.0917,
    0.0634,
    -0.0758,
    0.082,
    -0.0834,
    0.0672,
    -0.1269,
    0.0596,
    -0.073,
    0.0869,
    -0.1138,
    0.794,
    -0.6433,
    0.3915,
    -0.3145,
    0.4702,
    -0.2473,
    0.4698,
    -0.835,
    0.3553,
    -0.5765,
    0.398,
    -0.5405,
    0.503,
    -0.7652,
    0.4756,
    -0.1366,
    0.389,
    -0.5383,
    0.1234,
    -0.0765,
    0.5079,
    -0.6603,
    0.5269,
    -0.6053,
    0.3671,
    -0.906,
    0.377,
    -0.057,
    0.1063,
    -0.0545,
    0.5011,
    -0.6351,
    0.4303,
    -0.3026,
    0.2336,
    -0.2831,
    0.5288,
    -0.538,
    0.346,
    -0.4912,
    0.2509,
    -0.4938,
    0.1331,
    -0.4264,
    0.2385,
    -0.4205,
    0.1131,
    -0.2288,
    0.1179,
    -0.2655,
    0.4526,
    -0.3322,
    0.2143,
    -0.1809,
    0.2551,
    -0.0934,
    0.368,
    -0.5537,
    0.0728,
    -0.149,
    0.6896,
    -0.3089,
    0.0354,
    -0.3697,
    0.1622,
    -0.1916,
    0.3546,
    -0.8181,
    0.2531,
    -0.772,
    0.3391,
    -0.2201,
    0.169,
    -0.074,
    0.4718,
    -0.4301,
    0.4213,
    -0.5088,
    0.4139,
    -0.4735,
    0.6431,
    -0.4969,
    0.4852,
    -0.6163,
    0.2236,
    -0.6865,
    0.1239,
    -0.1137,
    0.2427,
    -0.1972,
    -0.0014,
    -0.1679,
    0.0544,
    -0.081,
    0.0709,
    -0.0623,
    0.1272,
    -0.095,
    0.0354,
    -0.0582,
    0.1893,
    -0.1975,
    0.2302,
    -0.1349,
    0.4537,
    -0.8381,
    0.5323,
    -0.2444,
    0.617,
    -0.4472,
    0.116,
    -0.1783,
    0.5642,
    -0.2615,
    0.3416,
    -0.4367,
    0.4586,
    -0.4159,
    0.1091,
    -0.3577,
    0.3268,
    -0.3815,
    0.1026,
    -0.1859,
    0.0414,
    -0.0772,
    0.0543,
    -0.0381,
    0.0329,
    -0.0419,
    0.0647,
    -0.0341,
    0.0598,
    -0.0508,
    0.1403,
    -0.1669,
    0.2801,
    -0.0809,
    0.4331,
    -0.2684,
    0.0954,
    -0.3076,
    0.204,
    -0.1974,
    0.1169,
    -0.0691,
    0.0624,
    -0.0455,
    0.1608,
    -0.1964,
    0.0652,
    -0.0577,
    0.0547,
    -0.0337,
    0.0561,
    -0.0525,
    0.039,
    -0.1458,
    0.0896,
    -0.1567,
    0.055,
    -0.0549,
    0.0508,
    -0.0579,
    0.0405,
    -0.0369,
    0.0421,
    -0.0244,
    0.0682,
    -0.056,
    0.0371,
    -0.0393,
    0.0385,
    -0.0356,
    0.0501,
    -0.0366,
    0.1936,
    -0.1629,
    0.0437,
    -0.0751,
    0.0904,
    -0.1106,
    0.0875,
    -0.0818,
    0.0827,
    -0.0773,
    0.084,
    -0.0619,
    0.0818,
    -0.0765,
    0.0694,
    -0.0633,
    0.0571,
    -0.049,
    0.0409,
    -0.0532,
    0.0312,
    -0.0508,
    0.033,
    -0.0191,
    0.0468,
    -0.045,
    0.0399,
    -0.0409,
    0.0601,
    -0.0566,
    0.0594,
    -0.0377,
    0.0526,
    -0.0444,
    0.0644,
    -0.0296,
    0.0543,
    -0.0322,
    0.0545,
    -0.0303,
    0.0433,
    -0.0264,
    0.045,
    -0.0258,
    0.031,
    -0.0244,
    0.0256,
    -0.0362,
    0.0253,
    -0.0391,
    0.0307,
    -0.0341,
    0.0345,
    -0.0416,
    0.0339,
    -0.0441,
    0.0395,
    -0.0393,
    0.0426,
    -0.0425,
    0.0411,
    -0.043,
    0.0331,
    -0.0394,
    0.0342,
    -0.0347,
    0.0343,
    -0.0345,
    0.035,
    -0.0382,
    0.0366,
    -0.038,
    0.0375,
    -0.0339,
    0.0376,
    -0.0354,
    0.0334,
    -0.0365,
    0.012,
    -0.0056,
    0.0021,
    -0.0019,
    0.0021,
    -0.002,
    0.0014,
    -0.0014,
    0.0015,
    -0.0013,
    0.0013,
    -0.0012,
    0.0009,
    -0.0008,
    0.0009,
    -0.0007,
    0.0008,
    -0.0006,
    0.0006,
    -0.0005,
    0.0005,
    -0.0003,
    0.0003,
    -0.0003,
    0.0003,
    -0.0002,
    0.0003,
    -0.0002,
    0.0002,
    -0.0002,
    0.0001,
    -0.0002,
    0.0001,
    -0.0001,
    0.0001,
    -0.0001,
    0.0001,
    -0.0001,
    0.0001,
    0,
    0,
    -0.0001,
    0,
    -0.0001,
    0,
    0,
    0,
    0,
    0,
    0
];

export default chapterNinthPCM;