import { Link } from 'react-router-dom';

import {
    ChapterWrapper,
    CoverWrap,
    CaptionWrap,
    IconWrap
} from "./Chapter.styles";

const Chapter = ({ name, variant, img1, title1, title2, url, introText }) => {
    return (
        <ChapterWrapper variant={ variant }>
            <Link to={ url } >
                <CoverWrap variant={ variant }>
                    <img className='cover-img' src={img1} alt={ name }/>
                    <IconWrap variant={ variant }>
                        <img src='./images/icon-play.svg' alt='icon-play'/>
                    </IconWrap>
                    <CaptionWrap variant={ variant }>
                        <p>{ title1 }</p>
                        <span>{ title2 }</span>
                    </CaptionWrap>
                </CoverWrap>
                {variant === 'intro' && <p className='intro-txt'>
                    { introText }
                </p>}
            </Link>
        </ChapterWrapper>
    );

};

export default Chapter;