const chapterTenthPCM = [
    0.0011,
    -0.0017,
    0.0043,
    -0.0028,
    0.004,
    -0.0046,
    0.0346,
    -0.0259,
    0.6215,
    -0.3567,
    0.4066,
    -0.8098,
    0.548,
    -0.5477,
    0.0594,
    -0.2117,
    0.0446,
    -0.4307,
    0.6452,
    -0.491,
    0.4748,
    -0.3157,
    0.7972,
    -0.2186,
    0.3863,
    -0.4798,
    0.3895,
    -0.2087,
    0.1322,
    -0.6209,
    0.5607,
    -0.2901,
    0.5809,
    -0.1381,
    0.5439,
    -0.2682,
    0.7955,
    -0.063,
    0.6079,
    -0.135,
    0.4092,
    -0.1281,
    0.1845,
    -0.1619,
    0.0759,
    -0.06,
    0.0482,
    -0.0555,
    0.043,
    -0.0391,
    0.0569,
    -0.0606,
    0.1436,
    -0.289,
    0.6811,
    -0.7356,
    0.2832,
    -0.418,
    0.4559,
    -0.221,
    0.4239,
    -0.7109,
    0.5024,
    -0.6776,
    0.8087,
    -0.3887,
    0.3166,
    -0.3437,
    0.3395,
    -0.4298,
    0.2166,
    -0.4999,
    0.3158,
    -0.3166,
    0.2866,
    -0.2365,
    0.0741,
    -0.0754,
    0.1106,
    -0.0859,
    0.0751,
    -0.0668,
    0.0711,
    -0.0608,
    0.0518,
    -0.0548,
    0.0873,
    -0.0795,
    0.0917,
    -0.1035,
    0.5625,
    -0.4705,
    0.1389,
    -0.0898,
    0.1315,
    -0.0839,
    0.3165,
    -0.4727,
    0.5507,
    -0.4356,
    0.6571,
    -0.4411,
    0.2019,
    -0.459,
    0.2357,
    -0.3788,
    0.1061,
    -0.0894,
    0.3408,
    -0.647,
    0.6032,
    -0.228,
    0.2367,
    -0.5016,
    0.0727,
    -0.1549,
    0.1575,
    -0.512,
    0.2542,
    -0.1714,
    0.7912,
    -0.3575,
    0.1537,
    -0.1945,
    0.4473,
    -0.1309,
    0.2701,
    -0.477,
    0.8739,
    -0.2006,
    0.1879,
    -0.3128,
    0.1504,
    -0.1374,
    0.4858,
    -0.305,
    0.3211,
    -0.0841,
    0.6463,
    -0.1907,
    0.1528,
    -0.0874,
    0.3384,
    -0.5761,
    0.5003,
    -0.3263,
    0.646,
    -0.4625,
    0.6689,
    -0.4474,
    0.4365,
    -0.2199,
    0.4539,
    -0.3992,
    0.2341,
    -0.3147,
    0.1127,
    -0.0874,
    0.2003,
    -0.1978,
    0.1893,
    -0.1559,
    0.3065,
    -0.2247,
    0.3945,
    -0.3743,
    0.4074,
    -0.2195,
    0.1277,
    -0.1062,
    0.0526,
    -0.1269,
    0.1546,
    -0.1275,
    0.1159,
    -0.1147,
    0.0778,
    -0.1152,
    0.0661,
    -0.0953,
    0.1808,
    -0.1207,
    0.1067,
    -0.1169,
    0.1065,
    -0.085,
    0.1451,
    -0.1461,
    0.1054,
    -0.1507,
    0.0794,
    -0.072,
    0.1156,
    -0.0697,
    0.1342,
    -0.2731,
    0.8847,
    -0.8375,
    0.2818,
    -0.5676,
    0.0872,
    -0.2071,
    0.3624,
    -0.6196,
    0.2954,
    -0.334,
    0.4299,
    -0.6972,
    0.1177,
    -0.2271,
    0.4739,
    -0.4767,
    0.6809,
    -0.3993,
    0.1736,
    -0.2362,
    0.1426,
    -0.1944,
    0.4388,
    -0.2061,
    0.298,
    -0.2134,
    0.1132,
    -0.1706,
    0.1888,
    -0.1831,
    0.1971,
    -0.2021,
    0.3049,
    -0.339,
    0.3642,
    -0.1256,
    0.6907,
    -0.4519,
    0.8094,
    -0.1303,
    0.1689,
    -0.4814,
    0.3706,
    -0.7771,
    -0.1227,
    -0.4822,
    0.6755,
    -0.3999,
    0.6591,
    -0.4045,
    0.1549,
    -0.1073,
    0.0797,
    -0.1562,
    0.5791,
    -0.3117,
    0.2247,
    -0.2732,
    0.5711,
    -0.2341,
    0.3,
    -0.493,
    0.3153,
    -0.2831,
    0.4713,
    -0.4626,
    0.0995,
    -0.0566,
    0.171,
    -0.2154,
    0.3719,
    -0.3723,
    0.2556,
    -0.2639,
    0.2938,
    -0.1649,
    0.2309,
    -0.199,
    0.3865,
    -0.2804,
    0.1886,
    -0.2761,
    0.1966,
    -0.1447,
    0.0982,
    -0.1059,
    0.3749,
    -0.2569,
    0.4829,
    -0.2813,
    0.2077,
    -0.3095,
    0.4344,
    -0.4607,
    0.5445,
    -0.6304,
    0.5903,
    -0.7761,
    0.5451,
    -0.6964,
    0.4599,
    -0.7121,
    0.3809,
    -0.4481,
    0.156,
    -0.1398,
    0.2194,
    -0.3266,
    0.3761,
    -0.4423,
    0.3001,
    -0.1748,
    0.1454,
    -0.3193,
    0.1305,
    -0.2729,
    0.4031,
    -0.2457,
    0.1774,
    -0.4991,
    0.3261,
    -0.4672,
    0.2604,
    -0.0483,
    0.6258,
    -0.6932,
    0.3004,
    -0.6737,
    0.1336,
    -0.2711,
    0.1329,
    -0.0916,
    0.3484,
    -0.6588,
    0.4475,
    0.0475,
    0.8015,
    -0.3361,
    0.8678,
    -0.4496,
    0.4169,
    -0.3898,
    0.331,
    -0.5638,
    0.2462,
    -0.3417,
    0.051,
    -0.1381,
    0.0413,
    -0.0352,
    0.1593,
    -0.1734,
    0.3785,
    -0.356,
    0.3247,
    -0.3076,
    0.3957,
    -0.3825,
    0.29,
    -0.1421,
    0.2006,
    -0.2928,
    0.4281,
    -0.2958,
    0.7561,
    -0.2744,
    0.3476,
    -0.2897,
    0.1573,
    -0.2756,
    0.2537,
    -0.3833,
    0.2521,
    -0.3754,
    0.1562,
    -0.303,
    0.613,
    -0.3789,
    0.6609,
    -0.346,
    0.3161,
    -0.3435,
    0.1071,
    -0.2274,
    0.1411,
    -0.228,
    0.1118,
    -0.3508,
    0.3708,
    -0.4642,
    0.3864,
    -0.406,
    0.2437,
    -0.3072,
    0.3355,
    -0.2242,
    0.258,
    -0.2405,
    0.099,
    -0.1075,
    0.1636,
    -0.2032,
    0.2983,
    -0.2135,
    0.1766,
    -0.1543,
    0.1539,
    -0.11,
    0.1307,
    -0.0874,
    0.1951,
    -0.111,
    0.0906,
    -0.1077,
    0.1367,
    -0.101,
    0.126,
    -0.118,
    0.1038,
    -0.1801,
    0.1371,
    -0.2007,
    0.1066,
    -0.0847,
    0.1448,
    -0.1085,
    0.142,
    -0.1384,
    0.1346,
    -0.1012,
    0.2067,
    -0.1234,
    0.0973,
    -0.1218,
    0.136,
    -0.1224,
    0.1061,
    -0.106,
    0.0435,
    -0.0826,
    0.28,
    -0.3132,
    0.8209,
    -0.2944,
    0.1622,
    -0.2171,
    0.3177,
    -0.649,
    0.5116,
    -0.5486,
    0.2375,
    -0.5406,
    0.893,
    -0.2036,
    0.4301,
    -0.2685,
    0.7376,
    -0.4861,
    0.1092,
    -0.1729,
    0.116,
    -0.0359,
    0.4936,
    -0.2528,
    0.1004,
    -0.2884,
    0.623,
    -0.5154,
    0.8238,
    -0.1697,
    0.7395,
    -0.4523,
    0.8671,
    -0.3707,
    0.0377,
    -0.0291,
    0.2817,
    -0.4037,
    0.2983,
    -0.4268,
    0.7511,
    -0.1416,
    0.6228,
    -0.4729,
    0.6538,
    -0.5272,
    0.4327,
    -0.5157,
    0.2006,
    -0.1884,
    0.0955,
    -0.7159,
    0.2218,
    -0.4714,
    0.3741,
    -0.3363,
    0.6548,
    -0.3521,
    0.6967,
    -0.1406,
    0.176,
    -0.158,
    0.1967,
    -0.2422,
    0.0712,
    -0.3414,
    0.2252,
    -0.1111,
    0.8859,
    -0.1806,
    0.8848,
    -0.3631,
    0.1282,
    -0.1956,
    0.1233,
    -0.2354,
    0.1228,
    -0.0489,
    0.6716,
    -0.5127,
    0.8176,
    -0.6831,
    0.3942,
    -0.7094,
    0.3384,
    -0.5416,
    0.7487,
    -0.473,
    0.2322,
    -0.481,
    0.1556,
    -0.2369,
    0.4907,
    -0.6293,
    0.195,
    -0.358,
    0.1664,
    -0.195,
    0.5963,
    -0.4051,
    0.4829,
    -0.2682,
    0.7485,
    -0.4652,
    0.7686,
    -0.3499,
    0.4534,
    -0.3954,
    0.2983,
    -0.2499,
    0.467,
    -0.3063,
    0.7308,
    -0.1762,
    0.6676,
    -0.4698,
    0.5569,
    -0.4614,
    0.3857,
    -0.2372,
    0.6076,
    -0.6495,
    0.1268,
    -0.2056,
    0.6081,
    -0.3333,
    0.4862,
    -0.458,
    0.3423,
    -0.6419,
    0.3895,
    -0.1669,
    0.1205,
    -0.2643,
    0.5062,
    -0.2579,
    0.6671,
    -0.3711,
    0.468,
    -0.3763,
    0.1764,
    -0.1076,
    0.1796,
    -0.2796,
    0.7764,
    -0.282,
    0.1649,
    -0.2074,
    0.5333,
    -0.1884,
    0.2599,
    -0.1769,
    0.1183,
    -0.17,
    0.1729,
    -0.0984,
    0.097,
    -0.2763,
    0.1916,
    -0.1198,
    0.513,
    -0.6637,
    0.5687,
    -0.9083,
    0.6938,
    -0.6077,
    0.7727,
    -0.6155,
    0.3824,
    -0.4073,
    0.3075,
    -0.3262,
    0.401,
    -0.3127,
    0.34,
    -0.3624,
    0.6412,
    -0.3002,
    0.4747,
    -0.2683,
    0.0937,
    -0.1738,
    0.0724,
    -0.0961,
    0.0362,
    -0.0804,
    0.0613,
    -0.1186,
    0.1023,
    -0.0895,
    0.0816,
    -0.0773,
    0.1669,
    -0.1123,
    0.091,
    -0.116,
    0.09,
    -0.1,
    0.061,
    -0.0589,
    0.08,
    -0.0911,
    0.1039,
    -0.0962,
    0.0479,
    -0.0662,
    0.3583,
    -0.5321,
    0.3962,
    -0.5484,
    0.0962,
    -0.4795,
    0.328,
    -0.3591,
    0.2075,
    -0.201,
    0.2054,
    -0.095,
    0.6182,
    -0.3507,
    0.3471,
    -0.2864,
    0.4933,
    -0.2994,
    0.6459,
    -0.4671,
    0.3656,
    -0.5171,
    0.301,
    -0.3584,
    0.2032,
    -0.1908,
    0.175,
    -0.1679,
    0.0977,
    -0.1076,
    0.1232,
    -0.1218,
    0.6345,
    -0.4481,
    0.7448,
    -0.615,
    0.4325,
    -0.4255,
    0.4209,
    -0.3375,
    0.5724,
    -0.7942,
    0.369,
    -0.0824,
    0.1676,
    -0.2405,
    0.5768,
    -0.6462,
    0.6863,
    -0.2888,
    0.32,
    -0.435,
    0.2862,
    -0.1275,
    0.8028,
    -0.4948,
    0.678,
    -0.5683,
    0.524,
    -0.5793,
    0.6721,
    -0.5058,
    0.5973,
    -0.3086,
    0.3447,
    -0.1177,
    0.0654,
    -0.1466,
    0.0208,
    -0.1056,
    0.0801,
    -0.0801,
    0.1811,
    -0.0718,
    0.5179,
    -0.5884,
    0.7056,
    -0.5885,
    0.083,
    -0.1297,
    0.78,
    -0.6714,
    0.6135,
    -0.3619,
    0.6983,
    -0.4951,
    0.4753,
    -0.7411,
    0.3206,
    -0.2018,
    0.2309,
    -0.6829,
    0.7265,
    -0.596,
    0.3193,
    -0.1868,
    0.196,
    0.033,
    0.5595,
    -0.8824,
    0.3621,
    -0.6148,
    0.0699,
    -0.1707,
    0.0872,
    -0.2068,
    0.1276,
    -0.0932,
    0.0758,
    -0.2961,
    0.4585,
    -0.2889,
    0.4411,
    -0.3278,
    0.1333,
    -0.1701,
    0.2366,
    -0.4189,
    0.4563,
    -0.2862,
    0.0754,
    -0.4499,
    0.7203,
    -0.4717,
    0.3663,
    -0.1674,
    0.2258,
    -0.1741,
    0.1618,
    -0.0944,
    0.2684,
    -0.3942,
    0.1669,
    -0.4307,
    0.5522,
    -0.2845,
    0.1836,
    -0.1617,
    0.1356,
    -0.5605,
    0.5455,
    -0.8061,
    0.92,
    -0.4085,
    0.6769,
    -0.6378,
    0.3482,
    -0.5184,
    0.5536,
    -0.8267,
    0.4039,
    -0.6027,
    0.4117,
    -0.6,
    0.2802,
    -0.2449,
    0.1407,
    -0.2165,
    0.1175,
    -0.1318,
    0.4024,
    -0.4951,
    0.2168,
    -0.0107,
    0.2986,
    -0.1393,
    0.1512,
    -0.0843,
    0.0865,
    -0.0871,
    0.277,
    -0.6408,
    0.1143,
    -0.5948,
    0.7381,
    -0.5508,
    0.1189,
    -0.117,
    0.3519,
    -0.1212,
    0.3463,
    -0.485,
    0.1932,
    -0.0935,
    0.044,
    -0.3065,
    0.4015,
    -0.1212,
    0.1067,
    -0.0989,
    0.148,
    -0.1569,
    0.0779,
    -0.0782,
    0.1169,
    -0.0727,
    0.0657,
    -0.0649,
    0.0961,
    -0.1047,
    0.145,
    -0.0685,
    0.1157,
    -0.147,
    0.0678,
    -0.0797,
    0.0486,
    -0.094,
    0.0847,
    -0.0944,
    0.0773,
    -0.0772,
    0.0702,
    -0.0507,
    0.0656,
    -0.0929,
    0.1002,
    -0.2558,
    0.6115,
    -0.417,
    0.5828,
    -0.3206,
    0.3221,
    -0.6095,
    0.3166,
    -0.3642,
    0.3931,
    -0.3313,
    0.1609,
    -0.458,
    0.1383,
    -0.2677,
    0.357,
    -0.55,
    0.6921,
    -0.4158,
    0.3794,
    -0.4206,
    0.6545,
    -0.274,
    0.4931,
    -0.3462,
    0.487,
    -0.4204,
    0.2479,
    -0.3064,
    0.67,
    -0.4068,
    0.198,
    -0.2574,
    0.6428,
    0.017,
    0.5339,
    -0.5676,
    0.415,
    -0.7367,
    0.4234,
    -0.4734,
    0.2002,
    -0.2214,
    0.1872,
    -0.1967,
    0.4238,
    -0.0798,
    0.4348,
    -0.5783,
    0.3874,
    -0.2793,
    0.246,
    -0.1466,
    0.348,
    -0.4093,
    0.3576,
    -0.4217,
    0.51,
    -0.5576,
    0.2957,
    -0.4336,
    0.3082,
    -0.2077,
    0.1073,
    -0.1687,
    0.0342,
    -0.3838,
    0.2341,
    -0.3385,
    0.453,
    -0.1045,
    0.1417,
    -0.1752,
    0.2875,
    -0.2512,
    0.5558,
    -0.6136,
    0.3931,
    -0.3232,
    0.3221,
    -0.2954,
    0.2107,
    -0.2356,
    0.3204,
    -0.3358,
    0.7336,
    -0.6353,
    0.4534,
    -0.6865,
    0.5779,
    -0.4712,
    0.4831,
    -0.4162,
    0.2459,
    -0.4746,
    0.5805,
    -0.3857,
    0.474,
    -0.4835,
    0.2193,
    -0.4541,
    0.1747,
    -0.3716,
    0.1509,
    -0.3829,
    0.1573,
    -0.2432,
    0.3742,
    -0.2088,
    0.6222,
    -0.6138,
    0.5855,
    -0.4438,
    0.2424,
    -0.4415,
    0.1657,
    -0.1254,
    0.2324,
    -0.2054,
    0.3747,
    -0.2218,
    0.1402,
    -0.1606,
    0.6052,
    -0.4562,
    0.6542,
    -0.3367,
    0.2444,
    -0.1732,
    0.3115,
    -0.3474,
    0.4038,
    -0.2899,
    0.341,
    -0.2901,
    0.4337,
    -0.4481,
    0.333,
    -0.3594,
    0.1795,
    -0.1192,
    0.071,
    -0.039,
    0.0335,
    -0.0504,
    0.0229,
    -0.0212,
    0.0307,
    -0.02,
    0.0933,
    -0.0152,
    0.1447,
    -0.2237,
    0.5169,
    -0.3231,
    0.3713,
    -0.2191,
    0.3663,
    -0.2468,
    0.4379,
    -0.3195,
    0.2695,
    -0.3388,
    0.4065,
    -0.344,
    0.08,
    -0.121,
    0.0356,
    -0.0359,
    0.3063,
    -0.4601,
    0.5146,
    -0.3309,
    0.6026,
    -0.5142,
    0.5805,
    -0.8463,
    0.4579,
    -0.5427,
    0.3642,
    -0.5349,
    0.1662,
    -0.2708,
    0.5893,
    -0.155,
    0.1183,
    -0.1618,
    0.3915,
    -0.3305,
    0.4292,
    -0.5984,
    0.1343,
    -0.1168,
    0.0756,
    -0.0642,
    0.0802,
    -0.339,
    0.8585,
    -0.6379,
    0.4448,
    -0.7034,
    0.3736,
    -0.472,
    0.52,
    -0.4398,
    0.4558,
    -0.2679,
    0.2098,
    -0.4212,
    0.1977,
    -0.386,
    0.2144,
    -0.133,
    0.2321,
    -0.5562,
    0.8837,
    -0.3797,
    0.449,
    -0.7047,
    0.6505,
    -0.6782,
    0.6445,
    -0.4369,
    0.1523,
    -0.1273,
    0.829,
    -0.2991,
    0.7457,
    -0.1812,
    0.123,
    -0.3528,
    0.1484,
    -0.2247,
    0.7373,
    -0.227,
    0.6221,
    -0.4593,
    0.3681,
    -0.3105,
    0.1169,
    -0.2245,
    0.433,
    -0.5277,
    0.2049,
    -0.3325,
    0.6686,
    -0.3504,
    0.3148,
    -0.3459,
    0.6685,
    -0.1737,
    0.622,
    -0.2502,
    0.1755,
    -0.077,
    0.0709,
    -0.0655,
    0.1701,
    -0.2026,
    0.3388,
    -0.619,
    0.5809,
    -0.7496,
    0.6572,
    -0.7403,
    0.2412,
    -0.5572,
    0.0908,
    -0.3059,
    0.2006,
    -0.1625,
    0.1535,
    -0.1694,
    0.1212,
    -0.1249,
    0.1062,
    -0.2487,
    0.0618,
    -0.1022,
    0.1507,
    -0.1783,
    0.1188,
    -0.1294,
    0.1011,
    -0.0938,
    0.1919,
    -0.0898,
    0.1183,
    -0.117,
    0.091,
    -0.1467,
    0.0849,
    -0.095,
    0.0958,
    -0.1289,
    0.0288,
    -0.0251,
    0.0244,
    -0.0284,
    0.0922,
    -0.1955,
    0.1535,
    -0.1236,
    0.1142,
    -0.1799,
    0.177,
    -0.0933,
    0.0777,
    -0.0707,
    0.1101,
    -0.0879,
    0.3917,
    -0.1658,
    0.4194,
    -0.4837,
    0.325,
    -0.5291,
    0.3113,
    -0.2402,
    0.477,
    -0.4436,
    0.7824,
    -0.6206,
    0.4689,
    -0.3053,
    0.4087,
    -0.3572,
    0.3811,
    -0.3275,
    0.5668,
    -0.2482,
    0.2136,
    -0.2272,
    0.8445,
    -0.7699,
    0.6339,
    -0.5971,
    0.3266,
    -0.5723,
    0.1762,
    -0.5579,
    0.1873,
    -0.0173,
    0.1801,
    -0.1884,
    0.0752,
    -0.1466,
    0.5734,
    -0.5136,
    0.0424,
    -0.1859,
    0.4017,
    -0.1877,
    0.0496,
    -0.4168,
    0.3104,
    -0.4319,
    0.4974,
    -0.5527,
    0.1589,
    -0.2718,
    0.5335,
    -0.3941,
    0.4773,
    -0.3646,
    0.652,
    -0.5698,
    0.3425,
    -0.2099,
    0.5924,
    -0.6004,
    0.613,
    -0.6335,
    0.4858,
    -0.4682,
    0.6409,
    -0.4053,
    0.4389,
    -0.4774,
    0.2781,
    -0.4133,
    0.3113,
    -0.2588,
    0.3557,
    -0.3401,
    0.2547,
    -0.3373,
    0.1852,
    -0.1124,
    0.0698,
    -0.087,
    0.12,
    -0.0954,
    0.1184,
    -0.0942,
    0.1054,
    -0.1498,
    0.1229,
    -0.1832,
    0.1109,
    -0.1018,
    0.1061,
    -0.1095,
    0.1192,
    -0.1934,
    0.673,
    -0.1115,
    0.5964,
    -0.3821,
    0.1286,
    -0.0904,
    0.3655,
    -0.3104,
    0.712,
    -0.5925,
    0.1776,
    -0.6993,
    0.6882,
    -0.5179,
    0.4528,
    -0.5868,
    0.3435,
    -0.4334,
    0.1107,
    -0.1427,
    0.4343,
    -0.1759,
    0.1243,
    -0.11,
    0.3293,
    -0.3787,
    0.2775,
    -0.4399,
    0.4937,
    -0.4178,
    0.2365,
    -0.2971,
    0.2245,
    -0.2993,
    0.4919,
    -0.2657,
    0.3829,
    -0.5057,
    0.1272,
    -0.216,
    0.1819,
    -0.4897,
    0.4851,
    -0.4278,
    0.6173,
    -0.2659,
    0.5633,
    -0.4365,
    0.5195,
    -0.3865,
    0.1783,
    -0.3426,
    0.2411,
    -0.1966,
    0.1019,
    -0.2398,
    0.8368,
    -0.3819,
    0.3963,
    -0.7347,
    0.4085,
    -0.6054,
    0.2382,
    -0.1231,
    0.3126,
    -0.2944,
    0.5178,
    -0.7597,
    0.5431,
    -0.4192,
    0.5298,
    -0.2798,
    0.1355,
    -0.1027,
    0.144,
    -0.2087,
    0.4192,
    -0.3596,
    0.927,
    -0.44,
    0.8872,
    -0.422,
    0.4182,
    -0.4591,
    0.2726,
    -0.2839,
    0.1308,
    -0.1948,
    0.6339,
    -0.3406,
    0.0782,
    -0.2911,
    0.139,
    -0.1046,
    0.4429,
    -0.3457,
    0.339,
    -0.3554,
    0.435,
    -0.4135,
    0.5263,
    -0.5352,
    0.6083,
    -0.6091,
    0.7746,
    -0.9132,
    0.8943,
    -0.5778,
    0.3209,
    -0.3369,
    0.2219,
    -0.3292,
    0.2596,
    -0.7528,
    0.5814,
    -0.7125,
    0.1153,
    -0.3539,
    0.0598,
    -0.0796,
    0.2677,
    -0.3881,
    0.2781,
    -0.4947,
    0.5489,
    -0.4535,
    0.4749,
    -0.4265,
    0.8071,
    -0.3325,
    0.271,
    -0.2399,
    0.0739,
    -0.1112,
    0.7455,
    -0.3448,
    -0.0885,
    -0.3227,
    -0.0069,
    -0.3638,
    0.3202,
    -0.2747,
    0.3643,
    -0.294,
    0.2766,
    -0.3476,
    0.0705,
    -0.2095,
    0.0727,
    -0.0739,
    0.1994,
    -0.1445,
    0.1848,
    -0.1971,
    0.0957,
    -0.1348,
    0.1352,
    -0.1289,
    0.0991,
    -0.1619,
    0.1377,
    -0.1171,
    0.0964,
    -0.1592,
    0.1344,
    -0.1054,
    0.0997,
    -0.0936,
    0.0731,
    -0.0947,
    0.0745,
    -0.1261,
    0.1395,
    -0.1247,
    0.1687,
    -0.1012,
    0.1324,
    -0.1036,
    0.1271,
    -0.1689,
    0.165,
    -0.4793,
    0.1249,
    -0.7048,
    0.4684,
    -0.2769,
    0.5647,
    -0.5447,
    0.3738,
    -0.2661,
    0.1866,
    -0.2033,
    0.1614,
    -0.4065,
    0.5847,
    -0.365,
    0.4233,
    -0.2365,
    0.5107,
    -0.4123,
    0.7688,
    -0.3147,
    0.4679,
    -0.2419,
    0.258,
    -0.1373,
    0.3607,
    -0.4433,
    0.5628,
    -0.5166,
    0.5901,
    -0.4364,
    0.7143,
    -0.4412,
    0.1943,
    -0.4271,
    0.2501,
    -0.1784,
    0.4313,
    -0.1593,
    0.0715,
    -0.0687,
    0.2984,
    -0.1118,
    0.826,
    -0.6252,
    0.1943,
    -0.1702,
    0.234,
    -0.2419,
    0.1906,
    -0.2537,
    0.4321,
    -0.6712,
    0.3363,
    -0.4189,
    0.2801,
    -0.2346,
    0.1523,
    -0.3045,
    0.1689,
    -0.5305,
    0.8316,
    -0.3717,
    0.0895,
    -0.1457,
    0.2972,
    -0.2988,
    0.1943,
    -0.3211,
    0.2103,
    -0.1613,
    0.3366,
    -0.2539,
    0.8088,
    -0.3808,
    0.1719,
    -0.0908,
    0.1046,
    -0.1387,
    0.5095,
    -0.3006,
    0.5552,
    -0.1656,
    0.0832,
    -0.24,
    0.4268,
    -0.3933,
    0.301,
    -0.2461,
    0.2558,
    -0.2201,
    0.569,
    -0.3885,
    0.0918,
    -0.2711,
    0.1861,
    -0.2033,
    0.0923,
    -0.153,
    0.2918,
    -0.2083,
    0.262,
    -0.3588,
    0.1557,
    -0.3427,
    0.2509,
    -0.3614,
    0.1559,
    -0.074,
    0.0936,
    -0.095,
    0.064,
    -0.0915,
    0.1252,
    -0.1233,
    0.1537,
    -0.1654,
    0.0719,
    -0.0687,
    0.0762,
    -0.0646,
    0.1755,
    -0.1199,
    0.1357,
    -0.1281,
    0.7516,
    -0.426,
    0.3509,
    -0.2341,
    0.2502,
    -0.3549,
    0.141,
    -0.1679,
    0.1276,
    -0.1483,
    0.7951,
    -0.659,
    0.5031,
    -0.5217,
    0.4265,
    -0.6843,
    0.7717,
    -0.7651,
    0.8026,
    -0.2693,
    0.3765,
    -0.3483,
    0.4142,
    -0.6067,
    0.3675,
    -0.3424,
    0.4755,
    -0.5172,
    0.1903,
    -0.0706,
    0.4588,
    -0.1108,
    0.1696,
    -0.2734,
    0.595,
    -0.3527,
    0.0857,
    -0.1887,
    0.0956,
    -0.384,
    0.4418,
    -0.3222,
    0.231,
    -0.1702,
    0.279,
    -0.2751,
    0.388,
    -0.2249,
    0.1527,
    -0.123,
    0.8159,
    -0.126,
    0.8063,
    -0.3487,
    0.5474,
    -0.6812,
    0.3158,
    -0.2682,
    0.045,
    -0.4728,
    0.1753,
    -0.2635,
    0.5094,
    -0.2529,
    0.7713,
    -0.3177,
    0.0755,
    -0.415,
    0.1115,
    -0.1825,
    0.0752,
    -0.2956,
    0.632,
    -0.3392,
    0.3826,
    -0.3234,
    0.8351,
    -0.5644,
    0.7391,
    -0.4985,
    0.6158,
    -0.3299,
    0.1746,
    -0.3105,
    0.241,
    -0.4869,
    0.5514,
    -0.7971,
    0.3076,
    -0.2768,
    0.1168,
    -0.2945,
    0.4873,
    -0.5378,
    0.397,
    -0.4147,
    0.3666,
    -0.5861,
    0.6068,
    -0.4231,
    0.6177,
    -0.5032,
    0.3197,
    -0.4504,
    0.2733,
    -0.5154,
    0.1141,
    -0.1519,
    0.0509,
    -0.0455,
    0.1046,
    -0.1077,
    0.6084,
    -0.1392,
    0.5809,
    -0.4718,
    0.0204,
    -0.7168,
    0.5217,
    -0.6564,
    0.3623,
    -0.321,
    0.6933,
    -0.8625,
    0.4896,
    -0.4101,
    0.1155,
    -0.1352,
    0.1574,
    -0.6288,
    0.2602,
    -0.4229,
    0.9127,
    -0.6597,
    0.6995,
    -0.3062,
    0.5339,
    -0.5241,
    0.2879,
    -0.6932,
    0.7399,
    -0.5956,
    0.5188,
    -0.3872,
    0.693,
    -0.4901,
    0.4464,
    -0.5824,
    0.6181,
    -0.2713,
    0.0547,
    -0.2715,
    0.3956,
    -0.0829,
    0.509,
    -0.5642,
    0.178,
    -0.3523,
    0.6733,
    -0.3655,
    0.5702,
    -0.6495,
    0.1256,
    -0.1041,
    0.1245,
    -0.0722,
    0.0895,
    -0.0698,
    0.079,
    -0.1383,
    0.1178,
    -0.1381,
    0.1286,
    -0.1458,
    0.1262,
    -0.1447,
    0.1219,
    -0.1714,
    0.107,
    -0.1187,
    0.1513,
    -0.1086,
    0.1421,
    -0.1274,
    0.1841,
    -0.1575,
    0.1741,
    -0.1692,
    0.1332,
    -0.1283,
    0.7469,
    -0.7809,
    0.1955,
    -0.6037,
    0.1048,
    -0.1271,
    0.1996,
    -0.463,
    0.4933,
    -0.2902,
    0.4706,
    -0.3326,
    0.7717,
    -0.7796,
    0.5482,
    -0.7149,
    0.2417,
    -0.8283,
    0.2366,
    -0.2212,
    0.424,
    -0.3709,
    0.6227,
    -0.6203,
    0.8359,
    -0.8237,
    0.3477,
    -0.4088,
    0.3667,
    -0.5246,
    0.2103,
    -0.3809,
    0.4711,
    -0.517,
    0.2853,
    -0.4394,
    0.366,
    -0.2839,
    0.3752,
    -0.4294,
    0.2777,
    -0.516,
    0.5217,
    -0.5378,
    0.1284,
    -0.1986,
    0.1119,
    -0.0787,
    0.1242,
    -0.1718,
    0.0705,
    -0.1297,
    0.0797,
    -0.1173,
    0.0681,
    -0.0477,
    0.1572,
    -0.1776,
    0.1049,
    -0.0942,
    0.0736,
    -0.0827,
    0.0997,
    -0.1498,
    0.1278,
    -0.1662,
    0.1431,
    -0.0911,
    0.0895,
    -0.124,
    0.1048,
    -0.2241,
    0.1601,
    -0.2645,
    0.1675,
    -0.1705,
    0.1413,
    -0.1388,
    0.172,
    -0.2198,
    0.1518,
    -0.1243,
    0.0841,
    -0.1791,
    0.1544,
    -0.1289,
    0.1222,
    -0.2692,
    0.1558,
    -0.1464,
    0.152,
    -0.1857,
    0.2473,
    -0.1092,
    0.1786,
    -0.1231,
    0.1733,
    -0.1499,
    0.2344,
    -0.1232,
    0.2662,
    -0.259,
    0.244,
    -0.235,
    0.2198,
    -0.3198,
    0.1832,
    -0.2676,
    0.1032,
    -0.1085,
    0.2008,
    -0.1528,
    0.2738,
    -0.2081,
    0.3637,
    -0.284,
    0.1238,
    -0.2865,
    0.3291,
    -0.2375,
    0.3358,
    -0.2802,
    0.3549,
    -0.1164,
    0.1474,
    -0.1806,
    0.0978,
    -0.255,
    0.2153,
    -0.1904,
    0.2693,
    -0.2009,
    0.2375,
    -0.236,
    0.1586,
    -0.2386,
    0.124,
    -0.2167,
    0.2159,
    -0.2126,
    0.3307,
    -0.2044,
    0.6561,
    -0.1483,
    0.3472,
    -0.2605,
    0.1816,
    -0.2892,
    0.1501,
    -0.1445,
    0.0951,
    -0.1697,
    0.1967,
    -0.1962,
    0.1391,
    -0.1741,
    0.1479,
    -0.2006,
    0.1566,
    -0.2141,
    0.1462,
    -0.2228,
    0.1241,
    -0.1809,
    0.1736,
    -0.1589,
    0.2103,
    -0.1673,
    0.2081,
    -0.1429,
    0.2004,
    -0.1645,
    0.198,
    -0.242,
    0.1093,
    -0.1729,
    0.1917,
    -0.2597,
    0.2515,
    -0.1656,
    0.2183,
    -0.1704,
    0.187,
    -0.1821,
    0.2518,
    -0.2602,
    0.2199,
    -0.2628,
    0.2199,
    -0.2638,
    0.1876,
    -0.1895,
    0.1861,
    -0.1643,
    0.2888,
    -0.1553,
    0.2048,
    -0.1523,
    0.1572,
    -0.1655,
    0.1153,
    -0.1505,
    0.2846,
    -0.2155,
    0.3409,
    -0.2248,
    0.3232,
    -0.254,
    0.1938,
    -0.2655,
    0.2028,
    -0.1861,
    0.2866,
    -0.1559,
    0.2625,
    -0.2005,
    0.1824,
    -0.2168,
    0.1035,
    -0.2102,
    0.2004,
    -0.2056,
    0.1957,
    -0.1923,
    0.2337,
    -0.1613,
    0.2044,
    -0.1234,
    0.1717,
    -0.1685,
    0.167,
    -0.168,
    0.1811,
    -0.1796,
    0.1465,
    -0.1806,
    0.134,
    -0.154,
    0.1385,
    -0.1313,
    0.1271,
    -0.1636,
    0.2194,
    -0.1919,
    0.278,
    -0.2079,
    0.2026,
    -0.1662,
    0.1832,
    -0.1879,
    0.1839,
    -0.2316,
    0.2512,
    -0.2122,
    0.2344,
    -0.1532,
    0.229,
    -0.1526,
    0.1532,
    -0.1275,
    0.1436,
    -0.0995,
    0.1177,
    -0.1211,
    0.1203,
    -0.117,
    0.0748,
    -0.1222,
    0.0679,
    -0.0653,
    0.0296,
    -0.0552,
    0.0426,
    -0.0371,
    0.0397,
    -0.0311,
    0.0323,
    -0.0321,
    0.0258,
    -0.0296,
    0.02,
    -0.0202,
    0.0063,
    -0.0071,
    0.0026,
    -0.0023,
    0.0005,
    -0.0007
];

export default chapterTenthPCM;