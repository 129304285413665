import styled, { css } from 'styled-components';

export const ChapterWrapper = styled.div(({ theme: { colors } }) => {
    return css`
      color: ${colors.base};
      display: flex;
      flex-direction: column;
      align-items: ${(props) => props.variant === 'preview' ? 'center' : 'flex-start'};
      margin: 0 auto;
      padding: ${(props) => props.variant === 'preview' ? '7px 5px' : '70px 15px 15px'};
      max-width: ${(props) => props.variant === 'preview' ? '50%' : '375px'};
      highlight-color: rgba(239,225,194, .2);
      -webkit-tap-highlight-color: rgba(239,225,194, .2);
      &:last-of-type{
        margin-right: auto;
        margin-left: 0;
      }
      p.intro-txt {
        color: ${colors.base};
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        max-width: 320px;
        letter-spacing: 0.37px;
      }
  `;
});

export const CoverWrap = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.variant === 'preview' ? '0px' : '25px'};
  .cover-img{
    width: ${(props) => props.variant === 'preview' ? '168px' : '100%'};
    height: ${(props) => props.variant === 'preview' ? '168px' : '100%'};
    object-fit: cover;
  }
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: ${(props) => props.variant === 'preview' ? '135px' : '173px'};
    bottom: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #000000 120%);
  }
`;

export const CaptionWrap = styled.div(({ theme: { colors } }) => {
    return css`
      color: ${colors.white};
      position: absolute;
      bottom: ${(props) => props.variant === 'preview' ? '4px' : '20px'};
      left: ${(props) => props.variant === 'preview' ? '5px' : '16px'};
      line-height: 22px;
      p {
        font-size: ${(props) => props.variant === 'preview' ? '14px' : '22px'};
        line-height: ${(props) => props.variant === 'preview' ? '6px' : '22px'};
        font-weight: 300;
      }
      span {
        font-size: ${(props) => props.variant === 'preview' ? '13px' : '26px'};
        line-height: ${(props) => props.variant === 'preview' ? '22px' : '26px'};
        font-weight: 700;
        letter-spacing: ${(props) => props.variant === 'preview' ? '-0.5px' : 'normal'};
      }
  `;
});

export const IconWrap = styled.div(({ theme: { colors } }) => {
    return css`
      border: 3px solid ${colors.white};
      border-radius: ${(props) => props.variant === 'preview' ? '19px' : '30px'};
      background-color: rgba(255, 255, 255, .5);
      position: absolute;
      width: ${(props) => props.variant === 'preview' ? '49px' : '71px'};
      height: ${(props) => props.variant === 'preview' ? '49px' : '71px'};
      top: ${(props) => props.variant === 'preview' ? '35%' : '37%'};
      left: 50%;
      transform: translate(-50%);
      img {
        position: absolute;
        top: ${(props) => props.variant === 'preview' ? '32%' : '35%'};
        left: ${(props) => props.variant === 'preview' ? '52%' : '50%'};
        transform: translate(-50%);
        width: ${(props) => props.variant === 'preview' ? '15px' : '20px'};
      }
  `;
});