const chapterEighthPCM = [
    0,
    0,
    0.0305,
    0,
    0.0466,
    -0.0727,
    0.074,
    -0.072,
    0.0868,
    -0.1077,
    0.0869,
    -0.0773,
    0.0646,
    -0.0764,
    0.0799,
    -0.058,
    0.4302,
    -0.3838,
    0.7478,
    -0.4891,
    0.4467,
    -0.6052,
    0.3593,
    -0.3056,
    0.8016,
    -0.3353,
    0.7524,
    -0.4105,
    0.1606,
    -0.5068,
    0.4473,
    -0.4779,
    0.0711,
    -0.6652,
    0.3918,
    -0.6365,
    0.3984,
    -0.3566,
    0.2645,
    -0.2459,
    0.8698,
    -0.3375,
    0.2769,
    -0.3639,
    0.314,
    -0.1951,
    0.0133,
    -0.2732,
    0.0995,
    -0.3366,
    0.5203,
    -0.33,
    0.6974,
    -0.481,
    0.7351,
    -0.4839,
    0.342,
    -0.7504,
    0.2515,
    -0.3641,
    0.4962,
    -0.3927,
    0.7356,
    -0.5498,
    0.5962,
    -0.3475,
    0.3889,
    -0.1144,
    0.3756,
    -0.509,
    0.2703,
    -0.497,
    0.3371,
    -0.3425,
    0.4187,
    -0.2137,
    0.2501,
    -0.1868,
    0.0753,
    -0.041,
    0.0322,
    -0.0489,
    0.0378,
    -0.3072,
    0.3631,
    -0.8765,
    0.6596,
    -0.2435,
    0.5031,
    -0.214,
    0.3905,
    -0.3518,
    0.1037,
    -0.1139,
    0.0491,
    -0.0743,
    0.0398,
    -0.0475,
    0.096,
    -0.539,
    0.179,
    -0.1958,
    0.2399,
    -0.2152,
    0.2765,
    -0.0921,
    0.6073,
    -0.3865,
    0.6568,
    -0.2186,
    0.6622,
    -0.1362,
    0.4872,
    -0.2461,
    0.2172,
    -0.4084,
    0.2917,
    -0.3625,
    0.2196,
    -0.1133,
    0.3223,
    -0.1055,
    0.4457,
    -0.187,
    0.3259,
    -0.3288,
    0.5005,
    -0.6115,
    0.2518,
    -0.1764,
    0.132,
    -0.1231,
    0.2253,
    -0.2235,
    0.3881,
    -0.3169,
    -0.0187,
    -0.1748,
    0.3309,
    -0.3485,
    0.5873,
    -0.3147,
    0.3211,
    -0.3654,
    0.7364,
    -0.497,
    0.267,
    -0.7494,
    0.6184,
    -0.2085,
    0.2769,
    -0.2694,
    0.2632,
    -0.4386,
    0.7768,
    -0.2145,
    0.8362,
    -0.4314,
    0.7732,
    -0.3955,
    0.3213,
    -0.1261,
    0.1587,
    -0.1343,
    0.5295,
    -0.3342,
    0.2194,
    -0.3584,
    0.2987,
    -0.1458,
    0.0587,
    -0.1586,
    0.0589,
    -0.0489,
    0.0479,
    -0.0708,
    0.0966,
    -0.0915,
    0.4129,
    -0.4501,
    0.3875,
    -0.1368,
    0.3465,
    -0.1591,
    0.2241,
    -0.3278,
    0.1354,
    -0.0628,
    0.0749,
    -0.0961,
    0.5894,
    -0.0433,
    0.4907,
    -0.8962,
    0.3765,
    -0.3584,
    0.6291,
    -0.3202,
    0.0572,
    -0.0784,
    0.1721,
    -0.061,
    0.1099,
    -0.1374,
    0.815,
    -0.3315,
    0.2463,
    -0.2064,
    0.6036,
    -0.2368,
    0.79,
    -0.3712,
    0.6493,
    -0.306,
    0.8945,
    -0.5449,
    0.8439,
    -0.2003,
    0.4889,
    -0.4739,
    0.861,
    -0.6026,
    0.5516,
    -0.6001,
    0.6237,
    -0.2016,
    0.4457,
    -0.0423,
    0.3269,
    -0.2083,
    0.6268,
    -0.1862,
    0.4576,
    -0.3361,
    0.1612,
    -0.183,
    0.3524,
    -0.2979,
    0.6846,
    -0.2341,
    0.7619,
    -0.4948,
    0.7383,
    -0.4251,
    0.5566,
    -0.2242,
    0.0205,
    -0.0665,
    0.0377,
    -0.0477,
    0.0331,
    -0.0341,
    0.0279,
    -0.0157,
    0.0757,
    -0.0437,
    0.0594,
    -0.2943,
    0.3709,
    -0.2968,
    0.5105,
    -0.5111,
    0.4734,
    -0.5115,
    0.3748,
    -0.4505,
    0.087,
    -0.4874,
    0.5453,
    -0.6556,
    0.2272,
    -0.1081,
    0.3396,
    -0.1546,
    0.3281,
    -0.2824,
    0.3074,
    -0.3479,
    0.1594,
    -0.2055,
    0.4202,
    -0.1866,
    0.0787,
    -0.1973,
    0.6493,
    -0.3714,
    0.2533,
    -0.335,
    0.5025,
    -0.224,
    0.263,
    -0.0916,
    0.0883,
    -0.0749,
    0.0552,
    -0.0553,
    0.042,
    -0.032,
    0.0299,
    -0.0852,
    0.6571,
    -0.2667,
    0.3898,
    -0.5744,
    0.3008,
    -0.2323,
    0.6399,
    -0.35,
    0.2114,
    -0.0941,
    0.0317,
    -0.5032,
    0.6274,
    -0.5112,
    0.5371,
    -0.0253,
    0.4024,
    -0.5026,
    0.4419,
    -0.4118,
    0.5534,
    -0.4334,
    0.6385,
    -0.3766,
    0.7627,
    -0.1223,
    0.8403,
    -0.4216,
    0.2878,
    -0.6086,
    0.6796,
    -0.4115,
    0.4046,
    -0.4166,
    0.5195,
    -0.4058,
    0.3439,
    -0.412,
    0.6567,
    -0.3285,
    0.4974,
    -0.3946,
    0.5034,
    -0.4046,
    0.7939,
    -0.4345,
    0.0786,
    -0.3438,
    0.179,
    -0.0866,
    0.0896,
    -0.1324,
    0.0358,
    -0.0449,
    0.1006,
    -0.1198,
    0.4748,
    -0.26,
    0.6959,
    -0.307,
    0.6881,
    -0.5667,
    0.4495,
    -0.4756,
    0.1308,
    -0.1785,
    0.1605,
    -0.1308,
    0.4082,
    -0.1999,
    0.3084,
    -0.243,
    0.7494,
    -0.4115,
    0.4797,
    -0.5016,
    0.5764,
    -0.199,
    0.4345,
    -0.1194,
    0.5853,
    -0.1967,
    0.2753,
    -0.2171,
    0.0945,
    -0.0123,
    0.0622,
    -0.0512,
    0.0448,
    -0.0785,
    0.452,
    -0.3027,
    0.8043,
    -0.2401,
    0.5625,
    -0.1897,
    0.3009,
    -0.1428,
    0.4356,
    -0.4956,
    0.1781,
    -0.5004,
    0.5436,
    -0.2836,
    0.7751,
    -0.4423,
    0.6067,
    -0.2763,
    0.1388,
    -0.1373,
    0.3658,
    -0.4081,
    0.3716,
    -0.4398,
    0.1003,
    -0.232,
    0.4028,
    -0.2212,
    0.4228,
    -0.3935,
    0.4259,
    -0.4395,
    0.3012,
    -0.1828,
    0.1242,
    -0.143,
    0.1477,
    -0.1938,
    0.0902,
    -0.1328,
    0.4284,
    -0.3612,
    0.27,
    -0.1363,
    0.094,
    -0.1277,
    0.0781,
    -0.1216,
    0.1521,
    -0.3795,
    0.795,
    -0.483,
    0.816,
    -0.4998,
    0.3768,
    -0.3093,
    0.0456,
    -0.0628,
    0.0618,
    -0.1818,
    0.8576,
    -0.4051,
    0.7103,
    -0.4883,
    0.667,
    -0.3354,
    0.1836,
    -0.5491,
    0.12,
    -0.1268,
    0.1925,
    -0.1636,
    0.3378,
    -0.1304,
    0.1233,
    -0.1345,
    0.1075,
    -0.017,
    0.0668,
    -0.0911,
    0.0797,
    -0.0914,
    0.0851,
    -0.0411,
    0.0862,
    -0.0692,
    0.4534,
    -0.4745,
    0.4292,
    -0.5026,
    0.7495,
    -0.5034,
    0.6354,
    -0.3164,
    0.6348,
    -0.2316,
    0.5149,
    -0.3497,
    0.4066,
    -0.4088,
    0.642,
    -0.4132,
    0.6192,
    -0.3722,
    0.0884,
    -0.1998,
    0.5418,
    -0.2755,
    0.1229,
    -0.0138,
    0.6406,
    -0.2435,
    0.4447,
    -0.3897,
    0.1011,
    -0.1592,
    0.3429,
    -0.2806,
    0.5055,
    -0.4039,
    0.2879,
    -0.1945,
    0.0734,
    -0.1306,
    0.1017,
    -0.1294,
    0.7791,
    -0.3011,
    0.203,
    -0.5968,
    0.1643,
    -0.4478,
    0.5024,
    -0.281,
    0.5401,
    -0.5054,
    0.1402,
    -0.1061,
    0.5082,
    -0.6767,
    0.5453,
    -0.4893,
    0.4605,
    -0.5492,
    0.5155,
    -0.204,
    0.1035,
    -0.157,
    0.1873,
    -0.1192,
    0.7935,
    -0.3832,
    0.2906,
    -0.4753,
    0.5206,
    -0.2369,
    0.1314,
    -0.091,
    0.1359,
    -0.1098,
    0.0359,
    -0.1028,
    0.0624,
    -0.0695,
    0.0936,
    -0.0945,
    0.0858,
    -0.1781,
    0.6954,
    -0.697,
    0.6035,
    -0.2808,
    0.4301,
    -0.442,
    0.857,
    -0.3589,
    0.5748,
    -0.4108,
    0.1432,
    -0.2779,
    0.2605,
    -0.3246,
    0.6141,
    -0.294,
    0.1148,
    -0.1433,
    0.1214,
    -0.2169,
    0.1741,
    -0.2443,
    0.2127,
    -0.5739,
    0.4922,
    -0.1755,
    0.0724,
    -0.0515,
    0.077,
    -0.0481,
    0.1292,
    -0.126,
    0.0517,
    -0.1652,
    0.2463,
    -0.5302,
    0.4248,
    -0.5208,
    0.0909,
    -0.2,
    0.4437,
    -0.3816,
    0.5315,
    -0.5584,
    0.1612,
    -0.5025,
    0.2454,
    -0.3977,
    0.047,
    -0.2932,
    0.8098,
    -0.472,
    0.825,
    -0.2219,
    0.7926,
    -0.3362,
    0.7892,
    -0.4915,
    0.0519,
    -0.1426,
    0.1459,
    -0.2314,
    0.3873,
    -0.2121,
    0.3107,
    -0.2179,
    0.1463,
    -0.0766,
    0.0795,
    -0.0807,
    0.027,
    -0.0782,
    0.0623,
    -0.0948,
    0.0777,
    -0.1031,
    0.0768,
    -0.0172,
    0.0531,
    -0.096,
    0.5791,
    -0.2774,
    0.7227,
    -0.3505,
    0.308,
    -0.4341,
    0.3985,
    -0.4541,
    0.5338,
    -0.4017,
    0.52,
    -0.5209,
    0.776,
    -0.5129,
    0.7557,
    -0.4378,
    0.3685,
    -0.4323,
    0.8352,
    -0.447,
    0.1752,
    -0.074,
    0.2201,
    -0.4665,
    0.7681,
    -0.3472,
    0.7196,
    -0.2496,
    0.644,
    -0.4088,
    0.2315,
    -0.1145,
    0.1673,
    -0.1937,
    0.2959,
    -0.3319,
    0.3044,
    -0.1709,
    0.1796,
    -0.1726,
    0.1957,
    -0.2311,
    0.1882,
    -0.2266,
    0.0978,
    -0.1749,
    0.0492,
    -0.0559,
    0.3266,
    -0.4122,
    0.7882,
    -0.398,
    0.7617,
    -0.4118,
    0.4437,
    -0.3873,
    0.1788,
    -0.3552,
    0.4843,
    -0.2845,
    0.284,
    -0.5234,
    0.416,
    -0.5668,
    0.5944,
    -0.3088,
    0.2494,
    -0.2808,
    0.1216,
    -0.2821,
    0.795,
    -0.4605,
    0.1869,
    -0.3955,
    0.7315,
    -0.3439,
    0.3049,
    -0.3096,
    0.1854,
    -0.2601,
    0.5809,
    -0.049,
    0.5746,
    -0.3925,
    0.0405,
    -0.5602,
    0.8404,
    -0.5512,
    0.691,
    -0.3655,
    0.1782,
    -0.2878,
    0.2057,
    -0.3542,
    0.5619,
    -0.1799,
    0.2459,
    -0.1949,
    0.1839,
    -0.1306,
    0.0569,
    -0.0751,
    0.0935,
    -0.0771,
    0.032,
    -0.0325,
    0.0311,
    -0.0342,
    0.0358,
    -0.0304,
    0.0358,
    -0.0334,
    0.049,
    -0.0501,
    0.2195,
    -0.4822,
    0.8232,
    -0.3681,
    0.6358,
    -0.6465,
    0.5098,
    -0.3903,
    0.3656,
    -0.6211,
    0.1052,
    -0.4842,
    0.378,
    -0.349,
    0.6535,
    -0.3641,
    0.3326,
    -0.2073,
    0.4269,
    -0.3008,
    0.2719,
    -0.3441,
    0.0676,
    -0.2511,
    0.0944,
    -0.0571,
    0.089,
    -0.069,
    0.4975,
    -0.1609,
    0.529,
    -0.7668,
    0.4708,
    -0.2201,
    0.4059,
    -0.5279,
    0.3707,
    -0.1282,
    0.3145,
    -0.3813,
    0.4657,
    -0.4145,
    0.1859,
    -0.1918,
    0.0887,
    -0.3474,
    0.2869,
    -0.4679,
    0.7696,
    -0.579,
    0.6777,
    -0.2622,
    0.6751,
    -0.1857,
    0.1559,
    -0.056,
    0.0672,
    -0.1635,
    0.7204,
    -0.3382,
    0.1371,
    -0.2134,
    0.3086,
    -0.0889,
    0.1045,
    -0.0552,
    0.0705,
    -0.0868,
    0.0403,
    -0.0652,
    0.0495,
    -0.0408,
    0.0647,
    -0.0585,
    0.0355,
    -0.0496,
    0.0472,
    -0.0503,
    0.0374,
    -0.0468,
    0.6655,
    -0.8571,
    0.1708,
    -0.153,
    0.4774,
    -0.5718,
    0.5921,
    -0.7325,
    0.4833,
    -0.1116,
    0.4271,
    -0.3604,
    0.3775,
    -0.3538,
    0.322,
    -0.2333,
    0.2567,
    -0.5742,
    0.1463,
    -0.3178,
    0.1428,
    -0.0312,
    0.2866,
    -0.0467,
    0.2955,
    -0.4068,
    0.1459,
    -0.1763,
    0.6396,
    -0.7467,
    0.4574,
    -0.5075,
    0.5946,
    -0.3198,
    0.2447,
    -0.2576,
    0.4209,
    -0.4299,
    0.6655,
    -0.5866,
    0.2723,
    -0.3901,
    0.1877,
    -0.0142,
    0.179,
    -0.1184,
    0.0992,
    -0.1245,
    0.0913,
    -0.1655,
    0.2402,
    -0.1728,
    0.3015,
    -0.0615,
    0.5993,
    -0.6546,
    0.6543,
    -0.4383,
    0.0789,
    -0.25,
    0.211,
    -0.6736,
    0.4384,
    -0.4771,
    0.3552,
    -0.2723,
    0.2901,
    -0.5415,
    0.0961,
    -0.0705,
    0.466,
    -0.8409,
    0.5578,
    -0.6367,
    0.5471,
    -0.3093,
    0.0359,
    -0.0322,
    0.0465,
    -0.0644,
    0.0644,
    -0.0465,
    0.0252,
    -0.0228,
    0.2135,
    -0.3535,
    0.0951,
    -0.0479,
    0.0923,
    -0.2066,
    0.6243,
    -0.294,
    0.6509,
    -0.3652,
    0.6071,
    -0.3901,
    0.5302,
    -0.3008,
    0.0857,
    -0.044,
    0.7761,
    -0.6958,
    0.1993,
    -0.2232,
    0.1715,
    -0.3875,
    0.3375,
    -0.3028,
    0.046,
    -0.0584,
    0.0779,
    -0.0635,
    0.0516,
    -0.1101,
    0.2429,
    -0.0511,
    0.6113,
    -0.406,
    0.4727,
    -0.3122,
    0.0457,
    -0.0923,
    0.0474,
    -0.0414,
    0.3533,
    -0.0394,
    0.7605,
    -0.8549,
    0.4429,
    -0.3453,
    0.306,
    -0.2591,
    0.3334,
    -0.262,
    0.1806,
    -0.0958,
    0.041,
    -0.0389,
    0.0408,
    -0.0317,
    0.0871,
    -0.0618,
    0.058,
    -0.0307,
    0.0346,
    -0.0642,
    0.0629,
    -0.0376,
    0.0638,
    -0.0378,
    0.04,
    -0.0362,
    0.3837,
    -0.6453,
    0.515,
    -0.6253,
    0.5334,
    -0.3031,
    0.7393,
    -0.4724,
    0.2947,
    -0.3201,
    0.6113,
    -0.184,
    0.0591,
    -0.027,
    0.0192,
    -0.0201,
    0.3285,
    -0.7375,
    0.4346,
    -0.6959,
    0.1904,
    -0.2536,
    0.3949,
    -0.4572,
    0.4817,
    -0.4562,
    0.4754,
    -0.4986,
    0.2876,
    -0.2926,
    -0.0931,
    -0.2035,
    0.0825,
    -0.1449,
    0.0441,
    -0.0803,
    0.058,
    -0.0868,
    0.1041,
    -0.0637,
    0.0565,
    -0.0695,
    0.0933,
    -0.0717,
    0.0956,
    -0.0878,
    0.5638,
    -0.3449,
    0.1134,
    -0.3125,
    0.6504,
    -0.6756,
    0.2184,
    -0.533,
    0.505,
    -0.4022,
    0.4725,
    -0.7214,
    0.1593,
    -0.2912,
    0.1274,
    -0.2983,
    0.1863,
    -0.2693,
    0.2107,
    -0.1326,
    0.3325,
    -0.3899,
    0.3384,
    -0.7783,
    0.1845,
    -0.5222,
    0.4984,
    -0.4637,
    0.4671,
    -0.6984,
    0.3902,
    -0.5978,
    0.278,
    -0.2729,
    0.0772,
    -0.1829,
    0.2912,
    -0.2413,
    0.3197,
    -0.7279,
    0.4699,
    -0.3257,
    0.5217,
    -0.4774,
    0.3195,
    -0.5547,
    0.3277,
    -0.5631,
    0.1231,
    -0.2684,
    0.1083,
    -0.1214,
    0.068,
    -0.1706,
    0.3043,
    -0.2698,
    0.6692,
    -0.1438,
    0.481,
    -0.6132,
    0.3655,
    -0.1423,
    0.7455,
    -0.5291,
    0.2758,
    -0.1459,
    0.3433,
    -0.2136,
    0.1958,
    -0.0565,
    0.038,
    -0.0437,
    0.0586,
    -0.0394,
    0.0497,
    -0.0488,
    0.0389,
    -0.0503,
    0.2948,
    -0.4285,
    0.1205,
    -0.1021,
    0.3416,
    -0.2924,
    0.3691,
    -0.5766,
    0.2142,
    -0.4951,
    0.0578,
    -0.0124,
    0.7094,
    -0.6443,
    0.1661,
    -0.0899,
    0.2677,
    -0.5139,
    0.4502,
    -0.4826,
    0.0338,
    -0.1842,
    0.0458,
    -0.0685,
    0.056,
    -0.0657,
    0.0703,
    -0.0854,
    0.0544,
    -0.0279,
    0.045,
    -0.0505,
    0.0546,
    -0.0727,
    0.0431,
    -0.053,
    0.0586,
    -0.0347,
    0.048,
    -0.058,
    0.4945,
    -0.4912,
    0.4519,
    -0.3403,
    0.119,
    -0.0905,
    0.4155,
    -0.6769,
    0.1587,
    -0.3477,
    0.1447,
    -0.2729,
    0.2984,
    -0.2839,
    0.3327,
    -0.1158,
    0.2348,
    -0.2184,
    0.138,
    -0.2325,
    0.6732,
    -0.6547,
    0.6359,
    -0.6915,
    0.1358,
    -0.3438,
    0.5069,
    -0.6757,
    0.4902,
    -0.4026,
    0.5413,
    -0.6967,
    0.4818,
    -0.6531,
    0.457,
    -0.3881,
    0.1144,
    -0.0531,
    0.0476,
    -0.1297,
    0.3641,
    -0.2863,
    0.1554,
    -0.1986,
    0.4666,
    -0.481,
    0.5179,
    -0.4169,
    0.197,
    -0.2019,
    0.0733,
    -0.101,
    0.5598,
    -0.5057,
    0.266,
    -0.2256,
    0.0422,
    -0.0901,
    0.3288,
    -0.5965,
    0.4488,
    -0.6145,
    0.4296,
    -0.5557,
    0.1116,
    -0.2674,
    0.2404,
    -0.2475,
    0.1064,
    -0.44,
    0.5067,
    -0.7107,
    0.2393,
    -0.2061,
    0.227,
    -0.2251,
    0.0971,
    -0.1325,
    0.1006,
    -0.0935,
    0.0632,
    -0.0633,
    0.0804,
    -0.0428,
    0.0558,
    -0.0509,
    0.1569,
    -0.265,
    0.3063,
    -0.3636,
    0.2962,
    -0.2922,
    0.2355,
    -0.4704,
    0.4842,
    -0.4503,
    0.1787,
    -0.217,
    0.4373,
    -0.3538,
    0.2589,
    -0.4251,
    0.3188,
    -0.4719,
    0.1926,
    -0.1711,
    0.3617,
    -0.6877,
    0.4039,
    -0.5617,
    0.6258,
    -0.6398,
    0.4983,
    -0.7442,
    0.5193,
    -0.4079,
    0.4146,
    -0.4913,
    0.5839,
    -0.552,
    0.5214,
    -0.5615,
    0.3974,
    -0.4305,
    0.7928,
    -0.4535,
    0.158,
    -0.0828,
    0.1048,
    -0.0849,
    0.0644,
    -0.1129,
    0.0703,
    -0.0621,
    0.0363,
    -0.0429,
    0.2274,
    -0.1388,
    0.5579,
    -0.4753,
    0.6236,
    -0.5458,
    0.1041,
    -0.424,
    0.2215,
    -0.0427,
    0.2253,
    -0.1949,
    0.6289,
    -0.3555,
    0.1617,
    -0.2685,
    0.2169,
    -0.5927,
    0.4465,
    -0.6786,
    0.4353,
    -0.2604,
    0.4671,
    -0.4669,
    0.3075,
    -0.4686,
    0.0541,
    -0.2439,
    0.0883,
    -0.0704,
    0.0878,
    -0.0529,
    0.0586,
    -0.0294,
    0.302,
    -0.5557,
    0.3424,
    -0.5425,
    0.3569,
    -0.5054,
    0.4763,
    -0.4716,
    0.109,
    -0.1349,
    0.0387,
    -0.1081,
    0.0352,
    -0.0274,
    0.3566,
    -0.1071,
    0.1958,
    -0.4934,
    0.3713,
    -0.3609,
    0.293,
    -0.3308,
    0.2229,
    -0.4548,
    0.2745,
    -0.3839,
    0.3035,
    -0.1262,
    0.2471,
    -0.2174,
    0.5559,
    -0.4216,
    0.5359,
    -0.4355,
    0.1695,
    -0.4396,
    0.1528,
    -0.4568,
    0.2424,
    -0.2589,
    0.2335,
    -0.2448,
    0.3915,
    -0.3393,
    0.2749,
    -0.3334,
    0.3658,
    -0.2984,
    0.3945,
    -0.6865,
    0.362,
    -0.4924,
    0.2984,
    -0.2467,
    0.3307,
    -0.4731,
    0.3717,
    -0.4213,
    0.2282,
    -0.1785,
    0.5617,
    -0.7061,
    0.1093,
    -0.3885,
    0.0821,
    -0.0693,
    0.0812,
    -0.0613,
    0.0485,
    -0.0673,
    0.4036,
    -0.3648,
    0.5899,
    -0.702,
    0.2766,
    -0.6164,
    0.3616,
    -0.536,
    0.2507,
    -0.3075,
    0.3499,
    -0.1166,
    0.3493,
    -0.1665,
    0.6966,
    -0.6928,
    0.4956,
    -0.5807,
    0.5695,
    -0.7897,
    0.4797,
    -0.4659,
    0.2957,
    -0.6533,
    0.3283,
    -0.3161,
    0.3564,
    -0.62,
    0.2994,
    -0.5649,
    0.3059,
    -0.4333,
    0.1864,
    -0.2903,
    0.3221,
    -0.3173,
    0.23,
    -0.5467,
    0.2614,
    -0.5504,
    0.3303,
    -0.4261,
    0.1994,
    -0.2458,
    0.1531,
    -0.0158,
    0.6007,
    -0.6848,
    0.4037,
    -0.3743,
    0.4889,
    -0.2198,
    0.239,
    -0.5128,
    0.076,
    -0.1335,
    0.0897,
    -0.0731,
    0.0942,
    -0.0733,
    0.0443,
    -0.0757,
    0.073,
    -0.0663,
    0.5232,
    -0.4232,
    0.53,
    -0.5623,
    0.5052,
    -0.5135,
    0.3847,
    -0.0344,
    0.6458,
    -0.6914,
    0.3785,
    -0.3943,
    0.4681,
    -0.2615,
    0.0512,
    -0.2794,
    0.4413,
    -0.1994,
    0.3249,
    -0.2014,
    0.088,
    -0.1065,
    0.0883,
    -0.0378,
    0.0688,
    -0.0159,
    0.0577,
    -0.0726,
    0.0658,
    -0.0434,
    0.0159,
    -0.036,
    -0.0079,
    -0.064,
    0.0841,
    -0.1113,
    0.0623,
    -0.0778,
    0.6237,
    -0.6507,
    0.2911,
    -0.6254,
    0.6897,
    -0.3676,
    0.6781,
    -0.2957,
    0.6745,
    -0.7472,
    0.1339,
    -0.5644,
    0.2467,
    -0.5752,
    0.2309,
    -0.0734,
    0.3386,
    -0.2987,
    0.171,
    -0.2262,
    0.3593,
    -0.2647,
    0.3653,
    -0.6085,
    0.4342,
    -0.5531,
    0.4099,
    -0.1558,
    0.2743,
    -0.7159,
    0.2869,
    -0.1827,
    0.499,
    -0.2443,
    0.6017,
    -0.5314,
    0.3757,
    -0.3935,
    0.1235,
    -0.0809,
    0.0562,
    -0.0693,
    0.0726,
    -0.0659,
    0.0508,
    -0.0886,
    0.0584,
    -0.0688,
    0.6378,
    -0.4924,
    0.2477,
    -0.5107,
    0.3602,
    -0.5712,
    0.2629,
    -0.0607,
    0.2481,
    -0.262,
    0.2537,
    -0.5037,
    0.0597,
    -0.0828,
    0.1061,
    -0.0672,
    0.0488,
    -0.0518,
    0.0493,
    -0.0642,
    0.0916,
    -0.0825,
    0.0914,
    -0.0686,
    0.0699,
    -0.0862,
    0.0743,
    -0.0362,
    0.0775,
    -0.0377,
    0.0625,
    -0.0778,
    0.1068,
    -0.079,
    0.2479,
    -0.3337,
    0.127,
    -0.0438,
    0.0528,
    -0.0528,
    0.2471,
    -0.5724,
    0.1935,
    -0.6211,
    0.2277,
    -0.1199,
    0.088,
    -0.3045,
    0.1258,
    -0.1101,
    0.4437,
    -0.3828,
    0.2592,
    -0.2555,
    0.1757,
    -0.6326,
    0.173,
    -0.109,
    0.1512,
    -0.048,
    0.0306,
    -0.0932,
    0.0262,
    -0.0774,
    0.0488,
    -0.0495,
    0.0419,
    -0.0224,
    0.1682,
    -0.2441,
    0.0703,
    -0.0933,
    0.2845,
    -0.3327,
    0.1164,
    -0.0729,
    0.0969,
    -0.0926,
    0.0709,
    -0.0608,
    0.0583,
    -0.0399,
    0.4103,
    -0.5327,
    0.3147,
    -0.4064,
    0.4705,
    -0.317,
    0.1887,
    -0.5289,
    0.1972,
    -0.3245,
    0.3607,
    -0.1729,
    0.2976,
    -0.4441,
    0.3396,
    -0.3752,
    0.6468,
    -0.4346,
    0.1046,
    -0.3295,
    0.3105,
    -0.1807,
    0.1489,
    -0.1216,
    0.2152,
    -0.3205,
    0.3826,
    -0.4929,
    0.4038,
    -0.5825,
    0.481,
    -0.3314,
    0.3878,
    -0.4861,
    0.1912,
    -0.0403,
    0.1988,
    -0.1105,
    0.2733,
    -0.2893,
    0.1305,
    -0.2464,
    0.0927,
    -0.0498,
    -0.0077,
    -0.0608,
    0.317,
    -0.0236,
    0.2726,
    -0.0128,
    0.1629,
    -0.3817,
    0.1202,
    -0.3098,
    0.2705,
    -0.5286,
    0.3839,
    -0.2907,
    0.357,
    -0.3407,
    0.1599,
    -0.2428,
    0.2499,
    -0.0997,
    0.0731,
    -0.5025,
    0.2438,
    -0.2439,
    0.2763,
    -0.3025,
    0.2313,
    -0.2254,
    0.0624,
    -0.0851,
    0.3911,
    -0.4058,
    0.2805,
    -0.1156,
    0.0629,
    -0.1027,
    0.4681,
    -0.3829,
    0.3183,
    -0.438,
    0.5059,
    -0.4423,
    0.1686,
    -0.0915,
    0.1764,
    -0.378,
    0.6967,
    -0.1666,
    0.1248,
    -0.3853,
    0.1889,
    -0.034,
    0.0824,
    -0.0809,
    0.0528,
    -0.051,
    0.0339,
    -0.0349,
    0.0589,
    -0.0881,
    0.0533,
    -0.0495,
    0.3731,
    -0.1597,
    0.1456,
    -0.1363,
    0.6256,
    -0.583,
    0.1919,
    -0.293,
    0.4579,
    -0.5408,
    0.3897,
    -0.5766,
    0.3656,
    -0.3717,
    0.6983,
    -0.7057,
    0.3431,
    -0.6473,
    0.0515,
    -0.2401,
    0.1591,
    -0.2529,
    0.2888,
    -0.2453,
    0.2031,
    -0.1269,
    0.1922,
    -0.1586,
    0.1069,
    -0.2378,
    0.2124,
    -0.1362,
    0.1063,
    -0.2171,
    0.3066,
    -0.3292,
    0.079,
    -0.0724,
    0.1271,
    -0.1247,
    0.2553,
    -0.2074,
    0.2066,
    -0.5389,
    0.1909,
    -0.4912,
    0.3674,
    -0.4146,
    0.7906,
    -0.3758,
    0.457,
    -0.12,
    0.1303,
    -0.4869,
    0.4863,
    -0.5248,
    0.3593,
    -0.2371,
    0.1517,
    -0.0639,
    0.2221,
    -0.33,
    0.3731,
    -0.2699,
    0.2343,
    -0.2639,
    0.0573,
    -0.031,
    0.3288,
    -0.4027,
    0.0584,
    -0.4375,
    0.4077,
    -0.1651,
    0.4244,
    -0.6955,
    0.1264,
    -0.3645,
    0.2324,
    -0.461,
    0.2559,
    -0.4348,
    0.1705,
    -0.4058,
    0.1037,
    -0.3378,
    0.123,
    -0.0492,
    0.0678,
    -0.0709,
    0.0651,
    -0.0234,
    0.0326,
    -0.0676,
    0.0687,
    -0.0823,
    0.0063,
    -0.0833,
    0.218,
    -0.1256,
    0.3276,
    -0.2347,
    0.3859,
    -0.7992,
    0.4946,
    -0.4967,
    0.4336,
    -0.4945,
    0.3906,
    -0.4831,
    0.25,
    -0.1267,
    0.5582,
    -0.5982,
    0.4196,
    -0.7298,
    0.1937,
    -0.1712,
    0.0695,
    -0.1532,
    0.1589,
    -0.3802,
    0.3768,
    -0.3663,
    0.2773,
    -0.208,
    0.1095,
    -0.0589,
    0.2164,
    -0.1697,
    0.164,
    -0.4426,
    0.3352,
    -0.4664,
    0.2138,
    -0.2218,
    0.3586,
    -0.2746,
    0.1902,
    -0.1433,
    0.083,
    -0.0608,
    0.0554,
    -0.0732,
    0.0457,
    -0.0508,
    0.0505,
    -0.0754,
    0.1233,
    -0.1819,
    0.5215,
    -0.5392,
    0.7432,
    -0.3971,
    0.6021,
    -0.349,
    0.2811,
    -0.3722,
    0.4387,
    -0.3475,
    0.2888,
    -0.1202,
    0.3778,
    -0.4433,
    0.3265,
    -0.1892,
    0.2286,
    -0.524,
    0.3719,
    -0.7349,
    0.2843,
    -0.465,
    0.0955,
    -0.0649,
    0.3526,
    -0.5285,
    0.7036,
    -0.4638,
    0.0839,
    -0.1055,
    0.108,
    -0.086,
    0.3639,
    -0.2287,
    0.4192,
    -0.3329,
    0.1128,
    -0.1322,
    0.0625,
    -0.1565,
    0.3821,
    -0.2682,
    0.1673,
    -0.2596,
    0.0792,
    -0.1184,
    0.0477,
    -0.5081,
    0.0982,
    -0.6055,
    0.6224,
    -0.1481,
    0.1641,
    -0.1233,
    0.3349,
    -0.3163,
    0.3533,
    -0.3042,
    0.0378,
    -0.1274,
    0.1654,
    -0.082,
    0.415,
    -0.2743,
    0.1554,
    -0.1788,
    0.0816,
    -0.0931,
    0.1017,
    -0.4824,
    0.0794,
    -0.0755,
    0.2514,
    -0.06,
    0.1987,
    -0.2581,
    0.3011,
    -0.1189,
    0.1996,
    -0.1259,
    0.1362,
    -0.139,
    0.3376,
    -0.473,
    0.0848,
    -0.0667,
    0.0568,
    -0.4795,
    0.5135,
    -0.3297,
    0.5323,
    -0.467,
    0.1439,
    -0.1112,
    0.1186,
    -0.0457,
    0.1349,
    -0.1644,
    0.0954,
    -0.1806,
    0.0611,
    -0.1411,
    0.0518,
    -0.0373,
    0.0289,
    -0.0567,
    0.0433,
    -0.0516,
    0.037,
    -0.0605,
    0.0553,
    -0.0529,
    0.0384,
    -0.0807,
    0.0522,
    -0.0172,
    0.063,
    -0.0639,
    0.0449,
    -0.0593,
    0.0389,
    -0.022,
    0.0311,
    -0.0391,
    0.0182,
    -0.0497,
    0.0424,
    -0.0353,
    0.0339,
    0.0022,
    0.0336,
    -0.0497,
    0.0399,
    -0.05,
    0.0396,
    -0.032,
    0.0147,
    -0.0188,
    0.013,
    -0.0266,
    0.0187,
    -0.017,
    0.0152,
    -0.0193,
    0.0139,
    -0.012,
    0.0129,
    -0.013,
    0.022,
    -0.0121,
    0.0107,
    -0.0133,
    0.0066,
    -0.0109,
    0.0033,
    -0.006,
    0.0044,
    -0.004,
    0.0033,
    -0.0017,
    0.0021,
    -0.0022,
    0.0017,
    -0.0023,
    0.0019,
    -0.0009,
    0.001,
    -0.0013,
    0.0004,
    -0.0004,
    0.0002,
    -0.0007
];

export default chapterEighthPCM;