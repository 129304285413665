const chapterThirdPCM = [
    0.0443,
    -0.0445,
    0.0476,
    -0.0442,
    0.0504,
    -0.0333,
    0.0436,
    -0.0523,
    0.0481,
    -0.0426,
    0.6075,
    -0.4067,
    0.5792,
    -0.2714,
    0.0757,
    -0.413,
    0.4739,
    -0.5492,
    0.4502,
    -0.4473,
    0.6476,
    -0.4658,
    0.6375,
    -0.2762,
    0.2356,
    -0.4971,
    0.0883,
    -0.2903,
    0.9022,
    -0.354,
    0.261,
    -0.3158,
    0.8013,
    -0.6887,
    0.1922,
    -0.3182,
    0.4639,
    -0.4682,
    0.2164,
    -0.3289,
    0.3524,
    -0.0372,
    0.8781,
    -0.3433,
    0.4296,
    -0.3112,
    0.1215,
    -0.2664,
    0.6149,
    -0.2879,
    0.0999,
    -0.0542,
    0.0374,
    -0.0186,
    0.0086,
    -0.0081,
    0.1936,
    -0.2993,
    0.4262,
    -0.3677,
    0.5941,
    -0.6531,
    0.4377,
    -0.4442,
    0.5339,
    -0.4894,
    0.8196,
    -0.5249,
    0.0927,
    -0.2113,
    0.5231,
    -0.5264,
    0.0726,
    -0.3323,
    0.4886,
    -0.2834,
    0.0632,
    -0.1673,
    0.5538,
    -0.3189,
    0.6992,
    -0.385,
    0.2704,
    -0.4321,
    0.0608,
    -0.0611,
    0.7821,
    -0.4931,
    0.5126,
    -0.2468,
    0.555,
    -0.6566,
    0.3841,
    -0.6124,
    0.4371,
    -0.2408,
    0.7752,
    -0.1668,
    0.311,
    -0.266,
    0.3602,
    -0.3889,
    0.5856,
    -0.5298,
    0.3421,
    -0.4459,
    0.3748,
    -0.681,
    0.0339,
    -0.1724,
    0.5653,
    -0.1771,
    0.6728,
    -0.3043,
    0.3225,
    -0.2629,
    0.0084,
    -0.0582,
    0.0083,
    -0.0119,
    0.0122,
    -0.0203,
    0.0141,
    -0.0131,
    0.4437,
    -0.1512,
    0.3566,
    -0.4707,
    0.244,
    -0.5356,
    0.4792,
    -0.2456,
    0.6419,
    -0.2651,
    0.4019,
    -0.4604,
    0.2229,
    -0.2005,
    0.5786,
    -0.0929,
    0.4326,
    -0.5841,
    0.1124,
    -0.1871,
    0.0857,
    -0.1731,
    0.0807,
    -0.2186,
    0.0513,
    -0.0694,
    0.0786,
    -0.0461,
    0.1738,
    -0.0489,
    0.3861,
    -0.6164,
    0.3057,
    -0.6766,
    0.0439,
    -0.1091,
    0.2467,
    -0.4815,
    0.6677,
    -0.5252,
    0.4337,
    -0.3697,
    0.7359,
    -0.1745,
    0.4714,
    -0.4207,
    0.1381,
    -0.4701,
    0.0635,
    -0.2628,
    0.188,
    -0.2414,
    0.0157,
    -0.1152,
    0.0223,
    -0.0151,
    0.8376,
    -0.7899,
    0.4879,
    -0.6499,
    0.0787,
    -0.4472,
    0.4545,
    -0.1245,
    0.2603,
    -0.1764,
    0.4245,
    -0.1882,
    0.6178,
    -0.4282,
    0.445,
    -0.451,
    0.3567,
    -0.375,
    0.2666,
    -0.3657,
    0.4364,
    -0.2187,
    0.5267,
    -0.1418,
    0.5972,
    -0.3472,
    0.1597,
    -0.2074,
    0.5121,
    -0.3428,
    0.0651,
    -0.0624,
    0.1544,
    -0.4674,
    0.1161,
    -0.1775,
    0.4805,
    -0.3541,
    0.34,
    -0.5568,
    0.6743,
    -0.4254,
    0.3314,
    -0.2398,
    0.2803,
    -0.0851,
    0.0549,
    -0.1406,
    0.6642,
    -0.2139,
    0.4925,
    -0.2111,
    0.2168,
    -0.4856,
    0.2094,
    -0.1803,
    0.1528,
    -0.1372,
    0.1524,
    -0.1033,
    0.0044,
    -0.0041,
    0.0248,
    -0.2781,
    0.4936,
    -0.5583,
    0.6844,
    -0.4854,
    0.3402,
    -0.4372,
    0.3091,
    -0.32,
    0.341,
    -0.1554,
    0.1117,
    -0.1171,
    0.5047,
    -0.2516,
    0.4749,
    -0.1818,
    0.2858,
    -0.4078,
    0.1707,
    -0.2698,
    0.5957,
    -0.4612,
    0.5431,
    -0.3137,
    0.6681,
    -0.3692,
    0.2994,
    -0.4406,
    0.1231,
    -0.1545,
    0.3815,
    -0.4086,
    0.376,
    -0.4644,
    0.0614,
    -0.0462,
    0.042,
    -0.0363,
    0.0323,
    -0.0418,
    0.1658,
    -0.4505,
    0.5135,
    -0.3169,
    0.7561,
    -0.4723,
    0.7831,
    -0.1966,
    0.5778,
    -0.476,
    0.7801,
    -0.5397,
    0.5707,
    -0.3567,
    0.2713,
    -0.1769,
    0.6775,
    -0.3678,
    0.2058,
    -0.2343,
    0.4396,
    -0.1777,
    0.2397,
    -0.1471,
    0.0691,
    -0.357,
    0.1136,
    -0.3388,
    0.4248,
    -0.285,
    0.3867,
    -0.5205,
    0.204,
    -0.13,
    0.16,
    -0.1415,
    0.4479,
    -0.208,
    0.5056,
    -0.4243,
    0.2118,
    -0.1531,
    0.4402,
    -0.3554,
    0.3727,
    -0.16,
    0.3833,
    -0.4947,
    0.2949,
    -0.5321,
    0.7282,
    -0.189,
    0.3191,
    -0.2561,
    0.1719,
    -0.0501,
    0.0333,
    -0.0532,
    0.0218,
    -0.0365,
    0.0264,
    -0.0147,
    0.02,
    -0.0366,
    0.6495,
    -0.4628,
    0.2415,
    -0.3383,
    0.16,
    -0.5802,
    0.1801,
    -0.1867,
    0.1802,
    -0.3975,
    0.343,
    -0.362,
    0.7903,
    -0.4164,
    0.6637,
    -0.5083,
    0.4368,
    -0.2939,
    0.0286,
    -0.0727,
    0.0114,
    -0.0273,
    0.1534,
    -0.2644,
    0.6767,
    -0.3571,
    0.454,
    -0.3799,
    0.3378,
    -0.0793,
    0.1366,
    -0.224,
    0.7957,
    -0.2069,
    0.1223,
    -0.4236,
    0.801,
    -0.4187,
    0.3287,
    -0.3048,
    0.0864,
    -0.1011,
    0.0678,
    -0.3957,
    0.3853,
    -0.2495,
    0.0619,
    -0.2352,
    0.1938,
    -0.1905,
    0.4951,
    -0.291,
    0.4832,
    -0.159,
    0.2196,
    -0.2185,
    0.1481,
    -0.2434,
    0.0418,
    -0.0553,
    0.2095,
    -0.416,
    0.3471,
    -0.5216,
    0.8902,
    -0.5445,
    0.7746,
    -0.5281,
    0.5732,
    -0.3301,
    0.6059,
    -0.2752,
    0.5049,
    -0.4533,
    0.1506,
    -0.3073,
    0.4759,
    -0.2175,
    0.4208,
    -0.0886,
    0.3932,
    -0.2813,
    0.5993,
    -0.4332,
    0.6384,
    -0.4761,
    0.7755,
    -0.456,
    0.3125,
    -0.3326,
    0.3013,
    -0.3104,
    0.5368,
    -0.4338,
    0.1393,
    -0.5657,
    0.1187,
    -0.2088,
    0.0867,
    -0.165,
    0.132,
    -0.0812,
    0.0592,
    -0.0867,
    0.117,
    -0.1892,
    0.5873,
    -0.352,
    0.0519,
    -0.4612,
    0.7326,
    -0.2409,
    0.7183,
    -0.4855,
    0.0668,
    -0.1614,
    0.2064,
    -0.5264,
    0.5379,
    -0.1781,
    0.4978,
    -0.6898,
    0.4023,
    -0.2618,
    0.4704,
    -0.387,
    0.2658,
    -0.4094,
    0.3955,
    -0.474,
    0.3526,
    -0.1786,
    0.6179,
    -0.3299,
    0.5742,
    -0.4493,
    0.2057,
    -0.3881,
    0.3747,
    -0.0214,
    0.0168,
    -0.0119,
    0.0138,
    -0.0112,
    0.0719,
    -0.5214,
    0.7281,
    -0.5363,
    0.8407,
    -0.5831,
    0.3278,
    -0.4833,
    0.2267,
    -0.1507,
    0.3113,
    -0.6545,
    0.0281,
    -0.1172,
    0.5533,
    -0.1696,
    0.4488,
    -0.4595,
    0.5473,
    -0.3452,
    0.1281,
    -0.1172,
    0.1031,
    -0.1456,
    0.0666,
    -0.0689,
    0.3613,
    -0.4659,
    0.2727,
    -0.6123,
    0.427,
    -0.5328,
    0.2815,
    -0.4192,
    0.4578,
    -0.3178,
    0.2061,
    -0.2085,
    0.1294,
    -0.1779,
    0.142,
    -0.0088,
    0.1742,
    -0.087,
    0.0997,
    -0.1791,
    0.0825,
    -0.3671,
    0.2286,
    -0.4423,
    0.432,
    -0.4871,
    0.2943,
    -0.166,
    0.1975,
    -0.367,
    0.185,
    -0.2112,
    0.3964,
    -0.6058,
    0.5564,
    -0.3047,
    0.3684,
    -0.1339,
    0.3888,
    -0.1132,
    0.0926,
    -0.0699,
    0.4659,
    -0.2554,
    0.1573,
    -0.2705,
    0.2046,
    -0.4405,
    0.2297,
    -0.1624,
    0.3819,
    -0.6027,
    0.5742,
    -0.3503,
    0.0872,
    -0.2234,
    0.4318,
    -0.1589,
    0.1754,
    -0.4796,
    0.1228,
    -0.1378,
    0.3845,
    -0.3463,
    0.3751,
    -0.0888,
    0.5499,
    -0.5805,
    0.655,
    -0.5068,
    0.0422,
    -0.424,
    0.8131,
    -0.1462,
    0.2024,
    -0.3236,
    0.8898,
    -0.4281,
    0.1792,
    -0.2569,
    0.0418,
    -0.0902,
    0.3228,
    -0.0996,
    0.0937,
    -0.1828,
    0.0963,
    -0.0792,
    0.654,
    -0.3565,
    0.626,
    -0.4947,
    0.3591,
    -0.3834,
    0.2806,
    -0.556,
    0.2768,
    -0.1836,
    0.477,
    -0.3047,
    0.2659,
    -0.6421,
    0.6275,
    -0.2426,
    0.0996,
    -0.2921,
    0.1109,
    -0.1197,
    0.0746,
    -0.1402,
    0.2498,
    -0.1618,
    0.1296,
    -0.2122,
    0.0876,
    -0.1229,
    0.1134,
    -0.1018,
    0.2711,
    -0.1342,
    0.8467,
    -0.4378,
    0.1889,
    -0.6357,
    0.6366,
    -0.725,
    0.5988,
    -0.1839,
    0.5127,
    -0.4522,
    0.4927,
    -0.3005,
    0.0551,
    -0.0676,
    0.0555,
    -0.1042,
    0.5324,
    -0.0636,
    0.5436,
    -0.2637,
    0.32,
    -0.0743,
    0.1311,
    -0.1307,
    0.3885,
    -0.7069,
    0.1496,
    -0.3126,
    0.1034,
    -0.2487,
    0.1453,
    -0.093,
    0.2805,
    -0.311,
    0.1724,
    -0.1784,
    0.2354,
    -0.1135,
    0.3023,
    -0.3473,
    0.1068,
    -0.3623,
    0.3931,
    -0.1799,
    0.2391,
    -0.2727,
    0.1297,
    -0.3914,
    0.2619,
    -0.0651,
    0.1835,
    -0.3001,
    0.1032,
    -0.1375,
    0.4812,
    -0.7255,
    0.557,
    -0.8227,
    0.5774,
    -0.2153,
    0.5443,
    -0.461,
    0.2864,
    -0.2805,
    0.1745,
    -0.2713,
    0.4266,
    -0.4026,
    0.2669,
    -0.1941,
    0.2654,
    -0.7125,
    0.291,
    -0.434,
    0.2442,
    -0.3248,
    0.4791,
    -0.3018,
    0.1514,
    -0.1458,
    0.3175,
    -0.7111,
    0.667,
    -0.6294,
    0.3811,
    -0.1447,
    0.2758,
    -0.5011,
    0.1705,
    -0.6161,
    0.3077,
    -0.133,
    0.0547,
    -0.0776,
    0.4588,
    -0.4883,
    0.5847,
    -0.2465,
    0.645,
    -0.3255,
    0.1559,
    -0.2258,
    0.1053,
    -0.1581,
    0.0755,
    -0.1177,
    0.3079,
    -0.2836,
    0.1516,
    -0.3318,
    0.2846,
    -0.464,
    0.2265,
    -0.1016,
    0.4165,
    -0.3646,
    0.5789,
    -0.2564,
    0.3948,
    -0.3645,
    0.7039,
    -0.4435,
    0.2688,
    -0.204,
    0.1988,
    -0.2763,
    0.2587,
    -0.2386,
    0.0895,
    -0.2478,
    0.1118,
    -0.2676,
    0.1048,
    -0.1362,
    0.5344,
    -0.7827,
    0.167,
    -0.2171,
    0.0915,
    -0.1212,
    0.0659,
    -0.0976,
    0.0758,
    -0.0694,
    0.3835,
    -0.2577,
    0.3504,
    -0.5572,
    0.4166,
    -0.748,
    0.1443,
    -0.2611,
    0.1988,
    -0.241,
    0.3584,
    -0.1814,
    0.1227,
    -0.4813,
    0.0825,
    -0.2283,
    0.0931,
    -0.0725,
    0.0593,
    -0.0525,
    0.1361,
    -0.1059,
    0.8202,
    -0.6974,
    0.4342,
    -0.8199,
    0.6299,
    -0.461,
    0.3334,
    -0.4547,
    0.3935,
    -0.3614,
    0.2509,
    -0.3154,
    0.2285,
    -0.284,
    0.0889,
    -0.1099,
    0.1159,
    -0.0448,
    0.3527,
    -0.4238,
    0.4251,
    -0.4734,
    0.4613,
    -0.5162,
    0.4018,
    -0.535,
    0.5538,
    -0.1823,
    0.2609,
    -0.6043,
    0.3867,
    -0.5146,
    0.2327,
    -0.3056,
    0.2653,
    -0.4623,
    0.2208,
    -0.5278,
    0.2466,
    -0.4544,
    0.2809,
    -0.0999,
    0.3725,
    -0.446,
    0.3311,
    -0.441,
    0.1916,
    -0.6002,
    0.5452,
    -0.8001,
    0.6034,
    -0.5926,
    0.3962,
    -0.1968,
    0.3257,
    -0.0542,
    0.4619,
    -0.0774,
    0.5839,
    -0.3339,
    0.2649,
    -0.1455,
    0.2891,
    -0.0886,
    0.0509,
    -0.0786,
    0.0847,
    -0.0509,
    0.3279,
    -0.2862,
    0.771,
    -0.1487,
    0.0928,
    -0.5402,
    0.0761,
    -0.1714,
    0.1768,
    -0.3273,
    0.6328,
    -0.409,
    0.2815,
    -0.4188,
    0.1246,
    -0.0701,
    0.4443,
    -0.3201,
    0.1324,
    -0.1482,
    0.5032,
    -0.2837,
    0.0915,
    -0.257,
    0.1366,
    -0.436,
    0.0504,
    -0.2709,
    0.3172,
    -0.1489,
    0.3816,
    -0.4345,
    0.3405,
    -0.5098,
    0.4298,
    -0.2051,
    0.0277,
    -0.0888,
    0.2197,
    -0.0509,
    0.4979,
    -0.316,
    0.4473,
    -0.1995,
    0.129,
    -0.3664,
    0.1281,
    -0.0792,
    0.1858,
    -0.2641,
    0.2263,
    -0.3127,
    0.2028,
    -0.1309,
    0.1404,
    -0.1318,
    0.0822,
    -0.1322,
    0.5297,
    -0.4049,
    0.3155,
    -0.5929,
    0.8571,
    -0.4634,
    0.32,
    -0.4309,
    0.3932,
    -0.2366,
    0.104,
    -0.1238,
    0.2935,
    -0.3595,
    0.3894,
    -0.1011,
    0.1293,
    -0.096,
    0.1043,
    -0.0986,
    0.1252,
    -0.0983,
    0.0704,
    -0.1159,
    0.0935,
    -0.1014,
    0.2277,
    -0.1383,
    0.5374,
    -0.3931,
    0.6427,
    -0.1936,
    0.4747,
    -0.6705,
    0.397,
    -0.1493,
    0.4266,
    -0.6906,
    0.1697,
    -0.1158,
    0.091,
    -0.1024,
    0.1115,
    -0.1002,
    0.0753,
    -0.1444,
    0.0878,
    -0.1694,
    0.3072,
    -0.4158,
    0.6527,
    -0.7582,
    0.4847,
    -0.4229,
    0.5952,
    -0.647,
    0.1051,
    -0.1658,
    0.5128,
    -0.4505,
    0.7206,
    -0.2345,
    0.0866,
    -0.2254,
    0.0698,
    -0.1632,
    0.2203,
    -0.2748,
    0.0541,
    -0.1163,
    0.0279,
    -0.1095,
    0.4689,
    -0.4394,
    0.1839,
    -0.2388,
    0.5184,
    -0.6874,
    0.1456,
    -0.1193,
    0.33,
    -0.1822,
    0.3114,
    -0.105,
    0.0925,
    -0.0942,
    0.5032,
    -0.5496,
    0.4013,
    -0.8696,
    0.2424,
    -0.3779,
    0.0709,
    -0.1471,
    0.2883,
    -0.5052,
    0.1435,
    -0.4969,
    0.1872,
    -0.2749,
    0.1045,
    -0.1165,
    0.0937,
    -0.1349,
    0.16,
    -0.1336,
    0.5432,
    -0.4403,
    0.1438,
    -0.2239,
    0.6107,
    -0.5993,
    0.1103,
    -0.106,
    0.2989,
    -0.3499,
    0.2167,
    -0.1753,
    0.2907,
    -0.6021,
    0.5706,
    -0.6293,
    0.4866,
    -0.7088,
    0.4011,
    -0.351,
    0.26,
    -0.5136,
    0.6113,
    -0.5528,
    0.3382,
    -0.4711,
    0.3692,
    -0.0829,
    0.6112,
    -0.3977,
    0.4022,
    -0.3581,
    0.4372,
    -0.3548,
    0.4674,
    -0.5065,
    0.1946,
    -0.5752,
    0.4151,
    -0.4566,
    0.1796,
    -0.6587,
    0.3994,
    -0.2569,
    0.6246,
    -0.551,
    0.5576,
    -0.2506,
    0.1894,
    -0.6081,
    0.4964,
    -0.3086,
    0.4934,
    -0.3022,
    0.2394,
    -0.3875,
    0.1177,
    -0.362,
    0.5546,
    -0.1143,
    0.6756,
    -0.4101,
    0.3801,
    -0.15,
    0.4623,
    -0.6054,
    0.3511,
    -0.5672,
    0.5553,
    -0.1681,
    0.2158,
    -0.191,
    0.0502,
    -0.0583,
    0.0994,
    -0.0796,
    0.3338,
    -0.3585,
    0.3293,
    -0.7821,
    0.4958,
    -0.2206,
    0.2543,
    -0.3158,
    0.2048,
    -0.2339,
    0.0974,
    -0.1208,
    0.1309,
    -0.0587,
    0.073,
    -0.0814,
    0.1131,
    -0.2504,
    0.8198,
    -0.666,
    0.6211,
    -0.5026,
    0.8416,
    -0.2414,
    0.3865,
    -0.6826,
    0.1388,
    -0.151,
    0.1562,
    -0.1092,
    0.2046,
    -0.3084,
    0.3659,
    -0.8043,
    0.2382,
    -0.2079,
    0.0998,
    -0.3209,
    0.3274,
    -0.1645,
    0.6688,
    -0.665,
    0.4222,
    -0.7989,
    0.3667,
    -0.5564,
    0.57,
    -0.4316,
    0.2251,
    -0.2611,
    0.2658,
    -0.4453,
    0.3269,
    -0.2397,
    0.336,
    -0.346,
    0.45,
    -0.4363,
    0.3867,
    -0.6069,
    0.2889,
    -0.3222,
    0.2249,
    -0.1833,
    0.1225,
    -0.1981,
    0.1706,
    -0.1179,
    0.2098,
    -0.2851,
    0.0939,
    -0.093,
    0.2172,
    -0.1908,
    0.1913,
    -0.2731,
    0.0964,
    -0.0634,
    0.0921,
    -0.1383,
    0.1359,
    -0.1345,
    0.1036,
    -0.0935,
    0.1454,
    -0.1288,
    0.1998,
    -0.122,
    0.1557,
    -0.1583,
    0.2746,
    -0.1684,
    0.1749,
    -0.4999,
    0.7115,
    -0.5304,
    0.3105,
    -0.6777,
    0.5206,
    -0.4631,
    0.3725,
    -0.1641,
    0.3141,
    -0.4594,
    0.4529,
    -0.276,
    0.2112,
    -0.17,
    0.3978,
    -0.0896,
    0.2273,
    -0.1166,
    0.1039,
    -0.1471,
    0.0818,
    -0.1066,
    0.0607,
    -0.1102,
    0.1119,
    -0.095,
    0.147,
    -0.0838,
    0.134,
    -0.1527,
    0.1138,
    -0.1013,
    0.1031,
    -0.1239,
    0.0967,
    -0.098,
    0.2411,
    -0.1602,
    0.3264,
    -0.4085,
    0.3407,
    -0.2856,
    0.3234,
    -0.2763,
    0.2122,
    -0.2936,
    0.3043,
    -0.4042,
    0.4368,
    -0.3013,
    0.4013,
    -0.4816,
    0.1724,
    -0.3643,
    0.3159,
    -0.6882,
    0.3815,
    -0.2992,
    0.4231,
    -0.7806,
    0.377,
    -0.5417,
    0.1677,
    -0.3654,
    0.4306,
    -0.5876,
    0.2143,
    -0.1482,
    0.0971,
    -0.1478,
    0.0802,
    -0.0642,
    0.504,
    -0.2241,
    0.294,
    -0.3673,
    0.4646,
    -0.5254,
    0.3943,
    -0.398,
    0.1135,
    -0.4385,
    0.1851,
    -0.2593,
    0.2474,
    -0.1452,
    0.2511,
    -0.3423,
    0.1468,
    -0.1476,
    0.078,
    -0.0729,
    0.0905,
    -0.0824,
    0.23,
    -0.6578,
    0.2422,
    -0.4643,
    0.7031,
    -0.7452,
    0.2684,
    -0.2042,
    0.4283,
    -0.5811,
    0.2588,
    -0.163,
    0.5064,
    -0.0818,
    0.1247,
    -0.1523,
    0.2769,
    -0.1579,
    0.5453,
    -0.6322,
    0.3178,
    -0.1971,
    0.1478,
    -0.1661,
    0.1422,
    -0.3265,
    0.2014,
    -0.3626,
    0.2027,
    -0.2406,
    0.2686,
    -0.268,
    0.0747,
    -0.1201,
    0.0442,
    -0.1193,
    0.107,
    -0.1537,
    0.5743,
    -0.5024,
    0.2691,
    -0.403,
    0.6846,
    -0.2341,
    0.4241,
    -0.4968,
    0.3509,
    -0.1498,
    0.1369,
    -0.1188,
    0.2194,
    -0.4784,
    0.493,
    -0.1118,
    0.4415,
    -0.1862,
    0.2274,
    -0.0711,
    0.2742,
    -0.5639,
    0.1393,
    -0.2524,
    0.1398,
    -0.3875,
    0.101,
    -0.2639,
    0.1534,
    -0.5936,
    0.1286,
    -0.2476,
    0.1126,
    -0.0943,
    0.0641,
    -0.0982,
    0.0415,
    -0.7236,
    0.4048,
    -0.1144,
    0.2079,
    -0.4772,
    0.2767,
    -0.7922,
    0.4221,
    -0.2059,
    0.1898,
    -0.1668,
    0.2079,
    -0.2401,
    0.4653,
    -0.5254,
    0.2278,
    -0.236,
    0.1081,
    -0.2709,
    0.1627,
    -0.3835,
    0.1726,
    -0.2988,
    0.3189,
    -0.5542,
    0.1048,
    -0.3294,
    0.1205,
    -0.3344,
    0.344,
    -0.3061,
    0.2235,
    -0.1244,
    0.3342,
    -0.3999,
    0.3508,
    -0.1217,
    0.0498,
    -0.1368,
    0.0819,
    -0.1269,
    0.2492,
    -0.3175,
    0.2898,
    -0.1034,
    0.1858,
    -0.2112,
    0.8193,
    -0.0942,
    0.1611,
    -0.4037,
    0.372,
    -0.573,
    0.1765,
    -0.5533,
    0.2213,
    -0.0575,
    0.2111,
    -0.2597,
    0.2607,
    -0.3217,
    0.3421,
    -0.415,
    0.1068,
    -0.0902,
    0.0709,
    -0.0676,
    0.1951,
    -0.4185,
    0.1908,
    -0.6026,
    0.5369,
    -0.2286,
    0.1453,
    -0.1931,
    0.1199,
    -0.2962,
    0.1285,
    -0.1515,
    0.1002,
    -0.0989,
    0.1213,
    -0.0395,
    0.0678,
    -0.0897,
    0.2092,
    -0.1851,
    0.2991,
    -0.7706,
    0.2961,
    -0.5874,
    0.2053,
    -0.2198,
    0.4542,
    -0.5222,
    0.1181,
    -0.2405,
    0.4061,
    -0.611,
    0.5552,
    -0.5433,
    0.4067,
    -0.1062,
    0.0794,
    -0.1357,
    0.0874,
    -0.0688,
    0.2588,
    -0.2412,
    0.1134,
    -0.2795,
    0.0072,
    -0.3262,
    0.7201,
    -0.6865,
    0.4107,
    -0.3497,
    0.3335,
    -0.2403,
    0.4526,
    -0.8907,
    0.1679,
    -0.0969,
    0.5879,
    -0.7127,
    0.5222,
    -0.7723,
    0.2806,
    -0.7188,
    0.0729,
    -0.504,
    0.1365,
    -0.1461,
    0.6307,
    -0.4144,
    0.0478,
    -0.1262,
    0.1021,
    -0.1307,
    0.2584,
    -0.3585,
    0.5049,
    -0.5993,
    0.3135,
    -0.1359,
    0.0744,
    -0.233,
    0.1552,
    -0.2069,
    0.1497,
    -0.714,
    0.1435,
    -0.2858,
    0.3957,
    -0.4087,
    0.2412,
    -0.2085,
    0.1549,
    -0.0735,
    0.1464,
    -0.134,
    0.0652,
    -0.1376,
    0.0586,
    -0.1013,
    0.4355,
    -0.2294,
    0.831,
    -0.2942,
    0.7236,
    -0.6813,
    0.5964,
    -0.1619,
    0.6839,
    -0.7974,
    0.3328,
    -0.2792,
    0.2123,
    -0.1427,
    0.6075,
    -0.2647,
    0.3845,
    -0.5248,
    0.5252,
    -0.2996,
    0.8368,
    -0.3566,
    0.1205,
    -0.2566,
    0.5509,
    -0.7521,
    0.1364,
    -0.1372,
    0.0619,
    -0.1737,
    0.0591,
    -0.1087,
    0.0969,
    -0.0763,
    0.047,
    -0.0665,
    0.0653,
    -0.0657,
    0.2396,
    -0.396,
    0.5461,
    -0.661,
    0.3295,
    -0.5134,
    0.4965,
    -0.0734,
    0.5428,
    -0.2494,
    0.6441,
    -0.2825,
    0.2743,
    -0.2839,
    0.281,
    -0.5132,
    0.5311,
    -0.1181,
    0.2398,
    -0.1434,
    0.3433,
    -0.6783,
    0.3089,
    -0.114,
    0.1563,
    -0.0948,
    0.2197,
    -0.4619,
    0.1015,
    -0.0969,
    0.2369,
    -0.1488,
    0.2872,
    -0.3636,
    0.7333,
    -0.3162,
    0.3618,
    -0.3821,
    0.2451,
    -0.5413,
    0.0678,
    -0.1632,
    0.3183,
    -0.4122,
    0.3679,
    -0.4198,
    0.1738,
    -0.1759,
    0.6858,
    -0.647,
    0.7212,
    -0.4293,
    0.3464,
    -0.4661,
    0.5959,
    -0.5054,
    0.4935,
    -0.8728,
    0.4126,
    -0.3327,
    0.048,
    -0.4845,
    0.1722,
    -0.5118,
    0.4483,
    -0.2235,
    0.2593,
    -0.1003,
    0.4455,
    -0.7854,
    0.3853,
    -0.2241,
    0.1931,
    -0.4575,
    0.1478,
    -0.6294,
    0.1725,
    -0.3452,
    0.6116,
    -0.4881,
    0.204,
    -0.3487,
    0.3447,
    -0.3298,
    0.2768,
    -0.2826,
    0.1252,
    -0.1271,
    0.3276,
    -0.4428,
    0.1366,
    -0.0663,
    0.6458,
    -0.3289,
    0.5903,
    -0.5377,
    0.241,
    -0.3228,
    0.1223,
    -0.1495,
    0.0589,
    -0.088,
    0.1093,
    -0.0872,
    0.0686,
    -0.116,
    0.4563,
    -0.4668,
    0.2933,
    -0.3372,
    0.5246,
    -0.4101,
    0.3685,
    -0.1838,
    0.4019,
    -0.3715,
    0.1787,
    -0.304,
    0.4369,
    -0.5618,
    0.3198,
    -0.1318,
    0.4453,
    -0.5153,
    0.1792,
    -0.2265,
    0.3862,
    -0.2016,
    0.1123,
    -0.3165,
    0.5382,
    -0.3828,
    0.5874,
    -0.2844,
    0.1659,
    -0.3897,
    0.7962,
    -0.3658,
    0.7133,
    -0.6066,
    0.3587,
    -0.5223,
    0.5116,
    -0.2254,
    0.3139,
    -0.6813,
    0.0826,
    -0.1063,
    0.1072,
    -0.0999,
    0.1854,
    -0.1298,
    0.142,
    -0.0714,
    0.4978,
    -0.1329,
    0.1068,
    -0.6551,
    0.1653,
    -0.4435,
    0.2273,
    -0.5469,
    0.3945,
    -0.7041,
    0.7033,
    -0.8591,
    0.193,
    -0.1748,
    0.0438,
    -0.0999,
    0.1054,
    -0.0336,
    0.4437,
    -0.7599,
    0.5261,
    -0.5175,
    0.4787,
    -0.5124,
    0.4143,
    -0.4732,
    0.3668,
    -0.3963,
    0.3607,
    -0.4094,
    0.0777,
    -0.0592,
    0.0427,
    -0.0381,
    0.4645,
    -0.0806,
    0.5531,
    -0.7289,
    0.492,
    -0.629,
    0.3982,
    -0.6286,
    0.4497,
    -0.141,
    0.417,
    -0.3511,
    0.2359,
    -0.63,
    0.5935,
    -0.3056,
    0.0766,
    -0.0743,
    0.0839,
    -0.1104,
    0.0451,
    -0.0794,
    0.3377,
    -0.3259,
    0.1035,
    -0.4185,
    0.2001,
    -0.3511,
    0.6488,
    -0.5559,
    0.698,
    -0.5657,
    0.8309,
    -0.5851,
    0.8795,
    -0.5957,
    0.4717,
    -0.8306,
    0.2901,
    -0.52,
    0.5689,
    -0.4697,
    0.3797,
    -0.4517,
    0.1563,
    -0.4761,
    0.2594,
    -0.1753,
    0.3076,
    -0.2728,
    0.42,
    -0.5161,
    0.3928,
    -0.4593,
    0.3014,
    -0.1202,
    0.1152,
    -0.0628,
    0.1222,
    -0.1464,
    0.7435,
    -0.5191,
    0.1887,
    -0.8292,
    0.3412,
    -0.9206,
    0.3968,
    -0.4317,
    0.6935,
    -0.3249,
    0.2678,
    -0.8322,
    0.2879,
    -0.7063,
    0.4213,
    -0.5913,
    0.3676,
    -0.2042,
    0.3886,
    -0.3665,
    0.2834,
    -0.5627,
    0.6309,
    -0.7674,
    0.3344,
    -0.5695,
    0.4524,
    -0.7849,
    0.0673,
    -0.0636,
    0.0691,
    -0.0517,
    0.0913,
    -0.1366,
    0.1649,
    -0.1218,
    0.1487,
    -0.8511,
    0.7459,
    -0.7413,
    0.391,
    -0.257,
    0.5909,
    -0.4519,
    0.5782,
    -0.633,
    0.1724,
    -0.3741,
    0.4717,
    -0.4094,
    0.4515,
    -0.154,
    0.4371,
    -0.6452,
    0.2162,
    -0.4399,
    0.4392,
    -0.4176,
    0.5766,
    -0.353,
    0.3902,
    -0.6109,
    0.1189,
    -0.3798,
    0.3627,
    -0.4997,
    0.3617,
    -0.2651,
    0.5265,
    -0.5523,
    0.2673,
    -0.0854,
    0.0822,
    -0.0861,
    0.072,
    -0.0491,
    0.0603,
    -0.078,
    0.3548,
    -0.447,
    0.628,
    -0.5546,
    0.5033,
    -0.1076,
    0.1556,
    -0.3672,
    0.0471,
    -0.0617,
    0.0944,
    -0.1196,
    0.614,
    -0.1428,
    0.5358,
    -0.5882,
    0.3324,
    -0.2749,
    0.185,
    -0.0901,
    0.1776,
    -0.1398,
    0.4199,
    -0.4274,
    0.3752,
    -0.5908,
    0.3146,
    -0.4222,
    0.2582,
    -0.428,
    0.746,
    -0.5529,
    0.5196,
    -0.7207,
    0.2434,
    -0.3242,
    0.2787,
    -0.3083,
    0.0493,
    -0.0917,
    0.0737,
    -0.103,
    0.1087,
    -0.2909,
    0.1886,
    -0.3324,
    0.1477,
    -0.305,
    0.25,
    -0.4986,
    0.2496,
    -0.2263,
    0.2043,
    -0.1068,
    0.0743,
    -0.1625,
    0.1465,
    -0.1076,
    0.0722,
    -0.053,
    0.2097,
    -0.0548,
    0.3545,
    -0.7777,
    0.4854,
    -0.4999,
    0.4778,
    -0.73,
    0.1827,
    -0.4753,
    0.139,
    -0.4918,
    0.1051,
    -0.2082,
    0.1347,
    -0.1852,
    0.0591,
    -0.0703,
    0.08,
    -0.1797,
    0.1254,
    -0.1172,
    0.058,
    -0.0949,
    0.3205,
    -0.6544,
    0.3186,
    -0.7748,
    0.0993,
    -0.482,
    0.8405,
    -0.5294,
    0.1912,
    -0.267,
    0.5193,
    -0.1857,
    0.4277,
    -0.6278,
    0.2599,
    -0.2866,
    0.1533,
    -0.1216,
    0.0792,
    -0.0843,
    0.0934,
    -0.6494,
    0.265,
    -0.1739,
    0.424,
    -0.4949,
    0.297,
    -0.5539,
    0.5074,
    -0.328,
    0.6672,
    -0.357,
    0.0309,
    -0.0327,
    0.0353,
    -0.0229,
    0.0136,
    -0.017,
    0.0147,
    -0.0156,
    0.0044,
    -0.0054,
    0.001,
    -0.002,
    0.0002,
    -0.001,
    -0.0003,
    -0.0005,
    -0.0003,
    -0.0004,
    -0.0002,
    -0.0003,
    -0.0001,
    -0.0002,
    -0.0001,
    -0.0002,
    0,
    -0.0001,
    0,
    -0.0001
];

export default chapterThirdPCM;