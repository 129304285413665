export const fontFamily = {
    base: 'akkurat'
};

export const colors = {
    base: '#000000',
    white: '#ffffff',
    lightBg: '#f5f5f5',
    lightGrey: '#E5E5E5',
    borderColor: '#b3b3b3'
};