import Chapter from "../../components/Chapter";
import ChapterList from "../../components/ChapterList";
import HeaderTitle from "../../components/HeaderTitle";
import appData from "../../data/appData";

const [introChapterData] = appData;

const Home = () => {
    return (
        <>
            <HeaderTitle />
            <Chapter
                {...introChapterData}
            />
            <hr/>
            <ChapterList />
        </>
    );
};

export default Home;