import MultiCanvas from "wavesurfer.js/src/drawer.multicanvas";

export default class CustomRenderer extends MultiCanvas {
    constructor(container, params) {
        super(container, params);
    }

    drawBars(peaks, channelIndex, start, end) {
        return this.prepareDraw(
            peaks,
            channelIndex,
            start,
            end,
            ({ absmax, hasMinVals, halfH, peaks, channelIndex: ch }) => {
                // if drawBars was called within ws.empty we don't pass a start and
                // don't want anything to happen
                if (start === undefined) {
                    return;
                }
                // Skip every other value if there are negatives.
                const peakIndexScale = hasMinVals ? 2 : 1;
                const length = peaks.length / peakIndexScale;
                const bar = this.params.barWidth * this.params.pixelRatio;
                const gap =
                    this.params.barGap === null
                        ? Math.max(this.params.pixelRatio, ~~(bar / 2))
                        : Math.max(
                            this.params.pixelRatio,
                            this.params.barGap * this.params.pixelRatio
                        );
                const step = bar + gap;

                const scale = length / this.width;
                const first = start;
                const last = end;
                let peakIndex = first;
                for (peakIndex; peakIndex < last; peakIndex += step) {

                    // search for the highest peak in the range this bar falls into
                    let peak = 0;
                    let peakIndexRange = Math.floor(peakIndex * scale) * peakIndexScale; // start index
                    const peakIndexEnd = Math.floor((peakIndex + step) * scale) * peakIndexScale;
                    do { // do..while makes sure at least one peak is always evaluated
                        const newPeak = Math.abs(peaks[peakIndexRange]); // for arrays starting with negative values
                        if (newPeak > peak) {
                            peak = newPeak; // higher
                        }
                        peakIndexRange += peakIndexScale; // skip every other value for negatives
                    } while (peakIndexRange < peakIndexEnd);

                    // calculate the height of this bar according to the highest peak found
                    let h = Math.round((peak / absmax) * halfH);

                    // in case of silences, allow the user to specify that we
                    // always draw *something* (normally a 1px high bar)
                    if (h == 0 && this.params.barMinHeight) {
                        h = this.params.barMinHeight;
                    }

                    this.fillRect(
                        peakIndex + this.halfPixel,
                        (halfH - h) * 2,
                        bar + this.halfPixel,
                        h * 2,
                        this.barRadius,
                        ch
                    );
                }
            }
        );
    }
}