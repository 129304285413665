const completeToTwoDigits = ( number ) => {
    return number > 9 ? number.toString() : `0${number}`;
}

export const convertSecondsToAudioTime = ( seconds ) => {
    if (seconds === undefined) {
        return '00:00';
    }
    const roundedSeconds = Math.floor(seconds);

    if (roundedSeconds === 0) {
        return '00:00';
    }

    const minutes = Math.floor(roundedSeconds / 60);
    const secondsLeft = roundedSeconds % 60;

    // if (log) {
    //     console.log('seconds: ', seconds);
    //     console.log('roundedSeconds: ', roundedSeconds);
    //     console.log('minutes: ', minutes);
    //     console.log('secondsLeft: ', secondsLeft);
    // }

    return `${completeToTwoDigits(minutes)}:${completeToTwoDigits(secondsLeft)}`;
}