import {
    Error404Wrap,
    ErrorInfo
} from "./Error404.styles";
import {Link} from "react-router-dom";
import {DetailPageHeader} from "../DetailPage/DetailPage.styles";

const Error404 = () => {
    return (
        <Error404Wrap>
            <DetailPageHeader>
                <Link to='/'>
                    <img src='/images/icon-home.svg' alt='icon-home'/>
                </Link>
            </DetailPageHeader>
            <ErrorInfo>
                <img src='/images/404.png' alt='error-image'/>
            </ErrorInfo>
        </Error404Wrap>
    );
};

export default Error404;