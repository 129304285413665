import styled, { css } from 'styled-components';

export const HeaderTitleWrapper = styled.div(({ theme: { colors } }) => {
    return css`
      h1 {
        position: fixed;
        background-color: ${colors.lightBg};
        border-bottom: 1px solid ${colors.borderColor};
        font-size: 17px;
        padding: 12px 0 15px;
        text-align: center;
        line-height: 22px;
        width: 100%;
        z-index: 10;
      }
  `;
});