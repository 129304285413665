const chapterSeventhPCM = [
    0.2047,
    -0.0009,
    0.2607,
    -0.2596,
    0.0643,
    -0.0866,
    0.0275,
    -0.0381,
    0.0355,
    -0.0307,
    0.2368,
    -0.2365,
    0.0639,
    -0.1127,
    0.0372,
    -0.0373,
    0.0391,
    -0.0317,
    0.1988,
    -0.1887,
    0.2105,
    -0.085,
    0.3814,
    -0.1375,
    0.6079,
    -0.283,
    0.4658,
    -0.7869,
    0.3281,
    -0.3993,
    0.3137,
    -0.1524,
    0.8749,
    -0.4047,
    0.389,
    -0.2422,
    0.4234,
    -0.5217,
    0.3847,
    -0.7652,
    0.3365,
    -0.1282,
    0.578,
    -0.1491,
    0.4168,
    -0.5462,
    0.3775,
    -0.4867,
    0.6651,
    -0.1521,
    0.5188,
    -0.2934,
    0.2026,
    -0.2646,
    0.2406,
    -0.1308,
    0.4031,
    -0.2385,
    0.1629,
    -0.1786,
    0.0181,
    -0.0212,
    0.131,
    -0.0451,
    0.561,
    -0.4817,
    0.5704,
    -0.7292,
    0.4363,
    -0.4258,
    0.3594,
    -0.282,
    0.1519,
    -0.1417,
    0.0974,
    -0.3353,
    0.7155,
    -0.4159,
    0.6958,
    -0.2637,
    0.3278,
    -0.2141,
    0.2606,
    -0.0675,
    0.2621,
    -0.8642,
    0.2058,
    -0.2691,
    0.1668,
    -0.3613,
    0.4148,
    -0.2907,
    0.2576,
    -0.3749,
    0.3981,
    -0.325,
    0.4896,
    -0.5086,
    0.2929,
    -0.4672,
    0.2525,
    -0.2086,
    0.3904,
    -0.2566,
    0.558,
    -0.5689,
    0.404,
    -0.2885,
    0.0777,
    -0.3235,
    0.179,
    -0.2166,
    0.1214,
    -0.1365,
    0.2958,
    -0.6047,
    0.4591,
    -0.4767,
    0.2167,
    -0.3828,
    0.8925,
    -0.7321,
    0.3152,
    -0.5095,
    0.1291,
    -0.256,
    0.6027,
    -0.0738,
    0.4677,
    -0.5454,
    0.6617,
    -0.5116,
    0.428,
    -0.4668,
    0.0228,
    -0.0735,
    0.1377,
    -0.2599,
    0.0755,
    -0.1766,
    0.3508,
    -0.6618,
    0.1277,
    -0.3014,
    0.2496,
    -0.3281,
    0.36,
    -0.2992,
    0.0612,
    -0.0966,
    0.5699,
    -0.5301,
    0.3414,
    -0.2164,
    0.785,
    -0.1621,
    0.2165,
    -0.6227,
    0.8094,
    -0.6628,
    0.7474,
    -0.6756,
    0.8648,
    -0.502,
    0.0522,
    -0.2657,
    0.0214,
    -0.0112,
    0.645,
    -0.7053,
    0.2287,
    -0.2506,
    0.703,
    -0.4381,
    0.6696,
    -0.7114,
    0.0765,
    -0.1789,
    0.2686,
    -0.3119,
    0.4973,
    -0.5924,
    0.3159,
    -0.7603,
    0.7042,
    -0.2174,
    0.7481,
    -0.3705,
    0.3239,
    -0.1986,
    0.4979,
    -0.2883,
    0.1617,
    -0.096,
    0.0174,
    -0.0412,
    0.0351,
    -0.0521,
    0.24,
    -0.4607,
    0.5661,
    -0.7982,
    0.5743,
    -0.4481,
    0.4362,
    -0.3828,
    0.3861,
    -0.227,
    0.1003,
    -0.0457,
    0.0252,
    -0.0263,
    0.5312,
    -0.4715,
    0.6438,
    -0.4199,
    0.7108,
    -0.2339,
    0.2913,
    -0.1763,
    0.1603,
    -0.1446,
    0.0814,
    -0.1095,
    0.0244,
    -0.0318,
    0.1122,
    -0.1426,
    0.0512,
    -0.0352,
    0.0158,
    -0.0321,
    0.0198,
    -0.0216,
    0.5261,
    -0.6426,
    0.5151,
    -0.2649,
    0.6092,
    -0.5669,
    0.7528,
    -0.87,
    0.1568,
    -0.1769,
    0.142,
    -0.0881,
    0.0824,
    -0.0298,
    0.0201,
    -0.0278,
    0.2532,
    -0.1451,
    0.6635,
    -0.3508,
    0.6126,
    -0.4225,
    0.1339,
    -0.2343,
    0.2991,
    -0.1813,
    0.0812,
    -0.1011,
    0.6561,
    -0.1381,
    0.2886,
    -0.5063,
    0.1754,
    -0.3647,
    0.0261,
    -0.0424,
    0.1263,
    -0.0191,
    0.0679,
    -0.368,
    0.2741,
    -0.2866,
    0.5033,
    -0.3645,
    0.4741,
    -0.3625,
    0.273,
    -0.2438,
    0.0345,
    -0.2118,
    0.3699,
    -0.4971,
    0.3822,
    -0.3259,
    0.45,
    -0.5013,
    0.5409,
    -0.5731,
    0.0303,
    -0.0396,
    0.0196,
    -0.016,
    0.2038,
    -0.085,
    0.0783,
    -0.034,
    0.8647,
    -0.6507,
    0.8761,
    -0.4514,
    0.25,
    -0.2199,
    0.4282,
    -0.2056,
    0.4652,
    -0.1977,
    0.1944,
    -0.1651,
    0.2705,
    -0.284,
    0.0962,
    -0.087,
    0.1462,
    -0.2106,
    0.0679,
    -0.0521,
    0.0236,
    -0.3235,
    0.2372,
    -0.3577,
    0.8714,
    -0.7698,
    0.6016,
    -0.3226,
    0.6848,
    -0.3058,
    0.1304,
    -0.7945,
    0.5608,
    -0.4786,
    0.702,
    -0.3307,
    0.8481,
    -0.4874,
    0.192,
    -0.3227,
    0.5246,
    -0.3789,
    0.7857,
    -0.3702,
    0.7246,
    -0.2028,
    0.4474,
    -0.1159,
    0.1118,
    -0.1583,
    0.2756,
    -0.2237,
    0.5308,
    -0.1068,
    0.1535,
    -0.3707,
    0.0932,
    -0.095,
    0.0384,
    -0.1878,
    0.5419,
    -0.3268,
    0.2374,
    -0.4215,
    0.5777,
    -0.4049,
    0.7481,
    -0.5852,
    0.5079,
    -0.4037,
    0.4384,
    -0.3381,
    0.4941,
    -0.2485,
    0.8624,
    -0.4048,
    0.442,
    -0.1082,
    0.2921,
    -0.1381,
    0.4613,
    -0.5106,
    0.2196,
    -0.3076,
    0.373,
    -0.3378,
    0.6687,
    -0.2788,
    0.4889,
    -0.3003,
    0.7167,
    -0.4111,
    0.6881,
    -0.3676,
    0.2375,
    -0.4006,
    0.8427,
    -0.3442,
    0.6547,
    -0.3595,
    0.3088,
    -0.3478,
    0.1562,
    -0.1791,
    0.5574,
    -0.4104,
    0.6297,
    -0.5343,
    0.2152,
    -0.2911,
    0.2196,
    -0.1945,
    0.5632,
    -0.3551,
    0.8889,
    -0.3416,
    0.0622,
    -0.5032,
    0.056,
    -0.0334,
    0.0314,
    -0.0666,
    0.0992,
    -0.1062,
    0.8281,
    -0.3255,
    0.2171,
    -0.4912,
    0.4269,
    -0.4547,
    0.8119,
    -0.352,
    0.8688,
    -0.378,
    0.1287,
    -0.1526,
    0.1259,
    -0.0987,
    0.0569,
    -0.0669,
    0.0639,
    -0.0487,
    0.0576,
    -0.0362,
    0.1058,
    -0.0796,
    0.1174,
    -0.1854,
    0.3494,
    -0.5301,
    0.2694,
    -0.3707,
    0.4363,
    -0.5668,
    0.3341,
    -0.3983,
    0.4411,
    -0.4417,
    0.2412,
    -0.3282,
    0.2494,
    -0.5331,
    0.5728,
    -0.5612,
    0.5188,
    -0.4768,
    0.6416,
    -0.3322,
    0.6757,
    -0.2751,
    0.8244,
    -0.3488,
    0.1776,
    -0.3966,
    0.3612,
    -0.2164,
    0.1561,
    -0.4467,
    0.377,
    -0.3861,
    0.3271,
    -0.1039,
    0.0857,
    -0.324,
    0.1685,
    -0.3767,
    0.3215,
    -0.1749,
    0.1244,
    -0.0671,
    0.0694,
    -0.0677,
    0.0254,
    -0.0329,
    0.1139,
    -0.1429,
    0.0752,
    -0.0348,
    0.0108,
    -0.0249,
    0.0547,
    -0.0633,
    0.6759,
    -0.7519,
    0.3988,
    -0.4626,
    0.5471,
    -0.3988,
    0.7959,
    -0.3514,
    0.5739,
    -0.1766,
    0.6087,
    -0.585,
    0.5745,
    -0.5046,
    0.6324,
    -0.2545,
    0.1653,
    -0.1852,
    0.5823,
    -0.1085,
    0.3223,
    -0.8069,
    0.075,
    -0.1495,
    0.1545,
    -0.0921,
    0.0442,
    -0.0686,
    0.0297,
    -0.0635,
    0.0122,
    -0.024,
    0.1312,
    -0.2574,
    0.0971,
    -0.3686,
    0.2607,
    -0.5428,
    0.2746,
    -0.2935,
    0.16,
    -0.2906,
    0.2424,
    -0.3192,
    0.72,
    -0.4447,
    0.2794,
    -0.1023,
    0.3591,
    -0.1431,
    0.3698,
    -0.0935,
    0.0312,
    -0.0503,
    0.064,
    -0.1559,
    0.0643,
    -0.0271,
    0.3185,
    -0.1744,
    0.2514,
    -0.1947,
    0.4721,
    -0.3691,
    0.165,
    -0.4694,
    0.5107,
    -0.2924,
    0.7093,
    -0.3868,
    0.2379,
    -0.3226,
    0.3541,
    -0.4831,
    0.1281,
    -0.2342,
    0.191,
    -0.3694,
    0.0499,
    -0.0926,
    0.0376,
    -0.0231,
    0.0658,
    -0.0419,
    0.0606,
    -0.3462,
    0.3724,
    -0.3362,
    0.2879,
    -0.4445,
    0.3434,
    -0.3884,
    0.4875,
    -0.436,
    0.3753,
    -0.4256,
    0.0484,
    -0.042,
    0.1732,
    -0.0669,
    0.1359,
    -0.4129,
    0.6074,
    -0.41,
    0.3119,
    -0.1538,
    0.3227,
    -0.3452,
    0.5953,
    -0.1667,
    0.0803,
    -0.1832,
    0.333,
    -0.3807,
    0.5882,
    -0.2606,
    0.2904,
    -0.1844,
    0.1024,
    -0.2143,
    0.1282,
    -0.5804,
    0.1454,
    -0.1302,
    0.0794,
    -0.3706,
    0.361,
    -0.3897,
    0.1405,
    -0.2189,
    0.6982,
    -0.4496,
    0.3847,
    -0.4036,
    0.1946,
    -0.2327,
    0.2607,
    -0.5458,
    0.3807,
    -0.3809,
    0.6603,
    -0.4425,
    0.241,
    -0.3572,
    0.6926,
    -0.4066,
    0.5316,
    -0.5277,
    0.5109,
    -0.2128,
    0.4566,
    -0.6331,
    0.1339,
    -0.3705,
    0.0588,
    -0.0761,
    0.0738,
    -0.043,
    0.1307,
    -0.0599,
    0.3771,
    -0.3775,
    0.1725,
    -0.1198,
    0.2978,
    -0.4466,
    0.1407,
    -0.3613,
    0.2033,
    -0.0648,
    0.6586,
    -0.3195,
    0.3253,
    -0.4791,
    0.26,
    -0.2791,
    0.0869,
    -0.0265,
    0.0277,
    -0.0399,
    0.0743,
    -0.0207,
    0.4984,
    -0.3896,
    0.2417,
    -0.2954,
    0.2482,
    -0.702,
    0.1602,
    -0.1253,
    0.6378,
    -0.3659,
    0.773,
    -0.4137,
    0.7569,
    -0.4703,
    0.6939,
    -0.2418,
    0.7192,
    -0.4069,
    0.5902,
    -0.4021,
    0.6291,
    -0.0543,
    0.7798,
    -0.5596,
    0.5614,
    -0.3393,
    0.3809,
    -0.5072,
    0.0657,
    -0.09,
    0.1284,
    -0.0725,
    0.2613,
    -0.2058,
    0.7403,
    -0.3112,
    0.1758,
    -0.3218,
    0.2499,
    -0.7224,
    0.4998,
    -0.6477,
    0.4375,
    -0.4562,
    0.1301,
    -0.3795,
    0.3985,
    -0.3136,
    0.3978,
    -0.1364,
    0.3922,
    -0.3575,
    0.3702,
    -0.445,
    0.4347,
    -0.1257,
    0.6867,
    -0.4396,
    0.8518,
    -0.4561,
    0.3163,
    -0.4348,
    0.3265,
    -0.2832,
    0.2128,
    -0.2454,
    0.0742,
    -0.086,
    0.0694,
    -0.0563,
    0.0827,
    -0.1997,
    0.6379,
    -0.5368,
    0.3451,
    -0.2472,
    0.4887,
    -0.6355,
    0.2981,
    -0.6129,
    0.4205,
    -0.7028,
    0.7834,
    -0.2111,
    0.3281,
    -0.2034,
    0.7372,
    -0.3188,
    0.3966,
    -0.3588,
    0.1404,
    -0.113,
    0.0976,
    -0.016,
    0.0299,
    -0.0244,
    0.1063,
    -0.1252,
    0.0774,
    -0.0924,
    0.7654,
    -0.2527,
    0.6889,
    -0.2304,
    0.5223,
    -0.383,
    0.2533,
    -0.3523,
    0.7013,
    -0.5739,
    0.0574,
    -0.4026,
    0.775,
    -0.4575,
    0.589,
    -0.3397,
    0.106,
    -0.3228,
    0.6036,
    -0.3726,
    0.3953,
    -0.3656,
    0.2455,
    -0.2972,
    0.331,
    -0.0742,
    0.4309,
    -0.2923,
    0.2235,
    -0.416,
    0.3466,
    -0.1233,
    0.4839,
    -0.6187,
    0.4041,
    -0.21,
    0.6236,
    -0.3257,
    0.4969,
    -0.4855,
    0.2578,
    -0.164,
    0.1936,
    -0.48,
    0.249,
    -0.4382,
    0.0733,
    -0.2592,
    0.5445,
    -0.5724,
    0.4188,
    -0.3219,
    0.2972,
    -0.4061,
    0.2027,
    -0.2869,
    0.0861,
    -0.0595,
    0.0996,
    -0.0533,
    0.0547,
    -0.0683,
    0.2547,
    -0.3249,
    0.5487,
    -0.4473,
    0.6097,
    -0.4785,
    0.5648,
    -0.3254,
    0.3462,
    -0.3209,
    0.6314,
    -0.6126,
    0.4822,
    -0.531,
    0.3031,
    -0.1682,
    0.6792,
    -0.4181,
    0.4321,
    -0.4392,
    0.0985,
    -0.2916,
    0.5382,
    -0.1363,
    0.6817,
    -0.3719,
    0.7156,
    -0.3362,
    0.3991,
    -0.3596,
    0.7352,
    -0.5716,
    0.6318,
    -0.1822,
    0.8328,
    -0.2918,
    0.8747,
    -0.1924,
    0.0438,
    -0.7583,
    0.3702,
    -0.8405,
    0.2761,
    -0.166,
    0.2631,
    -0.2597,
    0.0506,
    -0.074,
    0.055,
    -0.0299,
    0.0178,
    -0.0203,
    0.0288,
    -0.0845,
    0.1197,
    -0.6239,
    0.1828,
    -0.3033,
    0.4115,
    -0.5242,
    0.7366,
    -0.4912,
    0.0886,
    -0.1225,
    0.5566,
    -0.4299,
    0.4105,
    -0.4619,
    0.2662,
    -0.0463,
    0.6022,
    -0.5013,
    0.4773,
    -0.5528,
    0.5533,
    -0.3491,
    0.3703,
    -0.2776,
    0.2303,
    -0.3608,
    0.2959,
    -0.3955,
    0.6742,
    -0.3608,
    0.4374,
    -0.2447,
    0.3018,
    -0.1726,
    0.692,
    -0.2453,
    0.5697,
    -0.5628,
    0.2193,
    -0.4577,
    0.5698,
    -0.267,
    0.3947,
    -0.2017,
    0.443,
    -0.3206,
    0.2183,
    -0.4685,
    0.3625,
    -0.244,
    0.4637,
    -0.4606,
    0.2954,
    -0.2593,
    0.4277,
    -0.2207,
    0.5992,
    -0.3493,
    0.4313,
    -0.3507,
    0.2135,
    -0.0565,
    0.0888,
    -0.0427,
    0.0727,
    -0.1836,
    0.6947,
    -0.5396,
    0.2292,
    -0.1826,
    0.6571,
    -0.4131,
    0.1884,
    -0.1701,
    0.5856,
    -0.4515,
    0.7283,
    -0.284,
    0.1323,
    -0.1948,
    0.0085,
    -0.0113,
    0.0568,
    -0.0539,
    0.0191,
    -0.0509,
    0.0332,
    -0.0262,
    0.1034,
    -0.0501,
    0.1549,
    -0.6361,
    0.7628,
    -0.5271,
    0.4518,
    -0.4073,
    0.2468,
    -0.5068,
    0.4576,
    -0.729,
    0.5897,
    -0.1726,
    0.1188,
    -0.1518,
    0.5799,
    -0.4148,
    0.1164,
    -0.4696,
    0.0976,
    -0.1516,
    0.5093,
    -0.4047,
    0.5059,
    -0.4448,
    0.0689,
    -0.3631,
    0.0488,
    -0.0424,
    0.1028,
    -0.1615,
    0.4543,
    -0.3284,
    0.6778,
    -0.3012,
    0.2495,
    -0.3097,
    0.6807,
    -0.3146,
    0.8769,
    -0.4217,
    0.2911,
    -0.5526,
    0.6608,
    -0.2042,
    0.1918,
    -0.0707,
    0.0197,
    -0.006,
    0.6445,
    -0.2141,
    0.3168,
    -0.208,
    0.182,
    -0.5154,
    0.5919,
    -0.4084,
    0.3698,
    -0.3503,
    0.351,
    -0.5299,
    0.092,
    -0.104,
    0.6463,
    -0.4135,
    0.5082,
    -0.3393,
    0.3464,
    -0.1769,
    0.3055,
    -0.5515,
    0.1212,
    -0.3378,
    0.7559,
    -0.4246,
    0.4426,
    -0.3366,
    0.4615,
    -0.1985,
    0.4862,
    -0.2357,
    0.1579,
    -0.4386,
    0.6525,
    -0.3988,
    0.5519,
    -0.3043,
    0.3941,
    -0.1029,
    0.3208,
    -0.3865,
    0.294,
    -0.1891,
    0.8253,
    -0.5505,
    0.652,
    -0.2706,
    0.3411,
    -0.7049,
    0.7195,
    -0.4563,
    0.4524,
    -0.44,
    0.175,
    -0.3189,
    0.4983,
    -0.328,
    0.1434,
    -0.1335,
    0.0625,
    -0.0245,
    0.0796,
    -0.0422,
    0.0441,
    -0.0869,
    0.4013,
    -0.4701,
    0.527,
    -0.5483,
    0.747,
    -0.4329,
    0.3668,
    -0.4,
    0.583,
    -0.532,
    0.5751,
    -0.5829,
    0.1877,
    -0.3131,
    0.4644,
    -0.5474,
    0.6122,
    -0.3436,
    0.5213,
    -0.3596,
    0.1809,
    -0.2535,
    0.0456,
    -0.0651,
    0.0265,
    -0.0606,
    0.3113,
    -0.4879,
    0.4267,
    -0.2367,
    0.6789,
    -0.3461,
    0.8209,
    -0.5216,
    0.5391,
    -0.3527,
    0.2914,
    -0.4804,
    0.1493,
    -0.458,
    0.4518,
    -0.2717,
    0.0632,
    -0.2937,
    0.5899,
    -0.2631,
    0.8462,
    -0.3885,
    0.6776,
    -0.4042,
    0.0161,
    -0.3197,
    0.3141,
    -0.2133,
    0.4658,
    -0.1739,
    0.1006,
    -0.0278,
    0.0635,
    -0.0449,
    0.0242,
    -0.0394,
    0.0513,
    -0.0293,
    0.0802,
    -0.038,
    0.0445,
    -0.0386,
    0.0318,
    -0.0903,
    0.0361,
    -0.076,
    0.1081,
    -0.086,
    0.0998,
    -0.1673,
    0.3558,
    -0.4825,
    0.4888,
    -0.4039,
    0.8268,
    -0.118,
    0.5687,
    -0.4547,
    0.4132,
    -0.2611,
    0.3626,
    -0.4298,
    0.1718,
    -0.2093,
    0.4109,
    -0.1824,
    0.4541,
    -0.4717,
    0.4155,
    -0.6461,
    0.3445,
    -0.0343,
    0.3916,
    -0.5578,
    0.2118,
    -0.5034,
    0.2074,
    -0.1941,
    0.233,
    -0.233,
    0.1875,
    -0.0429,
    0.3856,
    -0.3439,
    0.1401,
    -0.1886,
    0.0335,
    -0.0575,
    0.3041,
    -0.3585,
    0.1035,
    -0.1891,
    0.461,
    -0.2197,
    0.1617,
    -0.4993,
    0.1494,
    -0.2986,
    0.1637,
    -0.2214,
    0.2794,
    -0.4772,
    0.3244,
    -0.4951,
    0.1952,
    -0.0886,
    0.1675,
    -0.0944,
    0.06,
    -0.1912,
    0.2496,
    -0.2723,
    0.2098,
    -0.0884,
    0.23,
    -0.2475,
    0.0682,
    -0.0509,
    0.3917,
    -0.5273,
    0.6214,
    -0.5129,
    0.3711,
    -0.4311,
    0.1495,
    -0.5413,
    0.1402,
    -0.0906,
    0.4191,
    -0.3527,
    0.1074,
    -0.0772,
    0.4603,
    -0.448,
    0.6316,
    -0.6526,
    0.1313,
    -0.1819,
    0.1111,
    -0.1557,
    0.1422,
    -0.3154,
    0.1485,
    -0.0534,
    0.0496,
    -0.1333,
    0.0657,
    -0.0602,
    0.0439,
    -0.0683,
    0.0734,
    -0.0587,
    0.0705,
    -0.0482,
    0.1717,
    -0.0996,
    0.2311,
    -0.5343,
    0.4882,
    -0.5815,
    0.3568,
    -0.43,
    0.683,
    -0.1041,
    0.4219,
    -0.2521,
    0.5923,
    -0.4813,
    0.3736,
    -0.4928,
    0.1599,
    -0.3261,
    0.0669,
    -0.4623,
    0.062,
    -0.2643,
    0.2479,
    -0.0611,
    0.3203,
    -0.7048,
    0.2516,
    -0.2931,
    0.172,
    -0.2849,
    0.3732,
    -0.4113,
    0.2485,
    -0.5201,
    0.238,
    -0.265,
    0.2223,
    -0.3454,
    0.1942,
    -0.4285,
    0.0624,
    -0.1153,
    0.2255,
    -0.0945,
    0.1302,
    -0.145,
    0.2193,
    -0.6085,
    0.2852,
    -0.2738,
    0.0602,
    -0.1173,
    0.0784,
    -0.0399,
    0.071,
    -0.0435,
    0.0727,
    -0.0595,
    0.0645,
    -0.0722,
    0.1236,
    -0.1798,
    0.5003,
    -0.6094,
    0.2329,
    -0.5505,
    0.3815,
    -0.394,
    0.4261,
    -0.5711,
    0.4042,
    -0.6561,
    0.2321,
    -0.035,
    0.3964,
    -0.6132,
    0.4024,
    -0.6373,
    0.0502,
    -0.2499,
    0.0572,
    -0.0955,
    0.3752,
    -0.4885,
    0.3594,
    -0.3263,
    0.4554,
    -0.4125,
    0.4667,
    -0.5215,
    0.2784,
    -0.1531,
    0.1823,
    -0.1144,
    0.0494,
    -0.0986,
    0.1288,
    -0.0858,
    0.1097,
    -0.1252,
    0.1235,
    -0.1198,
    0.1194,
    -0.1143,
    0.2157,
    -0.3229,
    0.046,
    -0.0479,
    0.0905,
    -0.1154,
    0.5278,
    -0.189,
    0.4204,
    -0.6615,
    0.2519,
    -0.0471,
    0.1923,
    -0.3938,
    0.1324,
    -0.2848,
    0.2062,
    -0.1212,
    0.0348,
    -0.0512,
    0.0791,
    -0.1125,
    0.3137,
    -0.236,
    0.1227,
    -0.2807,
    0.3127,
    -0.5562,
    0.5893,
    -0.5402,
    0.245,
    -0.3417,
    0.0632,
    -0.4695,
    0.2501,
    -0.1481,
    0.184,
    -0.1336,
    0.1678,
    -0.0525,
    0.1649,
    -0.0945,
    0.0904,
    -0.0305,
    0.0548,
    -0.0094,
    0.0899,
    -0.0824,
    0.583,
    -0.6887,
    0.3228,
    -0.6805,
    0.4125,
    -0.4956,
    0.4265,
    -0.4802,
    0.4602,
    -0.5241,
    0.5046,
    -0.3347,
    0.5179,
    -0.3797,
    0.2774,
    -0.2585,
    0.3446,
    -0.305,
    0.5358,
    -0.4853,
    0.4365,
    -0.3726,
    0.4961,
    -0.3326,
    0.5269,
    -0.5083,
    0.2035,
    -0.1597,
    0.1471,
    -0.0666,
    0.1135,
    -0.0632,
    0.0719,
    -0.0666,
    0.0901,
    -0.0305,
    0.1061,
    -0.1547,
    0.2317,
    -0.1629,
    0.1638,
    -0.402,
    0.2494,
    -0.0955,
    0.1553,
    -0.0647,
    0.1089,
    -0.127,
    0.0532,
    -0.0536,
    0.0631,
    -0.0598,
    0.286,
    -0.1027,
    0.3198,
    -0.6634,
    0.0816,
    -0.1003,
    0.0416,
    -0.0612,
    0.5464,
    -0.4311,
    0.2827,
    -0.4438,
    0.0956,
    -0.0561,
    0.0523,
    -0.0661,
    0.0334,
    -0.0571,
    0.313,
    -0.1981,
    0.0993,
    -0.2989,
    0.248,
    -0.1948,
    0.0488,
    -0.0896,
    0.1788,
    -0.448,
    0.376,
    -0.638,
    0.3262,
    -0.3471,
    0.1753,
    -0.2112,
    0.2882,
    -0.3073,
    0.2798,
    -0.2929,
    0.0868,
    -0.3223,
    0.2689,
    -0.3667,
    0.206,
    -0.0856,
    0.051,
    -0.0186,
    0.7871,
    -0.0351,
    0.2823,
    -0.2646,
    0.262,
    -0.2886,
    0.165,
    -0.1135,
    0.4548,
    -0.1227,
    0.6986,
    -0.2043,
    0.0573,
    -0.1296,
    0.0298,
    -0.0155,
    0.015,
    -0.0759,
    0.0447,
    -0.1241,
    0.7437,
    -0.0491,
    0.5506,
    -0.5637,
    0.3363,
    -0.4762,
    0.4423,
    -0.0699,
    0.0245,
    -0.0529,
    0.0588,
    -0.0422,
    0.0893,
    -0.049,
    0.0343,
    -0.0305,
    0.1998,
    -0.4527,
    0.1515,
    -0.1117,
    0.7354,
    -0.2131,
    0.4226,
    -0.572,
    0.1289,
    -0.6172,
    0.0443,
    -0.4165,
    0.1454,
    -0.2664,
    0.2685,
    -0.3662,
    0.2244,
    -0.4448,
    0.0716,
    -0.1582,
    0.0594,
    -0.0736,
    0.0421,
    -0.0567,
    0.0628,
    -0.2339,
    0.3007,
    -0.2432,
    0.0908,
    -0.2734,
    0.0347,
    -0.3147,
    0.1396,
    -0.129,
    0.2141,
    -0.3899,
    0.2948,
    -0.1574,
    0.2495,
    -0.4441,
    0.097,
    -0.0851,
    0.1929,
    -0.3902,
    0.0224,
    -0.4802,
    0.072,
    -0.1165,
    0.5473,
    -0.6226,
    0.5908,
    -0.7968,
    0.5356,
    -0.3956,
    0.6441,
    -0.6785,
    0.1495,
    -0.3036,
    0.5752,
    -0.4945,
    0.4625,
    -0.3304,
    0.1509,
    -0.3798,
    0.435,
    -0.5056,
    0.3233,
    -0.5609,
    0.1155,
    -0.3631,
    0.2276,
    -0.4641,
    0.1512,
    -0.3259,
    0.3404,
    -0.1654,
    0.4482,
    -0.4003,
    0.2791,
    -0.1447,
    0.3516,
    -0.3391,
    0.1149,
    -0.1387,
    0.1047,
    -0.076,
    0.0439,
    -0.0549,
    0.4639,
    -0.2176,
    0.4339,
    -0.3571,
    0.5427,
    -0.2769,
    0.3123,
    -0.4009,
    0.139,
    -0.3653,
    0.161,
    -0.7269,
    0.5659,
    -0.7061,
    0.348,
    -0.5687,
    0.4162,
    -0.5279,
    0.2267,
    -0.2077,
    0.2564,
    -0.5161,
    0.4736,
    -0.3816,
    0.2116,
    -0.3225,
    0.1399,
    -0.1896,
    0.068,
    -0.0848,
    0.1182,
    -0.1083,
    0.2684,
    -0.0726,
    0.2631,
    -0.454,
    0.2067,
    -0.4916,
    0.2812,
    -0.225,
    -0.1051,
    -0.3953,
    0.2374,
    -0.2524,
    0.2689,
    -0.3123,
    0.1727,
    -0.0844,
    0.133,
    -0.0925,
    0.0825,
    -0.134,
    0.0549,
    -0.0245,
    0.0499,
    -0.0217,
    0.1174,
    -0.0423,
    0.1596,
    -0.1606,
    0.1911,
    -0.3081,
    0.7051,
    -0.3348,
    0.4755,
    -0.1563,
    0.4867,
    -0.6494,
    0.2103,
    -0.5425,
    0.0614,
    -0.1549,
    0.133,
    -0.1778,
    0.0578,
    -0.0602,
    0.0646,
    -0.0665,
    0.0633,
    -0.0798,
    0.2544,
    -0.1677,
    0.174,
    -0.2374,
    0.4049,
    -0.4152,
    0.1442,
    -0.0945,
    0.3948,
    -0.6862,
    0.3673,
    -0.2232,
    0.2318,
    -0.174,
    0.3881,
    -0.0968,
    0.4296,
    -0.6165,
    0.2661,
    -0.2628,
    0.064,
    -0.4092,
    0.3364,
    -0.3359,
    0.3598,
    -0.1504,
    0.1451,
    0.0082,
    0.2699,
    -0.1309,
    0.3325,
    -0.2814,
    0.313,
    -0.6253,
    0.2742,
    -0.1616,
    0.1996,
    -0.5293,
    0.2357,
    -0.4048,
    0.0622,
    -0.2083,
    0.0882,
    -0.086,
    0.0229,
    -0.0582,
    0.0301,
    -0.3849,
    0.2726,
    -0.4127,
    0.4139,
    -0.5884,
    0.4931,
    -0.4163,
    0.4252,
    -0.124,
    0.2737,
    -0.4265,
    0.445,
    -0.4656,
    0.1453,
    -0.3591,
    0.0518,
    -0.0633,
    0.4386,
    -0.3197,
    0.3583,
    -0.7113,
    0.2772,
    -0.3125,
    0.2546,
    -0.147,
    0.2601,
    -0.4853,
    0.0998,
    -0.425,
    0.039,
    -0.1362,
    0.0708,
    -0.0373,
    0.0575,
    -0.105,
    0.0774,
    -0.0298,
    0.0944,
    -0.0714,
    0.0737,
    -0.087,
    0.074,
    -0.0841,
    0.6367,
    -0.4967,
    0.2179,
    -0.2741,
    0.1772,
    -0.1382,
    0.5091,
    -0.2689,
    0.2767,
    -0.6805,
    0.2101,
    -0.4693,
    0.4732,
    -0.2977,
    0.4003,
    -0.6012,
    0.451,
    -0.7067,
    0.6734,
    -0.4738,
    0.5321,
    -0.6472,
    0.2974,
    -0.3513,
    0.2383,
    -0.2583,
    0.0615,
    -0.0419,
    0.0771,
    -0.0688,
    0.3304,
    -0.227,
    0.2988,
    -0.7552,
    0.5274,
    -0.3313,
    0.426,
    -0.4315,
    0.3667,
    -0.0977,
    0.2511,
    -0.2914,
    0.3053,
    -0.3473,
    0.3387,
    -0.3149,
    0.3814,
    -0.2642,
    0.401,
    -0.2588,
    0.1989,
    -0.1882,
    0.0311,
    -0.0509,
    0.3019,
    -0.3439,
    0.2958,
    -0.3125,
    0.2579,
    -0.1412,
    0.0616,
    -0.0681,
    0.2857,
    -0.0493,
    0.512,
    -0.6264,
    0.3416,
    -0.426,
    0.4831,
    -0.509,
    0.4063,
    -0.487,
    0.4205,
    -0.4752,
    0.3395,
    -0.2752,
    0.2117,
    -0.1462,
    0.4552,
    -0.1371,
    0.2503,
    -0.411,
    0.3794,
    -0.2344,
    0.1643,
    -0.3368,
    0.3552,
    -0.078,
    0.1606,
    -0.2703,
    0.2035,
    -0.1582,
    0.3712,
    -0.5715,
    0.5421,
    -0.6876,
    0.3833,
    -0.4786,
    0.1861,
    -0.3428,
    0.6153,
    -0.4788,
    0.5765,
    -0.3276,
    0.2984,
    -0.3633,
    0.088,
    -0.4088,
    0.0781,
    -0.0373,
    0.3656,
    -0.2391,
    0.293,
    -0.2262,
    0.3048,
    -0.5001,
    0.0854,
    -0.3917,
    0.2676,
    -0.203,
    0.1743,
    -0.2807,
    0.1506,
    -0.1225,
    0.0578,
    -0.0202,
    0.0576,
    -0.0332,
    0.0662,
    -0.1155,
    0.1883,
    -0.1064,
    0.3821,
    -0.2868,
    0.1908,
    -0.1411,
    0.0591,
    -0.0646,
    0.0578,
    -0.0147,
    0.0505,
    -0.1194,
    0.0712,
    -0.0226,
    0.0235,
    -0.0181,
    0.1009,
    -0.0216,
    0.3573,
    -0.11,
    0.3858,
    -0.44,
    0.5378,
    -0.5113,
    0.5826,
    -0.5646,
    0.0413,
    -0.0292,
    0.0196,
    -0.0191,
    0.0259,
    -0.0257,
    0.0406,
    -0.0224,
    0.0566,
    -0.0335,
    0.0219,
    -0.0134,
    0.01,
    -0.0123,
    0.0158,
    -0.0117,
    0.0558,
    -0.0791,
    0.0351,
    -0.0421,
    0.0248,
    -0.0367,
    0.0441,
    -0.0353,
    0.0522,
    -0.0464,
    0.0273,
    -0.0188,
    0.0084,
    -0.0116,
    0.0373,
    -0.0401,
    0.0089,
    -0.0125,
    0.0374,
    -0.0022,
    0.0276,
    -0.0219,
    0.0074,
    -0.0165,
    0.0086,
    -0.0038,
    0.0022,
    -0.0021,
    0.0017,
    -0.0008,
    0.0005,
    -0.0004,
    0.0003,
    -0.0004,
    0.0001,
    -0.0001,
    0.0001,
    -0.0001,
    0,
    -0.0001,
    0,
    0,
    0,
    0,
    0.0001,
    0,
    0,
    0
];

export default chapterSeventhPCM;