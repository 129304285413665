import styled from 'styled-components';

export const Error404Wrap = styled.div`
  background-color: #F7F7F7;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
`;

export const ErrorInfo = styled.div`
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    display: block;
    margin: 0 auto;
    max-width: 375px;
    img{
      max-width: 375px;
      width: 100%;
    }
    a{
      position: absolute;
      bottom: 0;
      text-align: center;
    }
`;