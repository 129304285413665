import { createGlobalStyle, css } from 'styled-components';
import { fontFamily } from '../theme/theme';

export default createGlobalStyle(() => {
    return css`
    * {
      box-sizing: border-box;
    }

    html, body {
      background-color: #ffffff;
    }

    html{
      font-size: 20px;
    }

    body {
      margin: 0;
      font-family: ${fontFamily.base};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -ms-overflow-style: none;
    }

    button,
    a {
      font-family: ${fontFamily.base};
      cursor: pointer;
      text-decoration: none;
      highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    img,
    svg {
      max-width: 100%;
      vertical-align: top;
    }

    hr{
      margin: 0;
      height: 1px;
      border: none;
      background-color: #CCCCCC;
      width: 100%;
    }

    .app {
      position: relative;
      overflow: hidden;
    }
  `;
});