const chapterSecondPCM = [
    0.0174,
    -0.0069,
    0.0339,
    -0.0156,
    0.0353,
    -0.0302,
    0.089,
    -0.0769,
    0.0579,
    -0.0813,
    0.0773,
    -0.0959,
    0.0895,
    -0.0913,
    0.0889,
    -0.0913,
    0.3244,
    -0.4062,
    0.1752,
    -0.2249,
    0.3457,
    -0.2536,
    0.6159,
    -0.5455,
    0.7411,
    -0.3292,
    0.496,
    -0.5959,
    0.6127,
    -0.3887,
    0.4083,
    -0.3139,
    0.5503,
    -0.2795,
    0.2985,
    -0.5175,
    0.7158,
    -0.3023,
    0.1244,
    -0.2034,
    0.4482,
    -0.4136,
    0.3999,
    -0.2091,
    0.5624,
    -0.5625,
    0.1116,
    -0.4127,
    0.9202,
    -0.6505,
    0.2864,
    -0.4779,
    0.7779,
    -0.3117,
    0.4763,
    -0.5478,
    0.2231,
    -0.3546,
    0.514,
    -0.3036,
    0.2512,
    -0.2577,
    0.0749,
    -0.1347,
    0.0825,
    -0.1044,
    0.2426,
    -0.1265,
    0.155,
    -0.1255,
    0.1144,
    -0.0859,
    0.2874,
    -0.1245,
    0.3549,
    -0.2046,
    0.4246,
    -0.1252,
    0.3981,
    -0.8876,
    0.0687,
    -0.471,
    0.6672,
    -0.401,
    0.5454,
    -0.6623,
    0.78,
    -0.5309,
    0.2901,
    -0.421,
    0.0836,
    -0.1208,
    0.507,
    -0.1349,
    0.7186,
    -0.6691,
    0.2581,
    -0.2532,
    0.5691,
    -0.4139,
    0.3912,
    -0.5225,
    0.2705,
    -0.503,
    0.6551,
    -0.4816,
    0.3922,
    -0.6176,
    0.542,
    -0.7383,
    0.2122,
    -0.5488,
    0.5493,
    -0.609,
    0.2573,
    -0.4648,
    0.1071,
    -0.3424,
    0.0889,
    -0.2222,
    0.1709,
    -0.1794,
    0.0525,
    -0.0281,
    0.0476,
    -0.0726,
    0.2604,
    -0.3479,
    0.6078,
    -0.3875,
    0.3322,
    -0.5394,
    0.4515,
    -0.7188,
    0.5116,
    -0.2921,
    0.3428,
    -0.1732,
    0.3089,
    -0.3312,
    0.0298,
    -0.3264,
    0.077,
    -0.0601,
    0.0831,
    -0.0448,
    0.0594,
    -0.0444,
    0.0781,
    -0.0258,
    0.0439,
    -0.1068,
    0.4009,
    -0.4638,
    0.521,
    -0.6413,
    0.3648,
    -0.1759,
    0.1372,
    -0.2312,
    0.715,
    -0.4457,
    0.2135,
    -0.1403,
    0.2585,
    -0.2133,
    0.5238,
    -0.5513,
    0.2199,
    -0.5984,
    0.4028,
    -0.4952,
    0.5929,
    -0.2749,
    0.3565,
    -0.1405,
    0.6281,
    -0.4498,
    0.6159,
    -0.1878,
    0.0952,
    -0.3333,
    0.2401,
    -0.214,
    0.2311,
    -0.3368,
    0.1331,
    -0.4956,
    0.4787,
    -0.3582,
    0.2912,
    -0.4278,
    0.0462,
    -0.3151,
    0.3309,
    -0.1499,
    0.0558,
    -0.0833,
    0.0638,
    -0.0396,
    0.1009,
    -0.076,
    0.0379,
    -0.077,
    0.0757,
    -0.0866,
    0.1008,
    -0.0999,
    0.0792,
    -0.0888,
    0.2365,
    -0.2504,
    0.8263,
    -0.3664,
    0.4637,
    -0.2409,
    0.7911,
    -0.5387,
    0.6491,
    -0.5993,
    0.3649,
    -0.5193,
    0.2426,
    -0.1912,
    0.2279,
    -0.3362,
    0.4717,
    -0.2936,
    0.7343,
    -0.247,
    0.7505,
    -0.6553,
    0.1168,
    -0.1567,
    0.027,
    -0.3071,
    0.4539,
    -0.3653,
    0.4277,
    -0.3799,
    0.7562,
    -0.2003,
    0.6137,
    -0.4446,
    0.2887,
    -0.4434,
    0.275,
    -0.2898,
    0.2802,
    -0.2889,
    0.3938,
    -0.269,
    0.0768,
    -0.119,
    0.0387,
    -0.0546,
    0.5117,
    -0.3964,
    0.2206,
    -0.3822,
    0.7324,
    -0.1879,
    0.1696,
    -0.4315,
    0.4803,
    -0.4724,
    0.5644,
    -0.5145,
    0.4007,
    -0.3359,
    0.1266,
    -0.2682,
    0.4699,
    -0.4212,
    0.6008,
    -0.3632,
    0.132,
    -0.1343,
    0.8025,
    -0.2966,
    0.3885,
    -0.4628,
    0.6355,
    -0.0931,
    0.1894,
    -0.1757,
    0.5315,
    -0.5798,
    0.7513,
    -0.5222,
    0.3852,
    -0.5359,
    0.6591,
    -0.5252,
    0.4691,
    -0.3473,
    0.3274,
    -0.1689,
    0.1746,
    -0.1809,
    0.1807,
    -0.0923,
    0.0423,
    -0.0659,
    0.0416,
    -0.0976,
    0.2159,
    -0.1153,
    0.2091,
    -0.1058,
    0.8377,
    -0.3281,
    0.8207,
    -0.1401,
    0.8671,
    -0.1438,
    0.2392,
    -0.3601,
    0.3733,
    -0.587,
    0.7103,
    -0.3837,
    0.803,
    -0.1686,
    0.2542,
    -0.4414,
    0.4726,
    -0.53,
    0.1465,
    -0.2521,
    0.3687,
    -0.1612,
    0.2754,
    -0.163,
    0.1029,
    -0.0569,
    0.096,
    -0.0882,
    0.064,
    -0.1114,
    0.0248,
    -0.3805,
    0.438,
    -0.3189,
    0.3805,
    -0.1046,
    0.3293,
    -0.4675,
    0.202,
    -0.4403,
    0.2249,
    -0.4722,
    0.2185,
    -0.5042,
    0.2186,
    -0.5259,
    0.2218,
    -0.301,
    0.2766,
    -0.2391,
    0.4573,
    -0.4691,
    0.4011,
    -0.35,
    0.7304,
    -0.2473,
    0.7966,
    -0.2349,
    0.2608,
    -0.2509,
    0.663,
    -0.2002,
    0.8693,
    -0.3718,
    0.2098,
    -0.5024,
    0.1489,
    -0.2206,
    0.0744,
    -0.0378,
    0.0462,
    -0.0221,
    0.0386,
    -0.0194,
    0.2195,
    -0.0341,
    0.7263,
    -0.3477,
    0.6708,
    -0.647,
    0.6703,
    -0.5262,
    0.6409,
    -0.2152,
    0.3935,
    -0.3527,
    0.6977,
    -0.357,
    0.3629,
    -0.3732,
    0.4513,
    -0.2801,
    0.4695,
    -0.2841,
    0.4631,
    -0.2743,
    0.661,
    -0.4053,
    0.1044,
    -0.2684,
    0.6427,
    -0.3382,
    0.2299,
    -0.1425,
    0.3224,
    -0.3958,
    0.0701,
    -0.2784,
    0.0495,
    -0.0651,
    0.0706,
    -0.1014,
    0.0987,
    -0.5032,
    0.2333,
    -0.329,
    0.6038,
    -0.5936,
    0.1235,
    -0.2957,
    0.4159,
    -0.2228,
    0.5547,
    -0.4166,
    0.6192,
    -0.4777,
    0.3772,
    -0.1953,
    0.3291,
    -0.0484,
    0.2723,
    -0.304,
    0.0529,
    -0.4996,
    0.132,
    -0.1149,
    0.4279,
    -0.3228,
    0.427,
    -0.4416,
    0.1122,
    -0.1144,
    0.0108,
    -0.209,
    0.0516,
    -0.0194,
    0.0019,
    -0.0311,
    0,
    -0.0472,
    0.0262,
    -0.0413,
    0.3956,
    -0.1101,
    0.3772,
    -0.432,
    0.2013,
    -0.4966,
    0.7208,
    -0.5575,
    0.2379,
    -0.0964,
    0.5357,
    -0.7774,
    0.3276,
    -0.3645,
    0.1737,
    -0.3258,
    0.4528,
    -0.155,
    0.1302,
    -0.2851,
    0.3345,
    -0.4331,
    0.1869,
    -0.4351,
    0.502,
    -0.2285,
    0.6651,
    -0.3188,
    0.7522,
    -0.3937,
    0.2608,
    -0.3626,
    0.1366,
    -0.1619,
    0.5166,
    -0.3484,
    0.3634,
    -0.2994,
    0.1131,
    -0.3082,
    0.1087,
    -0.1203,
    0.0951,
    -0.0396,
    0.0655,
    -0.2995,
    0.8515,
    -0.3704,
    0.3848,
    -0.4567,
    0.732,
    -0.2267,
    0.4831,
    -0.3237,
    0.2182,
    -0.3848,
    0.269,
    -0.4142,
    0.0896,
    -0.0566,
    0.0659,
    -0.0764,
    0.178,
    -0.1681,
    0.1768,
    -0.4815,
    0.5465,
    -0.3862,
    -0.015,
    -0.3448,
    0.2726,
    -0.3688,
    0.7078,
    -0.2778,
    0.0476,
    -0.2112,
    0.4259,
    -0.2472,
    0.4067,
    -0.2657,
    0.1738,
    -0.0985,
    0.1839,
    -0.0327,
    0.2282,
    -0.1367,
    0.0358,
    -0.1005,
    0.1602,
    -0.0648,
    0.132,
    -0.372,
    0.8448,
    -0.5008,
    0.5719,
    -0.4466,
    0.0708,
    -0.1302,
    0.3477,
    -0.4492,
    0.2284,
    -0.249,
    0.0874,
    -0.2651,
    0.0692,
    -0.0772,
    0.0331,
    -0.0638,
    -0.0029,
    -0.0379,
    0.2455,
    -0.1171,
    0.6586,
    -0.6612,
    0.7491,
    -0.222,
    0.1488,
    -0.4223,
    0.6804,
    -0.4179,
    0.7593,
    -0.5079,
    0.8117,
    -0.3222,
    0.6596,
    -0.4062,
    0.2538,
    -0.4286,
    0.1752,
    -0.4355,
    0.6244,
    -0.4723,
    0.5867,
    -0.4857,
    0.0965,
    -0.3767,
    0.2487,
    -0.2004,
    0.1547,
    -0.2874,
    0.203,
    -0.4337,
    0.425,
    -0.2403,
    0.1807,
    -0.569,
    0.4933,
    -0.1785,
    -0.0065,
    -0.1932,
    0.0633,
    -0.0713,
    0.0957,
    -0.1923,
    0.3231,
    -0.0604,
    0.3833,
    -0.3482,
    0.3332,
    -0.2508,
    0.0698,
    -0.2876,
    0.6147,
    -0.3079,
    0.5149,
    -0.2199,
    0.1203,
    -0.1661,
    0.0265,
    -0.0556,
    0.0386,
    -0.0352,
    0.0667,
    -0.3407,
    0.6107,
    -0.3462,
    0.2949,
    -0.2434,
    0.4663,
    -0.3012,
    0.3944,
    -0.3945,
    0.2558,
    -0.2215,
    0.1935,
    -0.1966,
    0.1973,
    -0.2108,
    0.1793,
    -0.1033,
    0.1749,
    -0.1163,
    0.0436,
    -0.0712,
    0.0306,
    -0.0665,
    0.0934,
    -0.0406,
    0.0505,
    -0.0372,
    0.0682,
    -0.0266,
    0.1279,
    -0.1625,
    0.0768,
    -0.1432,
    0.7898,
    -0.6846,
    0.177,
    -0.3511,
    0.4632,
    -0.7925,
    0.6234,
    -0.8146,
    0.5589,
    -0.0153,
    0.4427,
    -0.263,
    0.166,
    -0.2819,
    0.1574,
    -0.5631,
    0.1657,
    -0.5196,
    0.2073,
    -0.1496,
    0.4417,
    -0.141,
    0.4294,
    -0.3214,
    0.3717,
    -0.1461,
    0.144,
    -0.0873,
    0.366,
    -0.5564,
    0.4427,
    -0.5626,
    0.2456,
    -0.2578,
    0.0757,
    -0.2145,
    0.545,
    -0.5745,
    0.4391,
    -0.4787,
    0.7605,
    -0.4555,
    0.6063,
    -0.2468,
    0.6807,
    -0.3153,
    0.5224,
    -0.5023,
    0.5673,
    -0.2423,
    0.5368,
    -0.4245,
    0.279,
    -0.1376,
    0.3717,
    -0.2189,
    0.1649,
    -0.1762,
    0.515,
    -0.414,
    0.4579,
    -0.487,
    0.306,
    -0.44,
    0.3376,
    -0.2624,
    0.3192,
    -0.6274,
    0.3166,
    -0.327,
    0.0529,
    -0.0885,
    0.0794,
    -0.032,
    0.0867,
    -0.0792,
    0.3996,
    -0.4278,
    0.6901,
    -0.5712,
    0.9235,
    -0.141,
    0.611,
    -0.3361,
    0.5514,
    -0.3383,
    0.6502,
    -0.1824,
    0.0282,
    -0.1223,
    0.0784,
    -0.0177,
    0.0641,
    -0.0009,
    0.0534,
    -0.1488,
    0.349,
    -0.6301,
    0.3978,
    -0.3885,
    0.6065,
    -0.3655,
    0.6001,
    -0.0989,
    0.239,
    -0.1302,
    0.6136,
    -0.3137,
    0.0542,
    -0.4413,
    0.233,
    -0.1747,
    0.3792,
    -0.307,
    -0.0551,
    -0.4936,
    0.4539,
    -0.5456,
    0.1464,
    -0.2144,
    0.2842,
    -0.4335,
    0.4392,
    -0.3714,
    0.8084,
    -0.3503,
    0.1324,
    -0.1141,
    0.1133,
    -0.5722,
    0.6898,
    -0.5071,
    0.2474,
    -0.3729,
    0.2024,
    -0.2502,
    0.1291,
    -0.1319,
    0.0818,
    -0.0754,
    0.0657,
    -0.0548,
    0.5725,
    -0.0486,
    0.2248,
    -0.5303,
    0.3768,
    -0.5188,
    0.4928,
    -0.4744,
    0.1372,
    -0.6531,
    0.592,
    -0.3994,
    0.0631,
    -0.102,
    0.1678,
    -0.3745,
    0.3398,
    -0.2672,
    0.3523,
    -0.1528,
    0.0516,
    -0.1094,
    0.108,
    -0.1576,
    0.0399,
    -0.0601,
    0.0332,
    -0.0338,
    0.0306,
    -0.0675,
    0.0271,
    -0.041,
    0.8241,
    -0.0971,
    0.6477,
    -0.6485,
    0.384,
    -0.6089,
    0.1317,
    -0.5426,
    0.4364,
    -0.1531,
    0.1408,
    -0.3412,
    0.637,
    -0.3984,
    0.4992,
    -0.3144,
    0.2556,
    -0.1211,
    0.484,
    -0.4857,
    0.3704,
    -0.334,
    0.2359,
    -0.2255,
    0.1023,
    -0.1967,
    0.1299,
    -0.29,
    0.6678,
    -0.3881,
    0.4057,
    -0.4173,
    0.7024,
    -0.3732,
    0.4569,
    -0.0797,
    0.1782,
    -0.152,
    0.1292,
    -0.386,
    0.5634,
    -0.4213,
    0.0985,
    -0.1888,
    0.0466,
    -0.1075,
    0.0499,
    -0.0397,
    0.0446,
    -0.0512,
    0.2547,
    -0.0629,
    0.6069,
    -0.2184,
    0.8404,
    -0.4358,
    0.7627,
    -0.2676,
    0.8226,
    -0.0592,
    0.6829,
    -0.1237,
    0.403,
    -0.2827,
    0.6502,
    -0.4807,
    0.4854,
    -0.5055,
    0.1351,
    -0.3353,
    0.4021,
    -0.4699,
    0.0398,
    -0.2178,
    0.4104,
    -0.2143,
    0.4146,
    -0.325,
    0.5711,
    -0.4269,
    0.1923,
    -0.3084,
    0.0935,
    -0.3793,
    0.7074,
    -0.4599,
    0.0548,
    -0.1658,
    0.2678,
    -0.4882,
    0.1439,
    -0.2698,
    0.2705,
    -0.3148,
    0.1609,
    -0.2238,
    0.0607,
    -0.0417,
    0.0546,
    -0.0492,
    0.0519,
    -0.0327,
    0.0388,
    -0.0429,
    0.0561,
    -0.0472,
    0.0923,
    0.0045,
    0.1211,
    -0.6227,
    0.3477,
    -0.4993,
    0.3962,
    -0.5615,
    0.2688,
    -0.6137,
    0.5968,
    -0.167,
    0.3331,
    -0.4545,
    0.3969,
    -0.5468,
    0.8719,
    -0.348,
    0.3738,
    -0.2625,
    0.483,
    -0.5326,
    0.6631,
    -0.4162,
    0.1461,
    -0.3751,
    0.1911,
    -0.328,
    0.8076,
    -0.2564,
    0.3616,
    -0.4075,
    0.4566,
    -0.4483,
    0.3346,
    -0.2026,
    0.4377,
    -0.3305,
    0.3827,
    -0.2234,
    0.3369,
    -0.1282,
    0.0864,
    -0.1455,
    0.0137,
    -0.0618,
    0.1696,
    -0.4556,
    0.183,
    -0.2032,
    0.5748,
    -0.3667,
    0.4705,
    -0.4667,
    0.5495,
    -0.4157,
    0.3364,
    -0.0852,
    0.2611,
    -0.367,
    0.4688,
    -0.467,
    0.1364,
    -0.4449,
    0.6583,
    -0.1254,
    0.0251,
    -0.3751,
    0.3181,
    -0.1055,
    0.603,
    -0.4034,
    0.7302,
    -0.5344,
    0.6437,
    -0.4599,
    0.1509,
    -0.2041,
    0.4568,
    -0.1267,
    0.1453,
    -0.1049,
    0.1504,
    -0.1007,
    0.1355,
    -0.067,
    0.0756,
    -0.0602,
    0.1533,
    -0.448,
    0.3114,
    -0.1035,
    0.2768,
    -0.4362,
    0.4542,
    -0.24,
    0.2265,
    -0.4655,
    0.31,
    -0.4694,
    0.6205,
    -0.1538,
    0.4379,
    -0.1754,
    0.7396,
    -0.1992,
    0.224,
    -0.1716,
    0.4928,
    -0.2598,
    0.6366,
    -0.2252,
    0.3493,
    -0.2964,
    0.374,
    -0.2272,
    0.3808,
    -0.2023,
    0.2137,
    -0.2397,
    0.493,
    -0.6214,
    0.3453,
    -0.2347,
    0.1456,
    -0.374,
    0.1157,
    -0.0343,
    0.1972,
    -0.2903,
    0.4067,
    -0.4065,
    0.7991,
    -0.1687,
    0.7701,
    -0.2127,
    0.7503,
    -0.2728,
    0.4591,
    -0.2458,
    0.6956,
    -0.6643,
    0.1665,
    -0.1892,
    0.2466,
    -0.2615,
    0.2763,
    -0.3445,
    0.7327,
    -0.2161,
    0.4812,
    -0.4321,
    0.3085,
    -0.4807,
    0.6885,
    -0.501,
    0.6369,
    -0.3853,
    0.5718,
    -0.3444,
    0.2468,
    -0.252,
    0.205,
    -0.3498,
    0.0874,
    -0.2979,
    0.6082,
    -0.2858,
    0.2553,
    -0.2298,
    0.3116,
    -0.2986,
    0.132,
    -0.5092,
    0.1998,
    -0.3015,
    0.1444,
    -0.3261,
    0.7785,
    -0.3672,
    0.6461,
    -0.1325,
    0.4244,
    -0.151,
    0.3342,
    -0.2311,
    0.076,
    -0.1475,
    0.0284,
    -0.1039,
    0.0964,
    -0.0726,
    0.0828,
    0.0052,
    0.0785,
    -0.0992,
    0.051,
    -0.0678,
    0.0912,
    -0.3441,
    0.6617,
    -0.1472,
    0.6493,
    -0.0588,
    0.6926,
    -0.1673,
    0.2092,
    -0.5207,
    0.6883,
    -0.3991,
    0.2179,
    -0.6644,
    0.3849,
    -0.1707,
    0.6073,
    -0.3829,
    0.7794,
    -0.4153,
    0.1088,
    -0.2671,
    0.1861,
    -0.2885,
    0.4882,
    -0.2732,
    0.1738,
    -0.2483,
    0.2899,
    -0.2766,
    0.2714,
    -0.0904,
    0.0794,
    -0.069,
    0.025,
    -0.0691,
    0.0591,
    -0.1214,
    0.7992,
    -0.8608,
    0.3561,
    -0.5741,
    0.4552,
    -0.7026,
    0.2948,
    -0.3482,
    0.0415,
    -0.3925,
    0.2595,
    -0.3202,
    0.7761,
    -0.8406,
    0.1962,
    -0.3537,
    0.2106,
    -0.2669,
    0.2879,
    -0.0666,
    0.7951,
    -0.526,
    0.2748,
    -0.0721,
    0.3476,
    -0.1366,
    0.8941,
    -0.6732,
    0.9285,
    -0.2537,
    0.6234,
    -0.5013,
    0.2614,
    -0.4728,
    0.3325,
    -0.1381,
    0.4266,
    -0.262,
    0.5619,
    -0.1301,
    0.1083,
    -0.1563,
    0.1269,
    -0.1238,
    0.1143,
    -0.0328,
    0.0706,
    -0.0752,
    0.0752,
    -0.0961,
    0.102,
    -0.1054,
    0.1112,
    -0.0722,
    0.1031,
    -0.0783,
    0.0702,
    -0.1159,
    0.4943,
    -0.2685,
    0.714,
    -0.4513,
    0.096,
    -0.4658,
    0.7449,
    -0.3129,
    0.6988,
    -0.5023,
    0.5411,
    -0.284,
    0.1063,
    -0.1237,
    0.1137,
    -0.105,
    0.1246,
    -0.1271,
    0.401,
    -0.5683,
    0.2501,
    -0.5076,
    0.4979,
    -0.5026,
    0.4035,
    -0.436,
    0.2171,
    -0.5575,
    0.1711,
    -0.5494,
    0.3923,
    -0.199,
    0.6861,
    -0.4662,
    0.234,
    -0.3504,
    0.3378,
    -0.2864,
    0.4946,
    -0.1442,
    0.3971,
    -0.0686,
    0.2046,
    -0.1642,
    0.2651,
    -0.179,
    0.1283,
    -0.1881,
    0.0686,
    -0.1681,
    0.1522,
    -0.1224,
    0.6265,
    -0.4766,
    0.1926,
    -0.3093,
    0.1932,
    -0.2322,
    0.6378,
    -0.3262,
    0.4319,
    -0.0432,
    0.337,
    -0.0328,
    0.4582,
    -0.2604,
    0.2876,
    -0.4219,
    0.2896,
    -0.1709,
    0.07,
    -0.0918,
    0.0434,
    -0.0554,
    0.4093,
    -0.2496,
    0.4495,
    -0.3773,
    0.4899,
    -0.0524,
    0.5155,
    -0.3162,
    0.5321,
    -0.4836,
    0.5214,
    -0.347,
    0.3832,
    -0.5186,
    0.3163,
    -0.3978,
    0.0478,
    -0.0536,
    0.5169,
    -0.4372,
    0.1595,
    -0.2768,
    0.3739,
    -0.2405,
    0.1729,
    -0.26,
    0.3041,
    -0.1784,
    0.2893,
    -0.2214,
    0.1445,
    -0.1608,
    0.2307,
    -0.1002,
    0.0593,
    -0.0745,
    0.0623,
    -0.1317,
    0.127,
    -0.0919,
    0.245,
    -0.4366,
    0.5439,
    -0.3011,
    0.3922,
    -0.3751,
    0.5462,
    -0.5382,
    0.1341,
    -0.472,
    0.2959,
    -0.3698,
    0.3462,
    -0.3359,
    0.4369,
    -0.2419,
    0.36,
    -0.0856,
    0.4385,
    -0.2451,
    0.2769,
    -0.1534,
    0.1422,
    -0.1808,
    0.5177,
    -0.7833,
    0.344,
    -0.4441,
    0.2759,
    -0.3527,
    0.3627,
    -0.6079,
    0.4235,
    -0.5292,
    0.3883,
    -0.3349,
    0.0854,
    -0.0594,
    0.0487,
    -0.1375,
    0.1644,
    -0.0823,
    0.2841,
    -0.3038,
    0.6562,
    -0.3286,
    0.6479,
    -0.3314,
    0.3214,
    -0.3334,
    0.1623,
    -0.3554,
    0.2436,
    -0.3341,
    0.5528,
    -0.2676,
    0.0466,
    -0.1909,
    0.0326,
    -0.2081,
    0.1748,
    -0.1244,
    0.0495,
    -0.1364,
    0.0588,
    -0.1214,
    0.0888,
    -0.0822,
    0.0317,
    -0.1038,
    0.4039,
    -0.3797,
    0.1414,
    -0.2352,
    0.4979,
    -0.2878,
    0.4592,
    -0.5287,
    0.2203,
    -0.2762,
    0.4914,
    -0.4207,
    0.58,
    -0.583,
    0.355,
    -0.4805,
    0.1174,
    -0.1881,
    0.2718,
    -0.345,
    0.0549,
    -0.0746,
    0.3001,
    -0.4195,
    0.2795,
    -0.1811,
    0.2907,
    -0.6783,
    0.4163,
    -0.3203,
    0.2663,
    -0.5083,
    0.1592,
    -0.2329,
    0.549,
    -0.3165,
    0.1912,
    -0.2374,
    0.0869,
    -0.3645,
    0.3078,
    -0.1539,
    0.0712,
    -0.091,
    0.2084,
    -0.196,
    0.2587,
    -0.2027,
    0.2596,
    -0.3196,
    0.3257,
    -0.2238,
    0.3261,
    -0.2583,
    0.2122,
    -0.3728,
    0.2416,
    -0.3277,
    0.1484,
    -0.2664,
    0.4752,
    -0.3594,
    0.2358,
    -0.1429,
    0.0892,
    -0.0068,
    0.0665,
    -0.0274,
    0.2237,
    -0.3944,
    0.1924,
    -0.5536,
    0.1807,
    -0.0802,
    0.2222,
    -0.3005,
    0.326,
    -0.1124,
    0.6379,
    -0.8484,
    0.0932,
    -0.2924,
    0.0373,
    -0.1166,
    0.1365,
    -0.0664,
    0.2435,
    -0.0105,
    0.0318,
    -0.0478,
    0.0426,
    -0.065,
    0.2124,
    -0.0496,
    0.3875,
    -0.2938,
    0.5541,
    -0.3601,
    0.2633,
    -0.4248,
    0.0848,
    -0.1249,
    0.4707,
    -0.2329,
    0.2506,
    -0.265,
    0.3344,
    -0.3385,
    0.0863,
    -0.0826,
    0.0279,
    -0.0409,
    0.0347,
    -0.0139,
    0.0518,
    -0.0509,
    0.0188,
    -0.0283,
    0.0223,
    -0.0595,
    0.4605,
    -0.3487,
    0.312,
    -0.2036,
    0.3099,
    -0.2811,
    0.5267,
    -0.5594,
    0.4695,
    -0.2915,
    0.2992,
    -0.1696,
    0.3527,
    -0.4775,
    0.1984,
    -0.7034,
    0.1989,
    -0.4802,
    0.2293,
    -0.2363,
    0.3795,
    -0.1019,
    0.214,
    -0.3451,
    0.2787,
    -0.1206,
    0.1929,
    -0.2204,
    0.2263,
    -0.0662,
    0.0091,
    -0.0357,
    0.0245,
    -0.021,
    0.0206,
    -0.0202,
    0.0423,
    -0.055,
    0.1326,
    -0.3471,
    0.1661,
    -0.5983,
    0.1436,
    -0.6418,
    0.1344,
    -0.1677,
    0.4522,
    -0.3221,
    0.1314,
    -0.2541,
    0.1952,
    -0.2078,
    0.3302,
    -0.4264,
    0.1113,
    -0.3056,
    0.1221,
    -0.0698,
    0.161,
    -0.1983,
    0.0548,
    -0.1107,
    0.086,
    -0.0737,
    0.0497,
    -0.0552,
    0.0784,
    -0.0323,
    0.0361,
    -0.0135,
    0.0162,
    -0.0196,
    0.0206,
    -0.0453,
    0.0175,
    -0.0229,
    0.0241,
    -0.0002,
    0.0447,
    -0.0288,
    0.0223,
    -0.0121,
    0.1178,
    -0.0139,
    0.241,
    -0.1901,
    0.244,
    -0.5299,
    0.5447,
    -0.7113,
    0.4151,
    -0.603,
    0.2956,
    -0.5284,
    0.1806,
    -0.4323,
    0.6482,
    -0.2533,
    0.2103,
    -0.6375,
    0.3239,
    -0.4151,
    0.3537,
    -0.194,
    0.2693,
    -0.2642,
    0.2398,
    -0.4674,
    0.3016,
    -0.4068,
    0.0334,
    -0.0769,
    0.2639,
    -0.2361,
    0.0977,
    -0.3904,
    0.3204,
    -0.3504,
    0.0976,
    -0.217,
    0.0565,
    -0.1082,
    0.0533,
    -0.0722,
    0.1701,
    -0.7484,
    0.196,
    -0.4294,
    0.6807,
    -0.6819,
    0.3365,
    -0.8435,
    0.3891,
    -0.6207,
    0.5239,
    -0.3878,
    0.4208,
    -0.3535,
    0.2711,
    -0.3975,
    0.2731,
    -0.2205,
    0.4073,
    -0.2468,
    0.6025,
    -0.5396,
    0.1878,
    -0.5639,
    0.2352,
    -0.2761,
    0.0377,
    -0.3088,
    0.2845,
    -0.3598,
    0.3636,
    -0.2949,
    0.3403,
    -0.3867,
    0.2563,
    -0.4021,
    0.2157,
    -0.1704,
    0.1699,
    -0.435,
    0.3646,
    -0.5826,
    0.3387,
    -0.3027,
    0.4092,
    -0.4934,
    0.1424,
    -0.2463,
    0.0711,
    0.0001,
    0.0351,
    -0.0243,
    0.0612,
    -0.0531,
    0.0585,
    -0.0425,
    0.0092,
    -0.064,
    0.046,
    -0.004,
    0.2309,
    -0.436,
    0.7293,
    -0.5369,
    0.4776,
    -0.3488,
    0.3777,
    -0.7217,
    0.413,
    -0.4555,
    0.4399,
    -0.2549,
    0.3749,
    -0.0737,
    0.3357,
    -0.4675,
    0.3724,
    -0.5223,
    0.1958,
    -0.313,
    0.4511,
    -0.4031,
    0.3293,
    -0.5959,
    0.3007,
    -0.1257,
    0.3639,
    -0.7442,
    0.3774,
    -0.4794,
    0.4318,
    -0.3833,
    0.2124,
    -0.6809,
    0.2402,
    -0.3085,
    0.4119,
    -0.2997,
    0.8016,
    -0.6927,
    0.6266,
    -0.5041,
    0.1607,
    -0.3448,
    0.2803,
    -0.1503,
    0.2598,
    -0.1221,
    0.4858,
    -0.1476,
    0.5938,
    -0.43,
    0.194,
    -0.4916,
    0.3673,
    -0.4998,
    0.2248,
    -0.2581,
    0.3174,
    -0.36,
    0.3192,
    -0.3938,
    0.3716,
    -0.4978,
    0.6061,
    -0.4865,
    0.2533,
    -0.1549,
    0.537,
    -0.6421,
    0.2832,
    -0.5582,
    0.1887,
    -0.2927,
    0.3153,
    -0.3715,
    0.3874,
    -0.2358,
    0.4595,
    -0.2514,
    0.2339,
    -0.3213,
    0.2307,
    -0.2308,
    0.2181,
    -0.1255,
    0.1783,
    -0.4506,
    0.2554,
    -0.4667,
    0.5537,
    -0.6071,
    0.5667,
    -0.7196,
    0.4511,
    -0.2971,
    0.3402,
    -0.7305,
    0.2621,
    -0.3168,
    0.1878,
    -0.1546,
    0.0653,
    -0.0493,
    0.036,
    -0.0405,
    0.2289,
    -0.0528,
    0.4384,
    -0.4868,
    0.4826,
    -0.5944,
    0.219,
    -0.7204,
    0.2023,
    -0.1847,
    0.1218,
    -0.7725,
    0.3868,
    -0.8665,
    0.2606,
    -0.6119,
    0.2851,
    -0.385,
    0.2719,
    -0.3259,
    0.1764,
    -0.2084,
    0.0812,
    -0.477,
    0.1109,
    -0.2314,
    0.1696,
    -0.3021,
    0.2662,
    -0.3523,
    0.0898,
    -0.402,
    0.869,
    -0.2912,
    0.5495,
    -0.0655,
    0.5196,
    -0.6279,
    0.1393,
    -0.1679,
    0.4149,
    -0.5814,
    0.2383,
    -0.1819,
    0.2597,
    -0.1247,
    0.0737,
    -0.0474,
    0.0365,
    -0.0632,
    0.0622,
    -0.0787,
    0.0394,
    -0.1167,
    0.0639,
    -0.0867,
    0.0894,
    -0.2216,
    0.2309,
    -0.6112,
    0.3304,
    -0.5389,
    0.1623,
    -0.3067,
    0.1803,
    -0.7204,
    0.4732,
    -0.09,
    0.5306,
    -0.262,
    0.0871,
    -0.0539,
    0.3961,
    -0.3672,
    0.407,
    -0.4525,
    0.2993,
    -0.1033,
    0.1633,
    -0.1406,
    0.2138,
    -0.1657,
    0.1866,
    -0.0607,
    0.209,
    -0.1523,
    0.183,
    -0.2367,
    0.1512,
    -0.1465,
    0.1671,
    -0.2079,
    0.2746,
    -0.2524,
    0.2307,
    -0.1921,
    0.2751,
    -0.2813,
    0.3394,
    -0.074,
    0.1033,
    -0.3119,
    0.4952,
    -0.384,
    0.1468,
    -0.0799,
    0.3979,
    -0.3267,
    0.0347,
    -0.0766,
    0.2678,
    -0.6332,
    0.1949,
    -0.1979,
    0.497,
    -0.401,
    0.3408,
    -0.2547,
    0.0166,
    -0.0384,
    0.1331,
    -0.0462,
    0.1286,
    -0.0458,
    0.0234,
    -0.0307,
    0.1862,
    -0.2296,
    0.4914,
    -0.3272,
    0.6079,
    -0.3289,
    0.4582,
    -0.375,
    0.0506,
    -0.2799,
    0.0599,
    -0.1961,
    0.0915,
    -0.057,
    0.1762,
    -0.1252,
    0.0695,
    -0.0651,
    0.0331,
    -0.0378,
    0.036,
    -0.1651,
    0.0728,
    -0.2874,
    0.0959,
    -0.1039,
    0.1176,
    -0.4266,
    0.3371,
    -0.31,
    0.2986,
    -0.373,
    0.2222,
    -0.3767,
    0.0508,
    -0.2402,
    0.0133,
    -0.0302,
    0.015,
    -0.0307,
    0.0387,
    -0.0039,
    0.0339,
    -0.0281,
    0.3786,
    -0.5616,
    0.2911,
    -0.5453,
    0.065,
    -0.1428,
    0.023,
    -0.0112,
    0.0032,
    -0.0059,
    0.014,
    -0.0052,
    0.0139,
    -0.0156,
    0.0049,
    -0.0032,
    0.0028,
    -0.0022,
    0.0045,
    -0.0089,
    0.0107,
    -0.0064,
    0.0072,
    -0.0059,
    0.0055,
    -0.0107,
    0.0001,
    -0.0025,
    0.0052,
    -0.0015,
    0.0086,
    -0.0042,
    0.0013,
    -0.0017,
    0.0038,
    -0.0003,
    0.0019,
    -0.0036,
    0.0006,
    -0.0007,
    0.0006,
    -0.0011
];

export default chapterSecondPCM;