import styled, { css } from 'styled-components';

export const ChapterListWrap = styled.div(({ theme: { colors } }) => {
    return css`
      color: ${colors.base};
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 7px 5px 20px;
      max-width: 375px;
      margin: 0 auto;
    }
  `;
});