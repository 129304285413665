import { BrowserView, MobileView } from 'react-device-detect';
import { useRoutes } from 'react-router-dom';

import { Wrapper } from './App.styles';
import DesktopPlug from '../DesktopPlug';
import { Home, DetailPage, Error404 } from '../../routes';

import appData from '../../data/appData';


function App() {
    const routesChapters = appData.map(chapter => ({
        path: `/${chapter.url}`,
        element: <DetailPage {...chapter} />
    }));

    const routesAll = [
        {
            path: '/',
            element: <Home />
        },
        ...routesChapters,
        {
            path: '*',
            element: <Error404 />
        }
    ];

    const elementRoutes = useRoutes(routesAll);

  return (
    <Wrapper>
        <BrowserView>
            <DesktopPlug />
        </BrowserView>
        <MobileView>
            {elementRoutes}
        </MobileView>
    </Wrapper>
  );
}

export default App;