/* import QRCode from "react-qr-code"; */
import {DesktopPlugWrapper} from "./DesktopPlug.styles";

const DesktopPlug = () => {
    return (
        <DesktopPlugWrapper>
        <h1>Klus en smaakmakers</h1>
        <h2>Audio tour</h2>
        <p>
            Deze website dient geopend te worden op mobile devices zoals smartphone of tablet.<br/>
            Scan de QR-code en ontdek de Klus en Smaakmakers Audiotour.
        </p>
        <img src='/images/qr-code.svg' alt='qr-code'/>
        {/*<QRCode*/}
        {/*    size={242}*/}
        {/*    value='https://console.firebase.google.com/u/0/project/karwei-audiotour-staging/overview' */}
        {/*/>*/}
        </DesktopPlugWrapper>
    );
}

export default DesktopPlug;
