const chapterSixthPCM = [
    0.0005,
    -0.0004,
    0.0011,
    -0.0023,
    0.0052,
    -0.0042,
    0.0118,
    -0.0088,
    0.0621,
    -0.1098,
    0.1453,
    -0.135,
    0.1495,
    -0.1456,
    0.2154,
    -0.1481,
    0.0667,
    -0.1499,
    0.1447,
    -0.087,
    0.6596,
    -0.3346,
    0.455,
    -0.4619,
    0.3626,
    -0.3318,
    0.2723,
    -0.1819,
    0.3653,
    -0.1029,
    0.0925,
    -0.0748,
    0.1345,
    -0.0601,
    0.0538,
    -0.1117,
    0.3304,
    -0.2267,
    0.3661,
    -0.5936,
    0.7491,
    -0.5392,
    0.5359,
    -0.3661,
    0.2223,
    -0.2993,
    0.5911,
    -0.2304,
    0.3029,
    -0.3176,
    0.2793,
    -0.2562,
    0.0995,
    -0.0641,
    0.7168,
    -0.4598,
    0.6587,
    -0.5439,
    0.4315,
    -0.4798,
    0.3662,
    -0.4224,
    0.3212,
    -0.1772,
    0.2112,
    -0.1747,
    0.2521,
    -0.2751,
    0.4025,
    -0.8522,
    0.3712,
    -0.2766,
    0.3189,
    -0.8078,
    0.7037,
    -0.4717,
    0.8362,
    -0.4426,
    0.2783,
    -0.2444,
    0.3395,
    -0.3464,
    0.3793,
    -0.3784,
    0.1994,
    -0.2535,
    0.2618,
    -0.2669,
    0.1856,
    -0.1193,
    0.1242,
    -0.1774,
    0.3021,
    -0.178,
    0.4998,
    -0.081,
    0.0398,
    -0.0783,
    0.1119,
    -0.072,
    0.1375,
    -0.1211,
    0.8575,
    -0.3951,
    0.4897,
    -0.3205,
    0.3343,
    -0.5024,
    0.6949,
    -0.5633,
    0.5612,
    -0.4747,
    0.3842,
    -0.1859,
    0.7274,
    -0.288,
    0.6174,
    -0.5058,
    0.2116,
    -0.1095,
    0.2604,
    -0.3519,
    0.2108,
    -0.2383,
    0.3958,
    -0.1383,
    0.3735,
    -0.4888,
    0.3468,
    -0.5196,
    0.9141,
    -0.0706,
    0.3577,
    -0.42,
    0.2268,
    -0.2571,
    0.2243,
    -0.5384,
    0.1148,
    -0.1169,
    0.5682,
    -0.0875,
    0.3445,
    -0.3087,
    0.7785,
    -0.503,
    0.1391,
    -0.307,
    0.6976,
    -0.6073,
    0.6978,
    -0.269,
    0.2076,
    -0.0947,
    0.2794,
    -0.3684,
    0.643,
    -0.3401,
    0.118,
    -0.4567,
    0.0894,
    -0.4857,
    0.4192,
    -0.4136,
    0.3891,
    -0.2126,
    0.0663,
    -0.1248,
    0.8299,
    -0.5342,
    0.6967,
    -0.5463,
    0.6472,
    -0.4893,
    0.6959,
    -0.4884,
    0.302,
    -0.3588,
    0.1389,
    -0.3058,
    0.0553,
    -0.1092,
    0.0682,
    -0.697,
    0.8044,
    -0.4627,
    0.5154,
    -0.5141,
    0.4738,
    -0.2477,
    0.5845,
    -0.3462,
    0.3418,
    -0.2694,
    0.5403,
    -0.6908,
    0.3139,
    -0.4546,
    0.8079,
    -0.6012,
    0.2478,
    -0.1477,
    0.5271,
    -0.4459,
    0.5028,
    -0.6801,
    0.1268,
    -0.4173,
    0.121,
    -0.1882,
    0.7496,
    -0.4065,
    0.5837,
    -0.304,
    0.6024,
    -0.2644,
    0.1279,
    -0.246,
    0.0722,
    -0.1641,
    0.0476,
    -0.1378,
    0.0358,
    -0.0239,
    0.0264,
    -0.0431,
    0.0362,
    -0.0552,
    0.0408,
    -0.0331,
    0.0489,
    -0.0602,
    0.0584,
    -0.11,
    0.0726,
    -0.1193,
    0.5998,
    -0.2809,
    0.6517,
    -0.201,
    0.2209,
    -0.3863,
    0.7105,
    -0.4405,
    0.0948,
    -0.1044,
    0.6172,
    -0.8843,
    0.2665,
    -0.4556,
    0.4191,
    -0.2878,
    0.4045,
    -0.228,
    0.6056,
    -0.6902,
    0.1312,
    -0.1377,
    0.799,
    -0.717,
    0.2898,
    -0.0994,
    0.412,
    -0.5479,
    0.3742,
    -0.4012,
    0.3665,
    -0.6228,
    0.2765,
    -0.3204,
    0.1925,
    -0.4029,
    0.6175,
    -0.3368,
    0.6405,
    -0.6052,
    0.2687,
    -0.5991,
    0.1893,
    -0.3836,
    0.1293,
    -0.0369,
    0.0569,
    -0.0718,
    0.0571,
    -0.0678,
    0.05,
    -0.0782,
    0.0534,
    -0.0768,
    0.0491,
    -0.0617,
    0.0403,
    -0.064,
    0.4124,
    -0.4767,
    0.4115,
    -0.5982,
    0.5927,
    -0.8013,
    0.3613,
    -0.6518,
    0.71,
    -0.6346,
    0.0742,
    -0.3936,
    0.2166,
    -0.3574,
    0.0457,
    -0.0481,
    0.3887,
    -0.4799,
    0.7454,
    -0.5517,
    0.7648,
    -0.39,
    0.1505,
    -0.4,
    0.1953,
    -0.127,
    0.0662,
    -0.0646,
    0.0688,
    -0.0938,
    0.054,
    -0.1004,
    0.0468,
    -0.3081,
    0.6589,
    -0.5615,
    0.4407,
    -0.4725,
    0.3057,
    -0.3583,
    0.3416,
    -0.3446,
    0.3842,
    -0.3968,
    0.6356,
    -0.5907,
    0.5247,
    -0.2951,
    0.6756,
    -0.2674,
    0.3084,
    -0.3532,
    0.3718,
    -0.2308,
    0.0828,
    -0.0883,
    0.1082,
    -0.1166,
    0.0948,
    -0.0706,
    0.0806,
    -0.0845,
    0.0622,
    -0.1088,
    0.1101,
    -0.1013,
    0.2721,
    -0.3414,
    0.6692,
    -0.6086,
    0.5304,
    -0.355,
    0.3552,
    -0.2885,
    0.087,
    -0.102,
    0.1391,
    -0.262,
    0.136,
    -0.0711,
    0.0884,
    -0.0732,
    0.0718,
    -0.0793,
    0.0748,
    -0.0956,
    0.0748,
    -0.0566,
    0.1639,
    -0.2151,
    0.3103,
    -0.522,
    0.5773,
    -0.5219,
    0.5286,
    -0.3094,
    0.0748,
    -0.1552,
    0.3375,
    -0.1995,
    0.0626,
    -0.1434,
    0.1666,
    -0.1481,
    0.082,
    -0.2713,
    0.0472,
    -0.0424,
    0.045,
    -0.0427,
    0.0458,
    -0.0423,
    0.0516,
    -0.0335,
    0.043,
    -0.0262,
    0.2031,
    -0.3876,
    0.1428,
    -0.1754,
    0.3531,
    -0.434,
    0.5424,
    -0.4269,
    0.2597,
    -0.1555,
    0.222,
    -0.5123,
    0.5204,
    -0.5491,
    0.7278,
    -0.3779,
    0.3915,
    -0.1161,
    0.1492,
    -0.3928,
    0.5192,
    -0.4172,
    0.5264,
    -0.5043,
    0.251,
    -0.2703,
    0.674,
    -0.3425,
    0.588,
    -0.4853,
    0.4941,
    -0.169,
    0.5254,
    -0.4881,
    0.1771,
    -0.2932,
    0.4107,
    -0.4351,
    0.4111,
    -0.1861,
    0.519,
    -0.2413,
    0.1894,
    -0.1116,
    0.404,
    -0.3012,
    0.2206,
    -0.1607,
    0.1569,
    -0.1693,
    0.1646,
    -0.1459,
    0.1838,
    -0.1493,
    0.1414,
    -0.1342,
    0.6532,
    -0.3909,
    0.1366,
    -0.196,
    0.1314,
    -0.3819,
    0.0316,
    -0.2785,
    0.407,
    -0.2673,
    0.3266,
    -0.3941,
    0.522,
    -0.3874,
    0.3496,
    -0.3965,
    0.1315,
    -0.2387,
    0.0536,
    -0.0463,
    0.0244,
    -0.0332,
    0.0159,
    -0.015,
    0.0871,
    -0.5995,
    0.1847,
    -0.5788,
    0.1394,
    -0.5678,
    0.4164,
    -0.1749,
    0.359,
    -0.3284,
    0.7684,
    -0.4829,
    0.6037,
    -0.5057,
    0.1727,
    -0.2188,
    0.3757,
    -0.3375,
    0.3295,
    -0.4154,
    0.373,
    -0.2051,
    0.2114,
    -0.0323,
    0.9374,
    -0.2312,
    0.632,
    -0.2171,
    0.2471,
    -0.2431,
    0.2132,
    -0.2575,
    0.7605,
    -0.4805,
    0.4856,
    -0.375,
    0.3086,
    -0.2016,
    0.1074,
    -0.0736,
    0.1202,
    -0.0957,
    0.1034,
    -0.0732,
    0.0328,
    -0.0328,
    0.1191,
    -0.2183,
    0.0607,
    -0.0438,
    0.0601,
    -0.0523,
    0.0275,
    -0.0185,
    0.0154,
    -0.0268,
    0.0975,
    -0.021,
    0.8,
    -0.2038,
    0.7947,
    -0.0095,
    0.3537,
    -0.3461,
    0.0272,
    -0.1023,
    0.7838,
    -0.6398,
    0.1608,
    -0.4298,
    0.3318,
    -0.3273,
    0.6637,
    -0.4097,
    0.1845,
    -0.3537,
    0.1973,
    -0.1244,
    0.3767,
    -0.1503,
    0.487,
    -0.495,
    0.4439,
    -0.3578,
    -0.1531,
    -0.3494,
    0.0583,
    -0.3147,
    0.3993,
    -0.0373,
    0.1478,
    -0.3504,
    0.4792,
    -0.2296,
    0.4928,
    -0.2283,
    0.1154,
    -0.0629,
    0.1433,
    -0.212,
    0.1047,
    -0.7252,
    0.6504,
    -0.4455,
    0.1507,
    -0.3017,
    0.4226,
    -0.3749,
    0.4451,
    -0.2606,
    0.2472,
    -0.3374,
    0.4373,
    -0.2933,
    0.0348,
    -0.0247,
    0.0256,
    -0.0192,
    0.0238,
    -0.0264,
    0.0169,
    -0.0128,
    0.5108,
    -0.0136,
    0.7745,
    -0.1966,
    0.6784,
    -0.6133,
    0.108,
    -0.1191,
    0.0335,
    -0.0551,
    0.0359,
    -0.0404,
    0.1541,
    -0.1158,
    0.3579,
    -0.5105,
    0.4805,
    -0.173,
    0.3269,
    -0.3921,
    0.6105,
    -0.3785,
    0.433,
    -0.1846,
    0.2207,
    -0.2487,
    0.0673,
    -0.1237,
    0.1048,
    -0.0797,
    0.0731,
    -0.0331,
    0.0131,
    -0.0243,
    0.08,
    -0.1427,
    0.0818,
    -0.0817,
    0.0306,
    -0.0381,
    0.0157,
    -0.012,
    0.5435,
    -0.404,
    0.2709,
    -0.3421,
    0.8344,
    -0.3341,
    0.121,
    -0.038,
    0.4968,
    -0.5005,
    0.6488,
    -0.451,
    0.2552,
    -0.0628,
    0.2405,
    -0.1564,
    0.1991,
    -0.2372,
    0.1894,
    -0.2138,
    0.4844,
    -0.3156,
    0.5278,
    -0.5165,
    0.1311,
    -0.1415,
    0.23,
    -0.4012,
    0.5168,
    -0.3622,
    0.1741,
    -0.367,
    0.271,
    -0.2087,
    0.7884,
    -0.422,
    0.0637,
    -0.3721,
    0.4906,
    -0.1863,
    0.4615,
    -0.5064,
    0.8131,
    -0.489,
    0.6172,
    -0.4154,
    0.8306,
    -0.3909,
    0.1035,
    -0.2833,
    0.6254,
    -0.3389,
    0.6984,
    -0.721,
    0.5736,
    -0.1559,
    0.6361,
    -0.3589,
    0.4906,
    -0.559,
    0.7615,
    -0.5576,
    0.396,
    -0.3606,
    0.3514,
    -0.3928,
    0.38,
    -0.3873,
    0.2354,
    -0.1358,
    0.647,
    -0.2049,
    0.9088,
    -0.1849,
    0.4709,
    -0.4203,
    0.4513,
    -0.4908,
    0.0223,
    -0.2592,
    0.785,
    -0.3114,
    0.2598,
    -0.1463,
    0.0834,
    -0.0762,
    0.5513,
    -0.5061,
    0.3695,
    -0.3383,
    0.2594,
    -0.3277,
    0.1049,
    -0.1457,
    0.1505,
    -0.1663,
    0.057,
    -0.0462,
    0.0605,
    -0.0394,
    0.0247,
    -0.0278,
    0.1508,
    -0.0313,
    0.0848,
    -0.1142,
    0.4774,
    -0.4066,
    0.7564,
    -0.4352,
    0.6386,
    -0.5464,
    0.0551,
    -0.3162,
    0.6728,
    -0.3206,
    0.242,
    -0.3608,
    0.2345,
    -0.2924,
    0.4056,
    -0.4403,
    0.423,
    -0.6565,
    0.126,
    -0.3872,
    0.1538,
    -0.1565,
    0.6158,
    -0.4717,
    0.1413,
    -0.1778,
    0.5699,
    -0.59,
    0.4016,
    -0.3645,
    0.5583,
    -0.2719,
    0.301,
    -0.1337,
    0.4808,
    -0.1871,
    0.7138,
    -0.0678,
    0.3771,
    -0.2924,
    0.7684,
    -0.475,
    0.7835,
    -0.7098,
    0.3903,
    -0.5505,
    0.0942,
    -0.0251,
    0.2769,
    -0.2018,
    0.3661,
    -0.3828,
    0.5052,
    -0.1261,
    0.3107,
    -0.3409,
    0.6053,
    -0.061,
    0.7508,
    -0.6595,
    0.0311,
    -0.1603,
    0.3433,
    -0.4388,
    0.5543,
    -0.4587,
    0.6577,
    -0.5048,
    0.3178,
    -0.41,
    0.5978,
    -0.3253,
    0.3562,
    -0.2377,
    0.1376,
    -0.0877,
    0.1117,
    -0.1129,
    0.0277,
    -0.0361,
    0.0144,
    -0.0128,
    0.0207,
    -0.0123,
    0.0347,
    -0.0211,
    0.1682,
    -0.2393,
    0.1444,
    -0.2229,
    0.7801,
    -0.3724,
    0.6875,
    -0.4978,
    0.1002,
    -0.0706,
    0.0544,
    -0.1529,
    0.4871,
    -0.5189,
    0.252,
    -0.5837,
    0.2283,
    -0.1369,
    0.1119,
    -0.2069,
    0.0231,
    -0.219,
    0.3543,
    -0.2432,
    0.1709,
    -0.0534,
    0.0294,
    -0.0158,
    0.0149,
    -0.0216,
    0.0363,
    -0.0304,
    0.109,
    -0.4029,
    0.6217,
    -0.4841,
    0.513,
    -0.396,
    0.3983,
    -0.3006,
    0.8476,
    -0.3476,
    0.5762,
    -0.3245,
    0.2603,
    -0.2955,
    0.2028,
    -0.362,
    0.0881,
    -0.0803,
    0.0919,
    -0.068,
    0.1315,
    -0.1249,
    0.6525,
    -0.4785,
    0.3896,
    -0.3508,
    0.316,
    -0.2695,
    0.2511,
    -0.0704,
    0.6017,
    -0.4106,
    0.249,
    -0.4706,
    0.2076,
    -0.3069,
    0.8616,
    -0.5675,
    0.1328,
    -0.1192,
    0.2094,
    -0.219,
    0.7705,
    -0.5256,
    0.1889,
    -0.275,
    0.4724,
    -0.2074,
    0.5562,
    -0.5276,
    0.1676,
    -0.2831,
    0.045,
    -0.0836,
    0.0901,
    -0.0918,
    0.0516,
    -0.0719,
    0.0262,
    -0.0278,
    0.0934,
    -0.3344,
    0.7956,
    -0.4643,
    0.8933,
    -0.5143,
    0.2797,
    -0.2553,
    0.7622,
    -0.3419,
    0.8785,
    -0.4693,
    0.4217,
    -0.4187,
    0.1321,
    -0.1053,
    0.7719,
    -0.3316,
    0.1596,
    -0.3005,
    0.2548,
    -0.27,
    0.1759,
    -0.1153,
    0.5242,
    -0.3887,
    0.5332,
    -0.477,
    0.3724,
    -0.3601,
    0.2056,
    -0.2159,
    0.1029,
    -0.17,
    0.0057,
    -0.013,
    0.0795,
    -0.0992,
    0.0974,
    -0.0758,
    0.3515,
    -0.2003,
    0.5028,
    -0.1758,
    0.8184,
    -0.558,
    0.1214,
    -0.4421,
    0.6433,
    -0.5585,
    0.212,
    -0.2787,
    0.5134,
    -0.342,
    0.2598,
    -0.1655,
    0.3601,
    -0.221,
    0.0897,
    -0.5498,
    0.6167,
    -0.5855,
    0.6675,
    -0.5168,
    0.6366,
    -0.1949,
    0.3123,
    -0.1357,
    0.772,
    -0.4458,
    0.5903,
    -0.5026,
    0.2237,
    -0.2307,
    0.182,
    -0.2318,
    0.4409,
    -0.5235,
    0.1984,
    -0.2599,
    0.0402,
    -0.048,
    0.0779,
    -0.1069,
    0.0919,
    -0.1236,
    0.2393,
    -0.138,
    0.2967,
    -0.2398,
    0.0478,
    -0.6134,
    0.6222,
    -0.4339,
    0.1417,
    -0.1561,
    0.6945,
    -0.1946,
    0.7959,
    -0.3606,
    0.1142,
    -0.1848,
    0.6395,
    -0.3456,
    0.5544,
    -0.4215,
    0.2196,
    -0.2099,
    0.2273,
    -0.167,
    0.1232,
    -0.1394,
    0.0725,
    -0.1789,
    0.1206,
    -0.0944,
    0.0662,
    -0.1236,
    0.1165,
    -0.1201,
    0.0964,
    -0.1135,
    0.0653,
    -0.0847,
    0.0745,
    -0.0715,
    0.0829,
    -0.1034,
    0.0676,
    -0.0862,
    0.1374,
    -0.0965,
    0.1147,
    -0.0726,
    0.2642,
    -0.1143,
    0.5944,
    -0.533,
    0.3447,
    -0.8467,
    0.2985,
    -0.6822,
    0.2505,
    -0.4248,
    0.2875,
    -0.4911,
    0.2769,
    -0.4562,
    0.1221,
    -0.1544,
    0.7783,
    -0.3211,
    0.7879,
    -0.3633,
    0.2999,
    -0.358,
    0.3974,
    -0.2335,
    0.0904,
    -0.1487,
    0.4968,
    -0.5208,
    0.1805,
    -0.5676,
    0.5012,
    -0.5469,
    0.5157,
    -0.3098,
    0.619,
    -0.5413,
    0.195,
    -0.5192,
    0.5903,
    -0.2895,
    0.7326,
    -0.4692,
    0.3324,
    -0.2584,
    0.172,
    -0.2379,
    0.1192,
    -0.601,
    0.1901,
    -0.1461,
    0.1074,
    -0.1306,
    0.0941,
    -0.0864,
    0.142,
    -0.0774,
    0.0433,
    -0.049,
    0.0579,
    -0.0704,
    0.0866,
    -0.0696,
    0.0609,
    -0.0767,
    0.0028,
    -0.0041,
    0.0624,
    -0.0014,
    0.0923,
    -0.0656,
    0.0725,
    -0.0601,
    0.0426,
    -0.0528,
    0.0194,
    -0.1063,
    0.1697,
    -0.0903,
    0.6924,
    -0.7901,
    0.3312,
    -0.4133,
    0.8482,
    -0.242,
    -0.1082,
    -0.4453,
    0.4093,
    -0.8747,
    0.635,
    -0.8309,
    0.3975,
    -0.3734,
    0.0976,
    -0.5755,
    0.1206,
    -0.1653,
    0.0326,
    -0.1268,
    0.1778,
    -0.5754,
    0.5104,
    -0.4128,
    0.1428,
    -0.1306,
    0.2924,
    -0.1067,
    0.6344,
    -0.4412,
    0.1361,
    -0.1445,
    0.2446,
    -0.6697,
    0.2749,
    0.0735,
    0.2125,
    -0.7382,
    0.2768,
    -0.229,
    0.2563,
    -0.2478,
    0.0838,
    -0.048,
    0.0803,
    -0.1106,
    0.0785,
    -0.0974,
    0.0431,
    -0.0722,
    0.0422,
    -0.0117,
    0.1819,
    -0.081,
    0.2217,
    -0.0679,
    0.3174,
    -0.3587,
    0.2371,
    -0.2088,
    0.6579,
    -0.7193,
    0.5051,
    -0.8427,
    0.3543,
    -0.0455,
    0.2718,
    -0.1601,
    0.1099,
    -0.172,
    0.4766,
    -0.6533,
    0.5634,
    -0.6693,
    0.3892,
    -0.6191,
    0.3177,
    -0.4295,
    0.1788,
    -0.3679,
    0.1149,
    -0.1136,
    0.1141,
    -0.1196,
    0.0971,
    -0.0878,
    0.1515,
    -0.1165,
    0.0247,
    -0.0293,
    0.1249,
    -0.1353,
    0.5176,
    -0.4703,
    0.4302,
    -0.516,
    0.348,
    -0.1322,
    0.089,
    -0.4846,
    -0.192,
    -0.5721,
    0.0612,
    -0.1866,
    0.4454,
    -0.1119,
    0.3848,
    -0.1066,
    0.0884,
    -0.3469,
    0.6188,
    -0.3324,
    0.5279,
    -0.5354,
    0.5228,
    -0.8452,
    0.1198,
    -0.0961,
    0.1342,
    -0.2036,
    0.1342,
    -0.1447,
    0.083,
    -0.1042,
    0.0754,
    -0.0878,
    0.1666,
    -0.0985,
    0.1066,
    -0.1002,
    0.082,
    -0.0998,
    0.0758,
    -0.0811,
    0.0775,
    -0.0956,
    0.1547,
    -0.0736,
    0.1425,
    -0.0985,
    0.4874,
    -0.5265,
    0.2839,
    -0.4055,
    0.2074,
    -0.0701,
    0.2349,
    -0.2287,
    0.3767,
    -0.3946,
    0.2911,
    -0.5449,
    0.351,
    -0.4466,
    0.4271,
    -0.4623,
    0.0789,
    -0.0576,
    0.0756,
    -0.1097,
    0.1515,
    -0.0908,
    0.3233,
    -0.7695,
    0.6443,
    -0.1718,
    0.4419,
    0.0648,
    0.4419,
    -0.1374,
    0.4843,
    -0.5684,
    0.1345,
    -0.1185,
    0.046,
    -0.0396,
    0.111,
    -0.1123,
    0.1185,
    -0.0738,
    0.0995,
    -0.1155,
    0.097,
    -0.1189,
    0.0356,
    -0.0349,
    0.0115,
    -0.0193,
    0.3845,
    -0.1456,
    0.367,
    -0.5643,
    0.5482,
    -0.5585,
    0.3604,
    -0.6821,
    0.7722,
    -0.8375,
    0.5331,
    -0.5454,
    0.1736,
    -0.2218,
    0.2641,
    -0.1506,
    0.5998,
    -0.5933,
    0.5218,
    -0.4684,
    0.047,
    -0.0555,
    0.0747,
    -0.0516,
    0.0255,
    -0.0294,
    0.037,
    -0.0268,
    0.0193,
    -0.0186,
    0.0104,
    -0.0073,
    0.16,
    -0.0082,
    0.3782,
    -0.6317,
    0.1505,
    -0.6938,
    0.5099,
    -0.4432,
    0.4322,
    -0.3098,
    0.4419,
    -0.6472,
    0.1415,
    -0.4747,
    0.1865,
    -0.1837,
    0.1563,
    -0.1548,
    0.1038,
    -0.7503,
    0.1901,
    -0.4825,
    0.1144,
    -0.0365,
    0.2696,
    -0.3518,
    0.5767,
    -0.6426,
    0.4884,
    -0.4509,
    0.3375,
    -0.4365,
    0.1114,
    -0.1887,
    0.2214,
    -0.4543,
    0.4213,
    -0.6128,
    0.1497,
    -0.124,
    0.3891,
    -0.5971,
    0.473,
    -0.7237,
    0.488,
    -0.5699,
    0.0527,
    -0.0562,
    0.1804,
    -0.3222,
    0.068,
    -0.0718,
    0.029,
    -0.029,
    0.0226,
    -0.0177,
    0.0211,
    -0.0188,
    0.0223,
    -0.0604,
    0.0479,
    -0.0473,
    0.0259,
    -0.0199,
    0.026,
    -0.0392,
    0.2988,
    -0.26,
    0.1093,
    -0.534,
    0.134,
    -0.0746,
    0.309,
    -0.1224,
    0.0724,
    -0.0544,
    0.5771,
    -0.7063,
    0.7741,
    -0.2506,
    0.173,
    -0.1579,
    0.4284,
    0.0469,
    0.4392,
    -0.0762,
    0.0452,
    -0.0324,
    0.0394,
    -0.0343,
    0.412,
    -0.3301,
    0.2817,
    -0.0697,
    0.4484,
    -0.4483,
    0.1687,
    -0.4639,
    0.0536,
    -0.2301,
    0.3068,
    -0.1616,
    0.5041,
    -0.1851,
    0.2278,
    -0.6747,
    0.5853,
    -0.327,
    0.2565,
    -0.5804,
    0.4773,
    -0.6009,
    0.2982,
    -0.6054,
    0.482,
    -0.6367,
    0.0345,
    -0.2448,
    0.0335,
    -0.0562,
    0.0312,
    -0.0257,
    0.0165,
    -0.0223,
    0.0155,
    -0.0201,
    0.0219,
    -0.0205,
    0.0447,
    -0.0795,
    0.0469,
    -0.0475,
    0.0378,
    -0.0317,
    0.0455,
    -0.0348,
    0.0596,
    -0.0352,
    0.112,
    -0.0551,
    0.0942,
    -0.067,
    0.0419,
    -0.0334,
    0.1258,
    -0.0355,
    0.2011,
    -0.1854,
    0.5915,
    -0.4519,
    0.3936,
    -0.3428,
    0.5878,
    -0.8693,
    0.5579,
    -0.5356,
    0.1843,
    -0.0368,
    0.1295,
    -0.1637,
    0.1802,
    -0.214,
    0.0449,
    -0.099,
    0.1937,
    -0.2836,
    0.3303,
    -0.3614,
    0.2095,
    -0.3851,
    0.491,
    -0.4539,
    0.3577,
    -0.4241,
    0.3313,
    -0.421,
    0.1023,
    -0.0415,
    0.0546,
    -0.0417,
    0.356,
    -0.0771,
    0.2622,
    -0.1755,
    0.1435,
    -0.0715,
    0.5132,
    -0.7285,
    0.3155,
    -0.3985,
    0.1502,
    -0.1306,
    0.2008,
    -0.1144,
    0.3639,
    -0.5038,
    0.4141,
    -0.4359,
    0.0403,
    -0.1181,
    0.033,
    -0.0544,
    0.0249,
    -0.0208,
    0.1313,
    -0.2618,
    0.0819,
    -0.0997,
    0.231,
    -0.1951,
    0.4886,
    -0.2476,
    0.3695,
    -0.3702,
    0.3023,
    -0.4009,
    0.3131,
    -0.4819,
    0.4535,
    -0.5054,
    0.4755,
    -0.2665,
    0.0818,
    -0.0769,
    0.0447,
    -0.0386,
    0.0379,
    -0.0483,
    0.0371,
    -0.0223,
    0.0391,
    -0.0355,
    0.0266,
    -0.0291,
    0.0221,
    -0.0236,
    0.2776,
    -0.2528,
    0.5775,
    -0.6285,
    0.6234,
    -0.7552,
    0.6644,
    -0.3642,
    0.4329,
    -0.7702,
    0.5121,
    -0.4652,
    0.288,
    -0.2073,
    0.0977,
    -0.0701,
    0.0503,
    -0.0428,
    0.0356,
    -0.0363,
    0.0452,
    -0.0552,
    0.0517,
    -0.1659,
    0.0622,
    -0.0953,
    0.1714,
    -0.1038,
    0.2267,
    -0.1904,
    0.3352,
    -0.661,
    0.6583,
    -0.2867,
    0.6403,
    -0.405,
    0.1189,
    -0.342,
    0.5482,
    -0.3642,
    0.5438,
    -0.6834,
    0.1585,
    -0.7399,
    0.1217,
    -0.1144,
    0.3886,
    -0.4309,
    0.0634,
    -0.0321,
    0.0632,
    -0.0423,
    0.1334,
    -0.0878,
    0.6311,
    -0.7043,
    0.1971,
    -0.5106,
    0.141,
    -0.0949,
    0.4609,
    -0.6949,
    0.2604,
    -0.2377,
    0.1334,
    -0.0557,
    0.0472,
    -0.0183,
    0.0617,
    -0.071,
    0.0582,
    -0.0295,
    0.0305,
    -0.0471,
    0.0372,
    -0.0662,
    0.457,
    -0.2333,
    0.4244,
    -0.5235,
    0.5756,
    -0.4421,
    0.5229,
    -0.4466,
    0.0164,
    -0.4462,
    0.0366,
    -0.0283,
    0.0124,
    -0.4291,
    0.6543,
    -0.5664,
    0.4873,
    -0.6773,
    0.3847,
    -0.1237,
    0.5884,
    -0.4716,
    0.436,
    -0.3755,
    0.174,
    -0.4037,
    0.0393,
    -0.0669,
    0.0259,
    -0.0198,
    0.0141,
    -0.0276,
    0.0504,
    -0.2865,
    0.1368,
    -0.0546,
    0.7117,
    0.12,
    0.1957,
    -0.2876,
    0.2158,
    -0.3376,
    0.4512,
    -0.2777,
    0.3861,
    -0.3499,
    0.2096,
    -0.2386,
    0.6933,
    -0.7384,
    0.5466,
    -0.7068,
    0.2304,
    -0.4096,
    0.3201,
    -0.3899,
    0.2755,
    -0.246,
    0.1838,
    -0.0906,
    0.0327,
    -0.0275,
    0.0247,
    -0.0264,
    0.0296,
    -0.0284,
    0.0268,
    -0.0262,
    0.0876,
    -0.0825,
    0.0312,
    -0.0744,
    0.0637,
    -0.228,
    0.2984,
    -0.0634,
    0.3401,
    -0.5697,
    0.5192,
    -0.5829,
    0.4351,
    -0.1596,
    0.418,
    -0.8469,
    0.6975,
    -0.7843,
    0.6778,
    -0.159,
    0.1422,
    -0.0281,
    0.1155,
    -0.0478,
    0.4659,
    -0.9331,
    0.4629,
    -0.6375,
    0.516,
    -0.5475,
    0.572,
    -0.6384,
    0.6299,
    -0.5544,
    0.4552,
    -0.595,
    0.0778,
    -0.1033,
    0.4112,
    -0.4112,
    0.6096,
    -0.4368,
    0.1467,
    -0.1169,
    0.4576,
    -0.4577,
    0.6098,
    -0.5448,
    0.0301,
    -0.029,
    0.6347,
    -0.2234,
    0.1343,
    -0.3024,
    0.1617,
    -0.0884,
    0.0958,
    -0.0619,
    0.1733,
    -0.4735,
    0.149,
    -0.1607,
    0.0725,
    -0.0946,
    0.4292,
    -0.1972,
    0.2464,
    -0.7134,
    0.3035,
    -0.6045,
    0.3272,
    -0.6883,
    0.4153,
    -0.6328,
    0.5052,
    -0.6702,
    0.1199,
    -0.2583,
    0.615,
    -0.1614,
    0.3499,
    -0.1613,
    0.3688,
    -0.1999,
    0.0884,
    -0.1466,
    0.8321,
    -0.3038,
    0.2931,
    -0.4305,
    0.0863,
    -0.2351,
    0.2482,
    -0.6983,
    0.4039,
    -0.6655,
    0.0734,
    -0.3439,
    0.2367,
    -0.4193,
    0.2225,
    -0.0854,
    0.5376,
    -0.6595,
    0.4959,
    -0.4611,
    0.4957,
    -0.4768,
    0.2073,
    -0.2948,
    0.0551,
    -0.041,
    0.0301,
    -0.0445,
    0.0569,
    -0.0406,
    0.477,
    -0.3776,
    0.525,
    -0.6846,
    0.6311,
    -0.5801,
    0.124,
    -0.4516,
    0.3775,
    -0.3502,
    0.4383,
    -0.3209,
    0.2868,
    -0.3329,
    0.6484,
    -0.3895,
    0.2778,
    -0.2846,
    0.2475,
    -0.2736,
    0.042,
    -0.0356,
    0.0348,
    -0.0302,
    0.0373,
    -0.0267,
    0.0274,
    -0.0282,
    0.0165,
    -0.0414,
    0.3071,
    -0.0708,
    0.3526,
    -0.0307,
    0.0338,
    -0.0431,
    0.0408,
    -0.5114,
    0.1609,
    -0.587,
    0.1937,
    -0.0255,
    0.418,
    -0.2849,
    0.2041,
    -0.3124,
    0.1581,
    -0.0809,
    0.1615,
    -0.0951,
    0.2202,
    -0.0447,
    0.4904,
    -0.1113,
    0.5395,
    -0.4477,
    0.2605,
    -0.2494,
    0.0298,
    -0.0443,
    0.0847,
    -0.0662,
    0.0339,
    -0.0289,
    0.0322,
    -0.0363,
    0.036,
    -0.0353,
    0.4452,
    -0.4264,
    0.4507,
    -0.4208,
    0.2079,
    -0.4369,
    0.2656,
    -0.4462,
    0.2036,
    -0.3353,
    0.2449,
    -0.2403,
    0.0599,
    -0.0542,
    0.0404,
    -0.0402,
    0.0421,
    -0.0405,
    0.0342,
    -0.0313,
    0.035,
    -0.0468,
    0.028,
    -0.0302,
    0.0376,
    -0.032,
    0.0184,
    -0.0174,
    0.0246,
    -0.0458,
    0.0295,
    -0.0162,
    0.0273,
    -0.0335,
    0.0216,
    -0.039,
    0.0266,
    -0.0194,
    0.0149,
    -0.0193,
    0.0211,
    -0.0165,
    0.0173,
    -0.0183,
    0.0142,
    -0.0137,
    0.0172,
    -0.0162,
    0.0172,
    -0.0099,
    0.0177,
    -0.0195,
    0.0101,
    -0.0187,
    0.0101,
    -0.0114,
    0.0102,
    -0.016,
    0.0106,
    -0.0108,
    0.011,
    -0.0123,
    0.0057,
    -0.0105,
    0.0077,
    -0.009,
    0.0074,
    -0.0087,
    0.0097,
    -0.0079,
    0.0047,
    -0.0097,
    0.0056,
    -0.0057,
    0.0044,
    -0.0088,
    0.0046,
    -0.0057,
    0.0054,
    -0.0055,
    0.0048,
    -0.004,
    0.0034,
    -0.0033,
    0.0028,
    -0.0035,
    0.0017,
    -0.0034,
    0.0034,
    -0.0026,
    0.0027,
    -0.0024,
    0.0027,
    -0.0025,
    0.0022,
    -0.0021,
    0.0015,
    -0.0017,
    0.0011,
    -0.0013,
    0.0013,
    -0.0011,
    0.0011,
    -0.0006,
    0.0011,
    -0.0009,
    0.0005,
    -0.0007,
    0.0003,
    -0.0004,
    0.0002,
    -0.0003,
    0.0001,
    -0.0001
];

export default chapterSixthPCM;