import styled from 'styled-components';

export const AudioBlockWrap = styled.div`
  width: 100%;
  canvas{
    width: 100%;
  }
`;

export const AudioBlockDuration = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const WaveSurferTrack = styled.div`
  margin: 0 10px;
  width: 248px;
   wave {
     overflow-y: visible!important;
     overflow-x: hidden!important;
     padding: 19px 0;
     box-sizing: content-box!important;
     height: 35px!important;
     
     canvas {
       height: 35px!important;
       top: 7px!important;
     }
    wave {
      border: none!important;
      height: 35px!important;
      
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 2px;
        background-color: #000000;
        border-radius: 90px;
        height: 72px;
        top: -15px;
      }
    }
  }
`;

export const AudioCountdown = styled.div`
  color: #000000;
  p {
    font-size: 12px;
  }
`;

export const AudioFullDuration = styled.div`
  color: #e5e5e5;
  p {
    font-size: 12px;
  }
`;

export const Preloader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #b4b4b4;
  font-size: 15px;
  max-width: 375px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  img{
    width: 25px;
  }
`;