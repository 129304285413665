import {HeaderTitleWrapper} from "./HeaderTitle.styles";

const HeaderTitle = () => {

    return (
        <HeaderTitleWrapper>
            <h1>Klus en Smaakmakers Audiotour</h1>
        </HeaderTitleWrapper>
    );

};

export default HeaderTitle;