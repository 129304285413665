import { ButtonPlayWrap } from "./ButtonPlay.styles";

const ButtonPlay = ({ handlePlay, isPlaying }) => {

    return (
        <ButtonPlayWrap
            onClick={handlePlay}
        >
            {!isPlaying
                ? <img src='./images/btn-play.png' alt='btn-play'/>
                : <img src='./images/btn-pause.png' alt='btn-pause'/>}
        </ButtonPlayWrap>
    );
};

export default ButtonPlay;