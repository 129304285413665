import { Link, useLocation } from 'react-router-dom';

import Player from "../../components/Player";

import {
    DetailPageWrap,
    DetailPageHeader,
    DetailPageHeaderTitle,
    DetailPageContent,
    DetailPageCover,
    DetailPageCaption,
    DetailPageAudio
} from "./DetailPage.styles";

const DetailPage = ({ title1, title1_1, title2, title3, img1, img2, audio }) => {

    const { pathname } = useLocation();
    let currentChapter = +pathname.split('-')[1];

    const prevChapter = currentChapter === 1
        ? `/chapter-${currentChapter}`
        : `/chapter-${currentChapter - 1}`;
    const nextChapter = currentChapter === 10
        ? `/chapter-${currentChapter}`
        : `/chapter-${currentChapter + 1}`;

    return (
        <DetailPageWrap>
            <DetailPageHeader>
                <Link to='/'>
                    <img src='./images/icon-home.svg' alt='icon-home'/>
                </Link>
                { currentChapter === 1
                    ? <DetailPageHeaderTitle><p>{title1_1}</p></DetailPageHeaderTitle>
                    : <DetailPageHeaderTitle><p>{title1}</p></DetailPageHeaderTitle>
                }
            </DetailPageHeader>
            <DetailPageContent>
                <DetailPageCover>
                    {   currentChapter === 1
                        ? <img src={img2} alt='detail-page-cover'/>
                        : <img src={img1} alt='detail-page-cover'/>
                    }
                </DetailPageCover>
                <DetailPageCaption>
                    { currentChapter === 1
                        ? <>
                            <p>{title1}</p>
                            <span>{title2}</span>
                        </>

                        : <>
                            <p>{title2}</p>
                            <span>{title3}</span>
                        </>
                    }
                </DetailPageCaption>
                <DetailPageAudio>
                    <Player
                        audio={audio}
                        prevChapter={prevChapter}
                        nextChapter={nextChapter}
                    />
                </DetailPageAudio>
            </DetailPageContent>
        </DetailPageWrap>
    );
};

export default DetailPage;