import { useEffect, useState, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';

import {
    AudioBlockWrap,
    AudioBlockDuration,
    AudioCountdown,
    WaveSurferTrack,
    AudioFullDuration,
    Preloader
} from "./AudioBlock.styles";
import {Link} from "react-router-dom";
import ButtonPlay from "../ButtonPlay";
import {Controls} from "../Player/Player.styles";

import CustomRenderer from "./helpers/CustomRender";
import { convertSecondsToAudioTime } from './helpers/helper';

const formWaveSurferOptions = ref => ({
    container: ref,
    autoplay: true,
    waveColor: "#c4c4c4c4",
    progressColor: "#000000",
    cursorColor: "#000000",
    cursorWidth: 2,
    barWidth: 3.15,
    barRadius: 4,
    barGap: 5,
    responsive: true,
    height: 35,
    backend: 'MediaElement',
    // If true, normalize by the maximum peak instead of 1.0.

    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    //partialRender: true,
    renderer: CustomRenderer
});

const AudioBlock = ({ loadAudioPath, prevChapter, nextChapter, trackPointsArray }) => {

    const [ isPlaying, setIsPlaying ] = useState(false);
    const [ fullTime, setFullTime ] = useState('00:00');
    const [ isLoaded, setIsLoaded ] = useState(false);

    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);

    const [ timePassed, setTimePassed ] = useState(convertSecondsToAudioTime(wavesurfer.current?.getCurrentTime()));

    const isPrevButtonDisabled = prevChapter === '/chapter-1' && nextChapter === '/chapter-2';
    const isNextButtonDisabled = prevChapter === '/chapter-9' && nextChapter === '/chapter-10';

    const handlePlay = () => {
        wavesurfer.current.playPause();
    }

    const handleSwitchChapters = (event) => {
        const { pathname: currentPathname } = window.location
        const linkString = event.currentTarget.href;
        const urlObject = new URL(linkString);
        const { pathname: linkPathname } = urlObject;
        const isPrevDisabled = currentPathname === '/chapter-1' && linkPathname === '/chapter-1';
        const isNextDisabled = currentPathname === '/chapter-9' && linkPathname === '/chapter-9';
        if (isPrevDisabled || isNextDisabled) {
            event.preventDefault();
        } else {
            setIsPlaying(false);
            setTimePassed('00:00');
        }
    }

    useEffect(() => {
        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);
        let timeCounter = undefined;

        wavesurfer.current.load(loadAudioPath, trackPointsArray);

        wavesurfer.current.on("loading", () => {
            if (isLoaded) {
                setIsLoaded(false);
            }
        })

        wavesurfer.current.on("ready", () => {
            // make sure object still available when file loaded
            if (wavesurfer.current) {
                setIsLoaded(true);
                wavesurfer.current.play();

                setFullTime(convertSecondsToAudioTime(wavesurfer.current?.getDuration()));

                wavesurfer.current.on("audioprocess", () => {
                    if (!timeCounter) {
                        timeCounter = setInterval(() => {
                            setTimePassed(convertSecondsToAudioTime(wavesurfer.current.getCurrentTime()));
                        }, 1000)
                    }
                })

                wavesurfer.current.on("pause", () => {
                    clearInterval(timeCounter);
                    timeCounter = undefined;
                    setIsPlaying(false);
                });

                wavesurfer.current.on("play", () => {
                    setIsPlaying(true);
                });

                wavesurfer.current.on("seek", () => {
                    setTimePassed(convertSecondsToAudioTime(wavesurfer.current.getCurrentTime()));
                });
                // TO GET PCM FOR TRACK
                // wavesurfer.current.exportPCM(1024, 10000, true)
                //     .then(result => {
                //         console.log(result);
                //     });
            }
        });

        return () => {
            clearInterval(timeCounter);
            wavesurfer.current.destroy();
        };
    }, [loadAudioPath]);

    return (
        <AudioBlockWrap>
            <AudioBlockDuration>
                <AudioCountdown><p>{timePassed}</p></AudioCountdown>
                {!isLoaded && <Preloader>
                    <img src='./images/giphy.gif' alt='preloader'/>
                </Preloader>}
                <WaveSurferTrack ref={waveformRef}/>
                <AudioFullDuration><p>{fullTime}</p></AudioFullDuration>
            </AudioBlockDuration>

            <Controls >
                <Link
                    to={prevChapter}
                    onClick={handleSwitchChapters}
                >
                    <button className={`arrow arrow-prev ${isPrevButtonDisabled ? 'disabled' : ''}`}>
                        <img src='./images/arrow-prev.svg' alt='arrow-prev'/>
                    </button>
                </Link>
                <ButtonPlay
                    isPlaying={isPlaying}
                    handlePlay={handlePlay}
                />
                <Link
                    to={nextChapter}
                    onClick={handleSwitchChapters}
                >
                    <button className={`arrow arrow-next ${isNextButtonDisabled ? 'disabled' : ''}`}>
                        <img src='./images/arrow-next.svg' alt='arrow-next'/>
                    </button>
                </Link>
            </Controls>
        </AudioBlockWrap>

    );
};


export default AudioBlock;
