import AudioBlock from "../AudioBlock";

import chapterFirstPCM from "../AudioBlock/helpers/chapter-01-pcm";
import chapterSecondPCM from "../AudioBlock/helpers/chapter-02-pcm";
import chapterThirdPCM from "../AudioBlock/helpers/chapter-03-pcm";
import chapterFourthPCM from "../AudioBlock/helpers/chapter-04-pcm";
import chapterFifthPCM from "../AudioBlock/helpers/chapter-05-pcm";
import chapterSixthPCM from "../AudioBlock/helpers/chapter-06-pcm";
import chapterSeventhPCM from "../AudioBlock/helpers/chapter-07-pcm";
import chapterEighthPCM from "../AudioBlock/helpers/chapter-08-pcm";
import chapterNinthPCM from "../AudioBlock/helpers/chapter-09-pcm";
import chapterTenthPCM from "../AudioBlock/helpers/chapter-10-pcm";

import {
    PlayerWrap
} from './Player.styles';

const Player = ({ prevChapter, nextChapter, audio}) => {
    let PCM = [];

    switch (audio) {
        case 'audio-1':
            PCM = chapterFirstPCM;
            break;
        case 'audio-2':
            PCM = chapterSecondPCM;
            break;
        case 'audio-3':
            PCM = chapterThirdPCM;
            break;
        case 'audio-4':
            PCM = chapterFourthPCM;
            break;
        case 'audio-5':
            PCM = chapterFifthPCM;
            break;
        case 'audio-6':
            PCM = chapterSixthPCM;
            break;
        case 'audio-7':
            PCM = chapterSeventhPCM;
            break;
        case 'audio-8':
            PCM = chapterEighthPCM;
            break;
        case 'audio-9':
            PCM = chapterNinthPCM;
            break;
        case 'audio-10':
            PCM = chapterTenthPCM;
            break;
        default:
            PCM = chapterFirstPCM;
    }
    return (
        <PlayerWrap>
            <AudioBlock
                loadAudioPath={`./audio/${audio}.mp3`}
                trackPointsArray={PCM}
                prevChapter={prevChapter}
                nextChapter={nextChapter}
            />
        </PlayerWrap>
    );
};

export default Player;